import { FC } from 'react';
import { DisableMask } from 'components/common';
import WithTitle from 'shared/ui/WithTitle/WithTitle';
import CustomToggleBtnGroup, { CustomToggleBtnGroupProps } from './CustomToggleBtnGroup';

import s from './styles.module.scss';

interface CustomToggleBtnGroupTitleProps extends CustomToggleBtnGroupProps {
	title: string;
	isDisabled: boolean;
}
const CustomToggleBtnGroupTitle: FC<CustomToggleBtnGroupTitleProps> = ({ title, isDisabled, ...props }) => (
	<WithTitle containerClassName={s.block} title={title} theme="gray" size="md">
		<DisableMask disable={isDisabled}>
			<CustomToggleBtnGroup
				buttons={props.buttons}
				defaultValue={props.defaultValue}
				onHandleSelect={props.onHandleSelect}
			/>
		</DisableMask>
	</WithTitle>
);

export default CustomToggleBtnGroupTitle;
