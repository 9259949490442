import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { Logic } from 'components/common/Selects/MultiValueSelect/components/MultiSelectWithConditions/MultiSelectWithConditions';
import { OptionType } from 'components/common/Selects/MultiValueSelect/multiValuesSelect';
import { convertDataForTags } from 'utils/convertDataForSearchFilters/convertData';
import { criteriaWithComplexValues } from 'configs/searchCriteria';
import { initialState } from './initial';
import { Dict, FragmentType, GlobalFilter, Group, GroupType, Item, MR, TagRuleType } from './types';
import { removeFromComplexCriteria } from '../search/utils';
import { createNullArray, getOwnerGroups, getOwnerItems } from './actions';

// TODO: починить типы!!!

export const mrSlice = createSlice({
	name: 'mr',
	initialState,
	reducers: {
		// страница
		setPage(state, action: PayloadAction<string>) {
			state.page = action.payload;
			state.ownerPage = action.payload;
		},

		setOwnerPage(state, action: PayloadAction<string>) {
			state.ownerPage = action.payload;
		},

		// активные словари
		setActiveDicts(state, action: PayloadAction<string[]>) {
			state.activeDicts = action.payload;
		},

		// общее
		setGroups(state, action: PayloadAction<MR<Group[]>>) {
			// @ts-ignore
			state[action.payload.page].groups = [...action.payload.data];
		},

		// определяем группы пользователя,
		// у которого хотим импортировать правила / словари
		setOwnerGroups(state, action: PayloadAction<MR<Group[]>>) {
			// @ts-ignore
			state[action.payload.page].ownerGroups = [...action.payload.data];
		},

		updateGroup(state, action: PayloadAction<MR<{ id: string }>>) {
			// @ts-ignore
			const groups = state[action.payload.page].groups; // @ts-ignore
			state[action.payload.page].groups = groups.map((group) => {
				if (group?.id === action.payload.data.id) {
					return {
						...group,
						enabled: !group.enabled,
					};
				}
				return group;
			});
		},

		updateOwnerGroup(state, action: PayloadAction<MR<{ id: string }>>) {
			// @ts-ignore
			const groups = state[action.payload.page].groups; // @ts-ignore
			state[action.payload.page].ownerGroups = groups.map((group) => {
				if (group?.id === action.payload.data.id) {
					return {
						...group,
						enabled: !group.enabled,
					};
				}
				return group;
			});
		},

		setCurrentGroup(state, action: PayloadAction<MR<Group | null>>) {
			// @ts-ignore
			state[action.payload.page].currentGroup = action.payload.data;
		},

		setOwnerCurrentGroup(state, action: PayloadAction<MR<Group | null>>) {
			// @ts-ignore
			state[action.payload.page].currentOwnerGroup = action.payload.data;
		},

		setItems(state, action: PayloadAction<MR<(Item | null)[] | null>>) {
			// без ифов странный ts error
			if (action.payload.page === 'tags') {
				state[action.payload.page][action.payload.page] = action.payload.data;
			} else if (action.payload.page === 'dicts') {
				state[action.payload.page][action.payload.page] = action.payload.data;
			}
		},

		setOwnerItems(state, action: PayloadAction<MR<(Item | null)[] | null>>) {
			if (action.payload.page === 'tags') {
				state.tags.ownerTags = action.payload.data;
			} else if (action.payload.page === 'dicts') {
				state.dicts.ownerDicts = action.payload.data;
			}
		},

		setSort(state, action: PayloadAction<MR<{ sort: string; sort_desc: boolean }>>) {
			// @ts-ignore
			state[action.payload.page].sort = action.payload.data;
		},

		setGroupsBlock(state, action: PayloadAction<MR<boolean>>) {
			// @ts-ignore
			state[action.payload.page].groupsBlock = action.payload.data;
		},

		setOwnerGroupsBlock(state, action: PayloadAction<MR<boolean>>) {
			// @ts-ignore
			state[action.payload.page].groupsOwnerBlock = action.payload.data;
		},

		// словари
		setDict(state, action: PayloadAction<Dict | false | null>) {
			state.dicts.currentDict = action.payload;
		},

		setOwnerDict(state, action: PayloadAction<Dict | false | null>) {
			state.dicts.currentOwnerDict = action.payload;
		},

		// теги
		setTag(state, action: PayloadAction<TagRuleType | false | null>) {
			state.tags.currentTag = convertDataForTags(action.payload);
		},

		setTagIsLoading(state, action: PayloadAction<boolean>) {
			state.tagsIsLoading = action.payload;
		},

		setOwnerTag(state, action: PayloadAction<TagRuleType | false | null>) {
			state.tags.currentOwnerTag = convertDataForTags(action.payload);
		},

		// фильтры
		setFilter(state, action: PayloadAction<GlobalFilter>) {
			if (state.tags.currentTag) {
				state.tags.currentTag.globalFilter.push(action.payload);
			}
		},

		removeFilters(state) {
			if (state.tags.currentTag) {
				state.tags.currentTag.globalFilter.length = 0;
			}
		},

		removeFilter(state, action: PayloadAction<GlobalFilter>) {
			if (state.tags.currentTag) {
				state.tags.currentTag.globalFilter = state.tags.currentTag.globalFilter.filter(
					(filter) => filter.key !== action.payload.key,
				);
			}
		},

		removeComplexFilter(state, action: PayloadAction<GlobalFilter>) {
			if (state.tags.currentTag) {
				state.tags.currentTag.globalFilter = removeFromComplexCriteria(
					current(state.tags.currentTag.globalFilter),
					action.payload.conditionItemId,
					action.payload.key,
				);
			}
		},

		setFilterValues(state, action: PayloadAction<GlobalFilter & { logic?: Logic; conditionItemId?: number }>) {
			const { key, values, conditionItemId, logic } = action.payload;
			if (state.tags.currentTag) {
				state.tags.currentTag.globalFilter = state.tags.currentTag.globalFilter.map((filter) => {
					if (filter.key === key) {
						if (criteriaWithComplexValues.includes(key)) {
							return filter.conditionItemId === conditionItemId ? { ...filter, values, logic } : filter;
						}
						return {
							...filter,
							values,
						};
					}
					return filter;
				});
			}
		},
		setRulesOwner(state, action: PayloadAction<OptionType>) {
			state.rulesOwner = action.payload;
		},
		setCopyMode(state, action: PayloadAction<boolean>) {
			state.copyMode = action.payload;
		},
		setCopyModeSuccess(state, action: PayloadAction<boolean>) {
			state.copyModeSuccess = action.payload;
		},
		resetAllTags(state) {
			state.tags = initialState.tags;
		},
		resetAllDicts(state) {
			state.dicts = initialState.dicts;
		},
		setUsersOnlyListForSelect(state, action: PayloadAction<OptionType[]>) {
			state.usersOnlyListForSelect = action.payload;
		},
		setFragments(state, action: PayloadAction<FragmentType[]>) {
			state.fragments = action.payload;
		},
	},
	extraReducers: {
		[getOwnerGroups.pending.type]: (state, action: any) => {
			const { meta: args } = action;
			const page = args.arg.page as 'tags' | 'dicts';
			const property = page === 'tags' ? 'ownerTags' : 'ownerDicts';
			// @ts-ignore
			state[page][property] = createNullArray(15);
			state[page].ownerGroups = createNullArray(15);
			state.ownerGroupsAndItemsLoading = true;
		},
		[getOwnerGroups.fulfilled.type]: (state, action: any) => {
			const { meta: args } = action;
			const page = args.arg.page as 'tags' | 'dicts';
			state[page].ownerGroups = action.payload;
		},
		[getOwnerGroups.rejected.type]: (state) => {
			state.ownerGroupsAndItemsLoading = false;
		},
		[getOwnerItems.fulfilled.type]: (state, action: any) => {
			const { meta: args, payload } = action;
			const page = args.arg.page as 'tags' | 'dicts';
			const property = page === 'tags' ? 'ownerTags' : 'ownerDicts';
			// @ts-ignore
			state[page][property] = payload.data;
			state[page].groupsOwnerBlock = false;
			state.ownerGroupsAndItemsLoading = false;
		},
		[getOwnerItems.rejected.type]: (state, action: any) => {
			const { meta: args } = action;
			const page = args.arg.page as 'tags' | 'dicts';
			const property = page === 'tags' ? 'ownerTags' : 'ownerDicts';
			state[page].groupsOwnerBlock = false;
			// @ts-ignore
			state[page][property] = null;
		},
	},
});
