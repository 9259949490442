import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
	diItem: {
		display: 'flex',
		margin: '3px 0',
		alignItems: 'start',
		position: 'relative',
		width: '100%',
		paddingRight: '24px',
	},
	diBodyTextWrapper: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1',
	},
	diBodyText: {
		padding: '0 2px !important',
		display: 'flex',
		flexWrap: 'wrap',
		position: 'relative',
		height: '100% !important',
		lineHeight: '22px !important',
		color: '#2F3747 !important',
		paddingTop: '0px',
	},
	attachments: {},
	attachmentIcon: {
		cursor: 'pointer',
		marginRight: '5px',
	},
	dateTypography: {
		fontSize: '14px !important',
		userSelect: 'none',
		WebkitUserSelect: 'none',
		MozUserSelect: 'none',
		msUserSelect: 'none',
	},
	diTagsBlock: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		margin: '0 16px',
	},
	diFragment: {
		wordBreak: 'break-all',
	},
	typographyTitle: {
		textAlign: 'right',
		color: '#2F3747 !important',
		fontWeight: '700 !important',
		minWidth: '88px !important',
		userSelect: 'none',
		WebkitUserSelect: 'none',
		MozUserSelect: 'none',
		msUserSelect: 'none',
	},
	titleCustomer: {
		color: '#0009FF !important',
	},
	titleEmployee: {
		color: '#FF0E00 !important',
	},
	tagMatch: {
		padding: '0 2px',
		fontWeight: 700,
		// backgroundColor: '#EFDBFF !important',
	},
	// chat styles
	chatTime: {
		display: 'inline-block',
		width: '80px',
		flexShrink: '0',
		WebkitUserSelect: 'none',
		MozUserSelect: 'none',
		msUserSelect: 'none',
		textAlign: 'end',
		paddingRight: '10px',
	},
	chatTitle: {
		width: '136px',
		flexShrink: '0',
		marginRight: '8px !important',
		textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
		justifyContent: 'end',
		flexWrap: 'wrap'
	},
	chatTitleNumber: {
		fontSize: '10px',
		fontWeight: 600,
		lineHeight: '20px',
		padding: '0px 4px',
		borderRadius: '5px',
		border: '1px solid #F5222D',
		marginLeft: '4px',
		maxHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
	}
});

export default useStyles;
