export const COUNT_PER_ITERATION = 'count_per_iteration';

/* Поля Stt, не отображаемые на фронте */
export const setDeleteSTT = new Set(['language', 'codec', 'remove_silence', 'container', 'model']);

/* валидация для стт */
export const validationCases = [
	{
		all: {
			diarization: true,
			engine_diarization: true,
		},
	},

	{
		all: {
			merge_all_to_one_audio: true,
		},
		oneOf: {
			diarization: true,
			engine_diarization: true,
		},
	},
];

export const sttDefault = {
	sttLanguage: null,
	sttEngine: null,
	sttModel: null,
	sttEconomize: false,
};

export const sttDefaultFull = {
	...sttDefault,
	sttOptionsMap: {},
};
