import { FormikConfig, useFormik } from 'formik';
import { FormikValues } from 'formik/dist/types';

const useCustomForm = <T extends FormikValues>(values: FormikConfig<T>, requiredFields: (keyof T)[]) => {
	const formik = useFormik(values);

	const emptyRequiredFields = requiredFields.filter((field) => formik.values[field] === '');

	return { formik, emptyRequiredFields };
};

export default useCustomForm;
