import { FC, useEffect, useState } from 'react';
import renderRoutes from 'router/renderRoutes';
import { authRoutes, routes } from 'router/routes';
import { useAppSelector } from 'hooks/redux';
import { Header } from 'components/common';

import s from './styles.module.scss';

const WithAuthContent: FC = () => {
	const isAuth = useAppSelector((state) => state.user.isAuth);
	const [localIsAuth, setLocalIsAuth] = useState<boolean | string>(false);

	useEffect(() => {
		setLocalIsAuth(isAuth);
	}, [isAuth]);

	if (localIsAuth)
		return (
			<>
				<Header />
				<div className={s.container}>{renderRoutes(routes)}</div>
			</>
		);

	return renderRoutes(authRoutes);
};

export default WithAuthContent;
