import { TagThemesTypes } from 'pages/Calls/types';
import variables from 'styles/variables.scss';

type ThemesColorsType = {
	[K in TagThemesTypes]: {
		color: string;
		bg: string;
		hoverBg: string;
	};
};

export const themesColors: ThemesColorsType = {
	blue: {
		bg: variables.geekblue_2,
		color: variables.geekblue_7,
		hoverBg: variables.green_4,
	},
	gray: {
		bg: variables.gray_2,
		color: variables.gray_7,
		hoverBg: variables.green_4,
	},
	purple: {
		bg: variables.purple_2,
		color: variables.purple_7,
		hoverBg: variables.green_4,
	},
	orange: {
		bg: variables.orange_2,
		color: variables.orange_7,
		hoverBg: variables.green_4,
	},
	lightBlue: {
		bg: variables.cyan_2,
		color: variables.cyan_7,
		hoverBg: variables.green_4,
	},
	green: {
		bg: variables.green_2,
		color: variables.green_7,
		hoverBg: variables.green_4,
	},
};
