import { FC } from 'react';
import Button from '@mui/material/Button';
import cn from 'classnames';
import { LoadingButton } from '@mui/lab';
import s from './FooterButtons.module.scss';

export interface FooterButtonsProps {
	onCancel?: () => void;
	onAccept: any;
	cancelText?: string;
	acceptText: string;
	cancelIsDisabled?: boolean;
	acceptIsDisabled?: boolean;
	footerClassName?: string;
	acceptClassName?: string;
	loading?: boolean;
}

const FooterButtons: FC<FooterButtonsProps> = ({
	onCancel,
	onAccept,
	cancelText,
	acceptText,
	cancelIsDisabled = false,
	acceptIsDisabled = false,
	footerClassName,
	acceptClassName,
	loading = false,
}) => (
	<div className={cn(s.footer, footerClassName)}>
		{onCancel && (
			<Button
				className={cn(s.footer__margin, {
					[s.footer__cancel]: !cancelIsDisabled,
				})}
				data-testid="cancelButton"
				color="primary"
				variant="contained"
				onClick={onCancel}
				disabled={cancelIsDisabled}
			>
				{cancelText}
			</Button>
		)}
		<LoadingButton
			className={acceptClassName && !acceptIsDisabled ? acceptClassName : undefined}
			data-testid="acceptButton"
			color="primary"
			variant="contained"
			onClick={onAccept}
			disabled={acceptIsDisabled}
			loading={loading}
		>
			{acceptText}
		</LoadingButton>
	</div>
);

export default FooterButtons;
