import React from 'react';
import { useAppDispatch } from 'hooks/redux';
import { reportsSlice } from 'store';
import { AdditionalColumns, AdditionalRows } from 'store/reports/reports.types';

const useValidateReport = (columns: AdditionalColumns[], rows: AdditionalRows[]) => {
	const dispatch = useAppDispatch();

	const validateEmptyParameters = (): boolean => {
		let resultValid = false;

		columns.forEach((column, index) => {
			const groupByColumnsValue = column.groupByColumnsValue;
			if (groupByColumnsValue.value === 'tag_name_list') {
				if (column.tagsNameList.col_name?.trim() === '' && !column.tagsNameList.split) {
					dispatch(
						reportsSlice.actions.setIsValidTagListTitle({
							arrayIndex: index,
							value: false,
						}),
					);
					resultValid = true;
				}
			} else if (groupByColumnsValue.value === 'tag_name_value') {
				if (!column.tagNameValue.name || column.tagNameValue.name?.trim() === '') {
					dispatch(
						reportsSlice.actions.setIsValidTagName({
							arrayIndex: index,
							value: false,
						}),
					);
					resultValid = true;
				}
			} else if (groupByColumnsValue.value === 'search_items') {
				if (column.callFilters.nameColumn.value?.trim() === '') {
					dispatch(
						reportsSlice.actions.setIsValidColumn({
							arrayIndex: index,
							value: false,
						}),
					);
					resultValid = true;
				}
			}
		});

		rows.forEach((row, index) => {
			const groupByRowsValue = row.groupByRowsValue;
			if (groupByRowsValue.value === 'tag_name_value') {
				if (!row.tagNameValueRow || row.tagNameValueRow.value?.trim() === '') {
					dispatch(
						reportsSlice.actions.setAdditionalRowValue({
							index,
							option: 'tagNameValueValid',
							data: false,
						}),
					);
					resultValid = true;
				}
			} else if (groupByRowsValue.value === 'tag_name_list') {
				if (row.tagsNameList.row_name?.trim() === '' && !row.tagsNameList.split) {
					dispatch(
						reportsSlice.actions.setAdditionalRowObjectValue({
							index,
							data: false,
							optionObject: 'tagsNameList',
							option: 'row_name_isValid',
						}),
					);
					resultValid = true;
				}
			}
		});

		return resultValid;
	};

	return {
		validateEmptyParameters,
	};
};

export default useValidateReport;
