import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { createSnackbarOptions } from 'components/common/Snackbar/Snackbar';
import { translate } from 'localizations';
import { useSnackbar } from 'notistack';
import { RouteParams } from 'pages/Settings/types';
import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

let errorQueue: number[] = [];
let isSnackbarOpen = false;

export const handleErrors = (errors: (FetchBaseQueryError | SerializedError | undefined)[]) => {
	const { lang } = useParams<RouteParams>();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [processedErrors, setProcessedErrors] = useState<Set<number>>(new Set());

	const processQueue = useCallback(() => {
		if (errorQueue?.length > 0 && !isSnackbarOpen) {
			const errorStatus = errorQueue?.shift();
			if (errorStatus !== undefined) {
				isSnackbarOpen = true;
				const enqueueError = (messageKey: string, time: number) => {
					const key = enqueueSnackbar(
						translate(messageKey, lang),
						createSnackbarOptions({
							time,
							text: translate(messageKey, lang),
							type: 'error',
						}),
					);

					setTimeout(() => {
						isSnackbarOpen = false;
						processQueue();
						closeSnackbar(key);
					}, time);
				};

				switch (errorStatus) {
					case 400:
						enqueueError('error400', 2000);
						break;
					case 401:
						enqueueError('error401', 2000);
						break;
					case 403:
						enqueueError('error403', 2000);
						break;
					case 404:
						enqueueError('error404', 2000);
						break;
					case 409:
						enqueueError('error409', 2000);
						break;
					case 422:
						enqueueError('error422', 2000);
						break;
					case 500:
						enqueueError('error500', 2000);
						break;
					default:
						enqueueError('unexpectedError', 2000);
						break;
				}
			}
		}
	}, [enqueueSnackbar, lang, closeSnackbar]);

	useEffect(() => {
		errors?.forEach((err: any) => {
			if (!err) return;

			const errorStatus = err.status;

			if (!processedErrors.has(errorStatus)) {
				setProcessedErrors((prevErrors) => new Set(prevErrors).add(errorStatus));
				errorQueue.push(errorStatus);
			}
		});

		processQueue();
	}, [errors, processedErrors, processQueue]);
};
