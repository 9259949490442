import { useState, useEffect, useRef, FC } from 'react';
import { CrossIcon } from 'shared/ui/index';
import { translate } from 'localizations';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'pages/Settings/types';

import cls from './EnhancedSelect.module.scss';

interface OptionType {
	label: string;
	value: string;
}

interface EnhancedSelectProps {
	options: OptionType[];
	placeholder?: string;
	onChange: (selected: OptionType[]) => void;
	value: OptionType[];
	testId?: string;
}

const EnhancedSelect: FC<EnhancedSelectProps> = ({ options, value, placeholder, onChange, testId }) => {
	const { lang } = useParams<RouteParams>();
	const [searchTerm, setSearchTerm] = useState('');
	const [savedSearchTerm, setSavedSearchTerm] = useState('');
	const [isInputVisible, setIsInputVisible] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState<OptionType[]>(value || []);
	const [filteredOptions, setFilteredOptions] = useState<OptionType[]>(options);
	const [isOpen, setIsOpen] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);
	const selectAllOption = { label: translate('selectAllOptions', lang), value: 'select-all' };

	const maxVisibleSelectedOptions = 5;
	const visibleSelectedOptions = selectedOptions.slice(0, maxVisibleSelectedOptions);
	const remainingSelectedOptionsCount = selectedOptions.length - maxVisibleSelectedOptions;

	useEffect(() => {
		setSelectedOptions(value);
	}, [value]);

	useEffect(() => {
		setFilteredOptions(options.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase())));
	}, [searchTerm, options]);

	const handleSelectInputClick = () => {
		setIsOpen(true);
		setIsInputVisible(true);
	};

	useEffect(() => {
		if (isInputVisible) {
			inputRef.current?.focus(); // Устанавливаем фокус на инпут
		}
	}, [isInputVisible]);

	const toggleOption = (option: OptionType) => {
		if (option.value === selectAllOption.value) {
			if (selectedOptions.length === options.length) {
				setSelectedOptions([]); // Убираем все выбранные опции
			} else {
				setSelectedOptions(options); // Выбираем все опции
			}
		} else {
			setSelectedOptions((prevSelected) => {
				const isSelected = prevSelected.some((selected) => selected.value === option.value);
				if (isSelected) {
					return prevSelected.filter((selected) => selected.value !== option.value);
				} else {
					return [...prevSelected, option]; // Добавляем в конец списка
				}
			});
		}
		// Сохраняем текущий поисковый запрос
		setSavedSearchTerm(searchTerm);
		// Оставляем фильтрованные опции
		setFilteredOptions((prevFiltered) => prevFiltered);
		setIsOpen(true);
	};

	const handleClickOutside = (event: MouseEvent) => {
		const target = event.target as HTMLElement;
		if (!target.closest(`.${cls.enhancedSelect}`)) {
			// Восстанавливаем сохраненный поисковый запрос
			setSearchTerm('');
			// Сбрасываем фильтрацию опций
			// Пока закомментировал, чтобы не кэшировало опции. Если будет другая проблема, то вернуть и думать дальше
			// setFilteredOptions(options);
			setIsOpen(false);
			// Скрываем инпут
			setIsInputVisible(false);
			// Убираем фокус с инпута
			inputRef.current?.blur();
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [savedSearchTerm]);

	useEffect(() => {
		onChange(selectedOptions);
	}, [selectedOptions]);

	// Функция сортировки перед рендерингом списка опций
	const sortedOptions = [...filteredOptions].sort((a, b) => {
		const indexA = selectedOptions.findIndex((selected) => selected.value === a.value);
		const indexB = selectedOptions.findIndex((selected) => selected.value === b.value);

		if (indexA !== -1 && indexB === -1) {
			return -1;
		}
		if (indexA === -1 && indexB !== -1) {
			return 1;
		}
		if (indexA !== -1 && indexB !== -1) {
			return indexA - indexB;
		}
		return 0;
	});

	const removeOption = (option: OptionType) => {
		if (option.value === selectAllOption.value) {
			setSelectedOptions([]);
		} else {
			setSelectedOptions((prevSelected) => prevSelected.filter((selected) => selected.value !== option.value));
		}
	};

	const removeAllOptions = () => {
		setSelectedOptions([]);
	};

	return (
		<div className={cls.enhancedSelect} data-testid={testId}>
			<div className={cls.selectInput} onClick={() => handleSelectInputClick()}>
				{selectedOptions.length > 0 && (
					<div className={cls.selectedOptionsContainer}>
						<div className={cls.left}>
							{selectedOptions.length === options.length ? (
								<div className={cls.selectedOption}>
									<span className={cls.label}>{selectAllOption.label}</span>
									<button
										className={cls.closeIconWrapper}
										onClick={() => removeOption(selectAllOption)}
									>
										<CrossIcon className={cls.closeIcon} />
									</button>
								</div>
							) : (
								<>
									{visibleSelectedOptions.map((option) => (
										<div key={option.value} className={cls.selectedOption}>
											<span className={cls.label}>{option.label}</span>
											<button
												className={cls.closeIconWrapper}
												onClick={() => removeOption(option)}
											>
												<CrossIcon className={cls.closeIcon} />
											</button>
										</div>
									))}
									{remainingSelectedOptionsCount > 0 && (
										<span className={cls.moreLabel}>
											{translate('more', lang)} {remainingSelectedOptionsCount}
										</span>
									)}
								</>
							)}
							{isInputVisible && (
								<input
									ref={inputRef} // Привязка рефа к инпуту
									type="text"
									value={searchTerm}
									onChange={(e) => setSearchTerm(e.target.value)}
									onFocus={() => setIsOpen(true)}
								/>
							)}
						</div>
						<div className={cls.right}>
							<button className={cls.closeIconWrapper} onClick={removeAllOptions}>
								<CrossIcon className={cls.closeIcon} />
							</button>
						</div>
					</div>
				)}
				{selectedOptions.length === 0 && (
					<input
						ref={inputRef} // Привязка рефа к инпуту
						type="text"
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
						placeholder={placeholder}
						onFocus={() => setIsOpen(true)}
					/>
				)}
			</div>
			{isOpen && (
				<ul className={cls.selectOptions}>
					{searchTerm === '' && (
						<li key={selectAllOption.value} className={cls.selectOption}>
							<label className={cls.label}>
								<span className={cls.labelContainer}>{selectAllOption.label}</span>
								<input
									type="checkbox"
									checked={selectedOptions.length === options.length}
									onChange={() => toggleOption(selectAllOption)}
								/>
							</label>
						</li>
					)}
					{sortedOptions.length > 0 ? (
						sortedOptions.map((option) => (
							<li key={option.value} className={cls.selectOption}>
								<label className={cls.label}>
									<span className={cls.labelContainer}>{option.label}</span>
									<input
										type="checkbox"
										checked={selectedOptions.some((selected) => selected.value === option.value)}
										onChange={() => toggleOption(option)}
									/>
								</label>
							</li>
						))
					) : (
						<li className={cls.noOptions}>{translate('noOptionsMessage', lang)}</li>
					)}
				</ul>
			)}
		</div>
	);
};

export default EnhancedSelect;
