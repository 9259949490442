import { UniversalInput } from 'shared/ui';
import { WidgetProps } from '@rjsf/utils';
import { translate } from 'localizations';
import { useAppSelector } from 'hooks/redux';
import s from './styles.module.scss';

type InputType = 'string' | 'number' | 'boolean' | 'object' | 'array';

const CustomTextWidget = (
	props: WidgetProps,
	validateInputNumber?: (label: string, fromTo: 'min' | 'max') => number | undefined,
	validateInputNumberValue?: (value: string) => boolean,
) => {
	const { language } = useAppSelector((state) => state.lang);

	const integer = props.schema.type === 'integer';
	const min = integer && validateInputNumber ? validateInputNumber(props.name, 'min') : undefined;
	const max = integer && validateInputNumber ? validateInputNumber(props.name, 'max') : undefined;

	return (
		<div style={{ marginBottom: '16px' }}>
			<UniversalInput
				min={min}
				max={max}
				width="250px"
				testId={props.name}
				inputStyle="outlined"
				tooltipTheme="dark"
				tooltipPlacement="right"
				value={props.value}
				label={translate(props.name, language)}
				classNameLabel={s.label}
				tooltipTitle={translate(`${props.name}_tooltip`, language)}
				inputType={integer ? 'number' : (props.schema.type as InputType)}
				handleChange={(event) => {
					const value = event.target.value;
					if (validateInputNumberValue) {
						if (validateInputNumberValue?.(value)) {
							props.onChange(value);
						}
					} else {
						props.onChange(value);
					}
				}}
			/>
		</div>
	);
};

export default CustomTextWidget;
