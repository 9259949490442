import { FC } from 'react';
import { LoadingButton } from '@mui/lab';
import { translate } from 'localizations';

import cls from './styles.module.scss';

interface DeleteModalInnerProps {
	toggleModalDelete: () => void;
	onIconClick: (action: string) => void;
	language: string;
}

const DeleteModalInner: FC<DeleteModalInnerProps> = ({ toggleModalDelete, onIconClick, language }) => (
	<div className={cls.modalBtns}>
		<LoadingButton variant="outlined" color="primary" onClick={toggleModalDelete}>
			{translate('cancelButton', language)}
		</LoadingButton>
		<LoadingButton
			variant="contained"
			color="primary"
			onClick={() => {
				onIconClick('delete');
				toggleModalDelete();
			}}
		>
			{translate('deleteButton', language)}
		</LoadingButton>
	</div>
);

export default DeleteModalInner;
