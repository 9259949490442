import { createSnackbarOptions } from 'components/common/Snackbar/Snackbar';
import { useAppSelector } from 'hooks/redux';
import { translate } from 'localizations';
import { useSnackbar } from 'notistack';
import { SttValuesForRequest } from 'widgets/STT/namespaces';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RootState } from 'store/store';
import { selectActiveUser } from 'store/user/selectors';
import { getUser, updateUser, userSlice } from 'store/user/user.slice';
import useCurrentUser from 'hooks/useCurrentUser';
import { UserType } from '../store/user/user.types';

const withSTTProps = (Component: any) => () => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const { language } = useAppSelector((state: RootState) => state.lang);
	const user = useAppSelector(selectActiveUser);
	const childUser = useAppSelector((state) => state.user.childUser);
	const currentUser = useCurrentUser();

	const [userInfo, setUserInfo] = useState(null);

	useEffect(() => {
		if (currentUser) {
			const fetchUserInfo = async () => {
				const userInfoFetch = await dispatch(getUser({ id: currentUser.id })); // @ts-ignore
				setUserInfo(userInfoFetch.payload);
			};
			fetchUserInfo().catch(console.error);
		}
	}, []);

	const successSSTUpdateUser = async () => {
		enqueueSnackbar(
			null,
			createSnackbarOptions({
				time: 3000,
				type: 'success',
				text: `${translate('updateSuccess', language)}`,
			}),
		); // @ts-ignore

		if (user) {
			const updatedUserInfo: any = await dispatch(getUser({ id: user.id }));
			const payloadUpdatedUserInfo = updatedUserInfo.payload;

			
			if (childUser && user?.id === childUser.id) {
				dispatch(userSlice.actions.setChildUser(payloadUpdatedUserInfo));
			} else {
				dispatch(userSlice.actions.setCurrentUser(payloadUpdatedUserInfo));
			}
		}
	};

	const onSubmitFunctionSTT = async (values: SttValuesForRequest) => {
		const updatedUser = await dispatch(
			// @ts-ignore
			updateUser({
				...user,
				...values,
			}),
		);
		// @ts-ignore
		if (updatedUser.meta.requestStatus === 'fulfilled') {
			successSSTUpdateUser();
		} else {
			enqueueSnackbar(
				null,
				createSnackbarOptions({
					time: 3000,
					type: 'error',
					text: `${translate('updateError', language)}`,
				}),
			);
		}
	};

	return (
		<Component
			language={language}
			user={userInfo}
			onSubmitFunction={onSubmitFunctionSTT}
			buttonTitle={translate('saveButton', language)}
		/>
	);
};

export default withSTTProps;
