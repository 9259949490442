import { createSlice, PayloadAction, Update } from '@reduxjs/toolkit';
import { initialState } from './initial';
import { callsCheckListsAdapter, userCheckListsAdapter } from './adapters';
import { TInitialState } from './namespace';
import { CheckListItemForCopy, TCallCheckList, TCheckListItem } from './namespaces/responses';

const checkListsSlice = createSlice({
	name: 'checkLists',
	initialState,
	reducers: {
		setCallsCheckLists: (state, action: PayloadAction<TCallCheckList[]>) => {
			callsCheckListsAdapter.setAll(state.callsCheckLists, action.payload);
		},
		updateCallsCheckListById: (state, action: PayloadAction<Update<TCallCheckList>>) => {
			callsCheckListsAdapter.updateOne(state.callsCheckLists, action.payload);
		},
		setCallsCheckListsLoading(state, action: PayloadAction<boolean>) {
			state.isCallsCheckListsLoading = action.payload;
		},

		setUserCheckLists: (state, action: PayloadAction<TCheckListItem[]>) => {
			userCheckListsAdapter.setAll(state.userCheckLists, action.payload);
		},
		updateUserCheckListById: (state, action: PayloadAction<Update<TCheckListItem>>) => {
			userCheckListsAdapter.updateOne(state.userCheckLists, action.payload);
		},
		setUserCheckListsLoading(state, action: PayloadAction<boolean>) {
			state.isUserCheckListsLoading = action.payload;
		},
		deleteUserCheckList(state, action: PayloadAction<string>) {
			userCheckListsAdapter.removeOne(state.userCheckLists, action.payload);
		},
		setCurrentCheckList(state, action: PayloadAction<TCheckListItem | null>) {
			state.currentCheckList = action.payload;
		},
		setUserNewCheckList(state, action: PayloadAction<boolean>) {
			state.isNewCheckList = action.payload;
		},
		setCheckListForCopy(state, action: PayloadAction<CheckListItemForCopy[] | null>) {
			state.checkListItemForCopy = action.payload;
		},
	},
});

export const checkListsActions = checkListsSlice.actions;

export default checkListsSlice.reducer;
