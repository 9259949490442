import { FC } from 'react';
import { MetaInfoType } from 'store/calls/calls.types';
import { Skeleton } from '@mui/material';

import cls from './styles.module.scss';

interface MetaInfoModalInnerProps {
	metaInfo: MetaInfoType[];
	isLoading: boolean;
}

const MetaInfoModalInner: FC<MetaInfoModalInnerProps> = ({ metaInfo, isLoading }) => (
	<div className={cls.metaInfo}>
		{isLoading ? (
			<div className={cls.skeleton}>
				<Skeleton variant="text" width={550} height={25} />
				<Skeleton variant="text" width={550} height={25} />
				<Skeleton variant="text" width={550} height={25} />
				<Skeleton variant="text" width={550} height={25} />
				<Skeleton variant="text" width={550} height={25} />
			</div>
		) : (
			metaInfo.map((item: MetaInfoType) => {
				const isLinkAvailable = !!item.link;

				if (isLinkAvailable) {
					if (item.value) {
						return (
							<div className={cls.item} key={`${item.name} ${item.value || item.link}`}>
								<div className={cls.title}>{item.title}: </div>
								<a
									className={`${cls.value} ${cls.link}`}
									href={item.link}
									target="_blank"
									rel="noopener noreferrer"
								>
									{item.value}
								</a>
							</div>
						);
					}
					return (
						<div className={cls.item} key={`${item.name} ${item.value || item.link}`}>
							<a
								href={item.link}
								target="_blank"
								rel="noopener noreferrer"
								className={`${cls.title} ${cls.link}`}
							>
								{item.title}
							</a>
						</div>
					);
				}
				return (
					<div className={cls.item} key={`${item.name} ${item.value || item.link}`}>
						<div className={cls.title}>{item.title}: </div>
						<div className={cls.value}>{item.value}</div>
					</div>
				);
			})
		)}
	</div>
);

export default MetaInfoModalInner;
