import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const select = (state: RootState): RootState['user'] => state.user;
 
export const selectCurrentUser = createSelector(select, (user) => user.user);

export const selectChildUser = createSelector(select, (user) => user.childUser);

export const selectActiveUser = createSelector([selectCurrentUser, selectChildUser], (currentUser, childUser) => childUser || currentUser);

export const selectAddressBook = createSelector(select, (user) => user.addressBook);

export const selectAddressBookLoading = createSelector(select, (user) => user.isAddressBookLoading); 
