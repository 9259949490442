import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

type TextSelectStylesType = {
	arrowColor?: string;
};

export const useMuiTextSelectStyles = makeStyles<Theme, TextSelectStylesType>((theme: Theme) => ({
	selectBox: {
		position: 'relative',
	},
	selectArrow: {
		fill: (props) => props.arrowColor || '#000',
		margin: '0 7px',
	},
	selectArrowOnTopBox: {
		marginBottom: '5px',
	},
	selectOption: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		cursor: 'pointer',
		padding: '5px 15px 5px 12px',
		fontFamily: 'Inter, sans-serif',
		fontSize: '14px',
		backgroundColor: '#ffffff',
		color: '#000',
		'&:hover': {
			backgroundColor: '#F8FAFC',
			color: '#722ED1',
		},
		'&:last-child': {
			marginBottom: '16px',
		},
	},
	groupName: {
		fontWeight: 400,
		fontSize: '14px',
		lineHeight: '22px',
		color: '#A3AEBE',
	},
	groupItem: {
		fontWeight: 400,
		fontSize: '14px',
		lineHeight: '22px',
		color: '#2F3747 !important',
		paddingRight: '16px',
		textWrap: 'balance',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
}));

type createCustomTextSelectStylesParamsType = {
	width?: string;
	menuPosition: 'right' | 'left';
};

export function createTextSelectStyles({ width = '380px', menuPosition }: createCustomTextSelectStylesParamsType) {
	return {
		menu: (provided: any, state: any) => ({
			...provided,
			width: width || '380px',
			minWidth: '322px',
			right: menuPosition === 'right' && '0',
			overflow: 'hidden',
			height: '388px',
			overflowY: 'auto',
			zIndex: '1000',
			margin: '0',
			padding: '16px',
			borderRadius: '10px',
			border: '1px solid #E3E8EF',
			boxShadow:
				'0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)',
			'&::-webkit-scrollbar': {
				width: '4px',
				backgroundColor: '#f1f1f1',
				outline: 'none',
			},
			'&::-webkit-scrollbar-thumb': {
				background: '#A3AEBE',
				height: '50px',
				borderRadius: '10px',
			},
			'&::-webkit-scrollbar-thumb:hover': {
				background: '#9298A1',
			},
		}),
		menuList: (provided: any, state: any) => ({
			...provided,
		}),
		option: (provided: any, state: any) => ({
			cursor: 'pointer',
			padding: '10px 24px',
			fontFamily: 'Inter, sans-serif',
			fontSize: '14px',
			backgroundColor: '#ffffff',
			color: '#000',
			'&:hover': {
				backgroundColor: '#F8FAFC',
				color: '#722ED1',
			},
		}),
	};
}
