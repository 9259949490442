import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useEffect, useMemo, useState } from 'react';
import { notificationsAPI } from 'services/NotificationsService';
import { isValidJson } from 'shared/lib/isValidJson';
import { CriteriasType } from 'store/search/search.types';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'pages/Settings/types';
import { setSelectedCall } from 'store/notifications/NotificationsSlice';
import {
	AmoFillableCustomFields,
	AmoFillableCustomFieldsType,
	BitrixFillableCustomFields,
	BitrixFillableCustomFieldsType,
	optionsForSelects,
} from '../types/NotificationsTypes';
import { handleErrors } from 'services/utils/errorHandler';
import { translate } from 'localizations';

interface TemplateObject {
	template: string;
	templateTooltip: string;
}

export const useNotifyRules = () => {
	const { lang } = useParams<RouteParams>();
	const { childUser } = useAppSelector((state) => state.user);

	// список правил должен перезапрашиваться, если выбрали другого childUser в выпадашке
	const {
		data: notifyRules,
		error: notifyRulesError,
		refetch: notifyRulesRefetch,
	} = notificationsAPI.useGetNotifyRulesQuery(childUser);
	const { data: getAvailableDirections, error: getAvailableDirectionsError } =
		notificationsAPI.useGetAvailableDirectionsQuery({ childUser });
	const { data: getAmoFillableCustomFields, error: getAmoFillableCustomFieldsError } =
		notificationsAPI.useGetAmoFillableCustomFieldsQuery({});
	const { data: getBitrixFillableCustomFields, error: getBitrixFillableCustomFieldsError } =
		notificationsAPI.useGetBitrixFillableCustomFieldsQuery({});
	const {
		data: getNotifyRuleVariables,
		error: getNotifyRuleVariablesError,
		isLoading: notifyRuleVariablesIsLoading,
	} = notificationsAPI.useGetNotifyRuleVariablesQuery({});
	const [createNotifyRule, { error: createNotifyRuleError }] = notificationsAPI.useCreateNotifyRuleMutation();
	const [notifyRuleActions, { error: notifyRuleActionsError }] = notificationsAPI.useNotifyRuleActionsMutation();
	const [updateNotifyRule, { error: updateNotifyRuleError }] = notificationsAPI.useUpdateNotifyRuleMutation();
	const [patchNotifyRule, { error: patchNotifyRuleError }] = notificationsAPI.usePatchNotifyRuleMutation();
	const [deleteNotifyRule, { error: deleteNotifyRuleError }] = notificationsAPI.useDeleteUserNotifyMutation();
	const [testNotifyRuleTemplate, { error: testNotifyRuleTemplateError }] =
		notificationsAPI.useTestNotifyRuleTemplateMutation();
	const [cloneNotifyRule, { error: cloneNotifyRuleError }] = notificationsAPI.useCloneNotifyRuleMutation();
	const fetchNotifyRules = (ruleOwner?: string) => {
		const { data, error, refetch } = notificationsAPI.useFetchNotifyRulesQuery(ruleOwner);
		handleErrors([error]);
		return { data, error, refetch };
	};
	const getNotifyRule = (id: string) => {
		const { data, error, refetch } = notificationsAPI.useGetNotifyRuleQuery(id);
		handleErrors([error]);
		return { data, error, refetch };
	};
	const getCallNotifyRuleVariables = (id: string) => {
		const { data, error, refetch } = notificationsAPI.useGetCallNotifyRuleVariablesQuery(id, { skip: !id });
		handleErrors([error]);
		return { data, error, refetch };
	};

	const { callId } = useParams<RouteParams>();
	const dispatch = useAppDispatch();

	useEffect(() => {
		const fetchCallId = async () => {
			if (callId) {
				await dispatch(setSelectedCall(callId));
			}
		};
		fetchCallId();
	}, [callId]);

	const accessRights = useAppSelector((state) => state.user.accessRights);

	const [notifyTypesOptions, setNotifyTypesOptions] = useState<{ value: string; label: string }[]>([]);
	useEffect(() => {
		if (getAvailableDirections) {
			const newNotifyTypesOptions = getAvailableDirections?.map((type: string) => ({
				value: type,
				label: type,
			}));
			setNotifyTypesOptions(newNotifyTypesOptions);
		}
	}, [getAvailableDirections]);

	const [selectedOption, setSelectedOption] = useState<{ value: string; label: string } | null>(null);

	const [notifyTitle, setNotifyTitle] = useState('');
	const [emailSubj, setEmailSubj] = useState('');
	const [emailDst, setEmailDst] = useState('');
	const [apiUrl, setApiUrl] = useState('');
	const [apiMethod, setApiMethod] = useState('');
	const [apiResend, setApiResend] = useState(false);
	const [allowOwerwriteFields, setAllowOwerwriteFields] = useState(false);
	const [apiHeadersText, setApiHeadersText] = useState('');
	const [bitrixNotificationDirection, setBitrixNotificationDirection] = useState('into_deal');
	const [selectedTgChats, setSelectedTgChats] = useState<string[]>([]);
	const [messageBody, setMessageBody] = useState('');
	const [isTgAudioActive, setIsTgAudioActive] = useState(false);
	const [selectedFilters, setSelectedFilters] = useState<CriteriasType[]>([]);

	useEffect(() => {
		setSelectedTgChats([]);
		setEmailSubj('');
		setEmailDst('');
		setApiUrl('');
		setApiResend(false);
		setAllowOwerwriteFields(false);
		setApiHeadersText('');
	}, [selectedOption?.value]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		switch (name) {
			case 'notifyTitle':
				setNotifyTitle(value);
				break;
			case 'emailSubj':
				setEmailSubj(value);
				break;
			case 'emailDst':
				setEmailDst(value);
				break;
			case 'apiUrl':
				setApiUrl(value);
				break;

			default:
				break;
		}
	};

	const templatesArray: TemplateObject[] = useMemo(() => {
		if (notifyRuleVariablesIsLoading || !getNotifyRuleVariables) {
			return [];
		}

		const { call_vars, descriptions } = getNotifyRuleVariables;

		return Object.keys(call_vars).map((key) => ({
			template: key,
			templateTooltip: `${descriptions[key] ? `${descriptions[key]}. ` : ''}Пример: ${call_vars[key]}`,
		}));
	}, [getNotifyRuleVariables, notifyRuleVariablesIsLoading]);

	const [callTemplatesArray, setCallTemplatesArray] = useState<TemplateObject[]>([]);

	const getCallTemplates = (callVariables: any) => {
		if (callVariables) {
			const { call_vars, descriptions } = callVariables;

			const newCallTemplatesArray = Object.keys(call_vars).map((key) => ({
				template: key,
				templateTooltip: `${descriptions[key] ? `${descriptions[key]}. ` : ''}Пример: ${call_vars[key]}`,
			}));

			setCallTemplatesArray(newCallTemplatesArray);
		}
	};

	const [resTemplate, setResTemplate] = useState('');
	const [resHasIsValidJson, setResHasIsValidJson] = useState(false);

	const testRuleTemplate = async (call_Id: string, template: string) => {
		try {
			const response = await testNotifyRuleTemplate({ callId: call_Id, params: { template } });

			if ('error' in response) {
				const error = response.error as FetchBaseQueryError;
				if (error.status === 422) {
					setResTemplate((error.data as { detail: string }).detail || 'Unknown error');
				}
			}

			if ('data' in response && response.data.template) {
				const templateData = response.data.template;
				if (typeof templateData === 'string' && isValidJson(templateData)) {
					setResHasIsValidJson(true);
				} else {
					setResHasIsValidJson(false);
				}

				setResTemplate(response.data.template);
			}
		} catch (error) {
			console.error('An error occurred:', error);
		}
	};

	// AmoCRM fields
	const [amoContactFields, setAmoContactFields] = useState<AmoFillableCustomFieldsType[]>([]);
	const [amoLeadFields, setamoLeadFields] = useState<AmoFillableCustomFieldsType[]>([]);

	useEffect(() => {
		if (!getAmoFillableCustomFields?.isLoading && getAvailableDirections?.includes('AmoCRM')) {
			try {
				setAmoContactFields(
					getAmoFillableCustomFields?.filter(
						(el: AmoFillableCustomFieldsType) => el.entityType === AmoFillableCustomFields.CONTACTS,
					),
				);

				setamoLeadFields(
					getAmoFillableCustomFields?.filter(
						(el: AmoFillableCustomFieldsType) => el.entityType === AmoFillableCustomFields.LEADS,
					),
				);
			} catch (error) {
				console.error('An error occurred:', error);
			}
		}
	}, [getAmoFillableCustomFields, getAvailableDirections]);

	const transformToOptionArray = (inputArray: AmoFillableCustomFieldsType[]): optionsForSelects[] =>
		inputArray?.map((item) => ({
			label: item.name,
			value: item.id,
			type: item.type,
			options: item.options,
		}));

	const amoContactFieldsToOptions = transformToOptionArray(amoContactFields);
	const amoLeadFieldsToOptions = transformToOptionArray(amoLeadFields);
	const [contactKeyValuePairs, setContactKeyValuePairs] = useState<Record<string, string>>({});
	const [leadKeyValuePairs, setLeadKeyValuePairs] = useState<Record<string, string>>({});

	// Bitrix fields
	const [bitrixContactFields, setBitrixContactFields] = useState<BitrixFillableCustomFieldsType[]>([]);
	const [bitrixLeadFields, setBitrixLeadFields] = useState<BitrixFillableCustomFieldsType[]>([]);
	const [bitrixDealFields, setBitrixDealFields] = useState<BitrixFillableCustomFieldsType[]>([]);

	useEffect(() => {
		if (!getBitrixFillableCustomFields?.isLoading && getAvailableDirections?.includes('Bitrix24')) {
			try {
				setBitrixContactFields(
					getBitrixFillableCustomFields?.filter(
						(el: BitrixFillableCustomFieldsType) => el.entityType === BitrixFillableCustomFields.CONTACT,
					),
				);

				setBitrixLeadFields(
					getBitrixFillableCustomFields?.filter(
						(el: BitrixFillableCustomFieldsType) => el.entityType === BitrixFillableCustomFields.LEAD,
					),
				);

				setBitrixDealFields(
					getBitrixFillableCustomFields?.filter(
						(el: BitrixFillableCustomFieldsType) => el.entityType === BitrixFillableCustomFields.DEAL,
					),
				);
			} catch (error) {
				console.error('An error occurred:', error);
			}
		}
	}, [getBitrixFillableCustomFields, getAvailableDirections]);

	const b24TransformToOptionArray = (inputArray: BitrixFillableCustomFieldsType[]): optionsForSelects[] =>
		inputArray?.map((item) => ({
			label: item.name,
			value: item.id,
			type: item.type,
			options: item.options,
		}));

	const bitrixContactFieldsToOptions = b24TransformToOptionArray(bitrixContactFields);
	const bitrixLeadFieldsToOptions = b24TransformToOptionArray(bitrixLeadFields);
	const bitrixDealFieldsToOptions = b24TransformToOptionArray(bitrixDealFields);
	const [bitrixContactKeyValuePairs, setBitrixContactKeyValuePairs] = useState<Record<string, string>>({});
	const [bitrixLeadKeyValuePairs, setBitrixLeadKeyValuePairs] = useState<Record<string, string>>({});
	const [bitrixDealKeyValuePairs, setBitrixDealKeyValuePairs] = useState<Record<string, string>>({});

	handleErrors([getNotifyRuleVariablesError, notifyRuleActionsError, testNotifyRuleTemplateError]);

	const bitrixNotifyDirectionOptions = [
		{ label: translate('intoDeal', lang), value: 'into_deal' },
		{ label: translate('intoCall', lang), value: 'into_call' },
	];

	const data = {
		notifyRules,
		notifyRulesError,
		callNotifyRuleVariables: (id: string) => getCallNotifyRuleVariables(id).data,
		notifyTypesOptions,
		selectedOption,
		notifyTitle,
		emailSubj,
		emailDst,
		apiUrl,
		apiMethod,
		apiResend,
		allowOwerwriteFields,
		apiHeadersText,
		selectedTgChats,
		messageBody,
		isTgAudioActive,
		selectedFilters,
		updateNotifyRuleError,
		notifyRuleVariablesIsLoading,
		templatesArray,
		patchNotifyRuleError,
		accessRights,
		callTemplatesArray,
		resTemplate,
		resHasIsValidJson,
		amoContactFields,
		amoLeadFields,
		amoContactFieldsToOptions,
		amoLeadFieldsToOptions,
		getAmoFillableCustomFieldsError,
		contactKeyValuePairs,
		leadKeyValuePairs,
		bitrixContactFields,
		bitrixLeadFields,
		bitrixDealFields,
		bitrixContactFieldsToOptions,
		bitrixLeadFieldsToOptions,
		bitrixDealFieldsToOptions,
		bitrixContactKeyValuePairs,
		bitrixLeadKeyValuePairs,
		bitrixDealKeyValuePairs,
		getBitrixFillableCustomFieldsError,
		bitrixNotificationDirection,
		bitrixNotifyDirectionOptions,
	};

	const methods = {
		fetchNotifyRules,
		getNotifyRule,
		createNotifyRule,
		notifyRulesRefetch,
		notifyRuleActions,
		updateNotifyRule,
		deleteNotifyRule,
		setSelectedOption,
		handleInputChange,
		setApiResend,
		setAllowOwerwriteFields,
		setApiMethod,
		setApiHeadersText,
		setSelectedTgChats,
		setMessageBody,
		setIsTgAudioActive,
		setSelectedFilters,
		patchNotifyRule,
		getCallTemplates,
		getCallNotifyRuleVariables,
		testRuleTemplate,
		setContactKeyValuePairs,
		setLeadKeyValuePairs,
		cloneNotifyRule,
		getAvailableDirections,
		getAvailableDirectionsError,
		setBitrixContactKeyValuePairs,
		setBitrixLeadKeyValuePairs,
		setBitrixDealKeyValuePairs,
		setBitrixNotificationDirection,
	};

	return { data, methods };
};
