export const defaultAdditionalColumn = {
	// по списку тегов
	tagsNameList: {
		values: [],
		values_isValid: true,
		split: true,
		col_name: '',
		col_name_isValid: true,
		only_to_first_appropriate: false,
	},
	// по тегу
	tagNameValue: {
		valueTag: { value: '', label: '' },
		optionsTag: [],
		values: [],
		name: '',
		split: true,
		only_to_first_appropriate: false,
		isValid: true,
	},
	// точный фильтр
	callFilters: {
		options: [],
		values: [],
		activeValues: [],
		nameColumn: {
			// это  col_name
			value: '',
			isValid: true,
		},
	},
};

export const defaultAdditionalRow = {
	timeRowsReportValue: { value: '', label: '' },
	checkboxSplitRow: true,
	tagNameRowListWithValue: [],
	checkboxToFirstAppropriate: false,
	tagNameValueValid: true,

	tagsNameList: {
		values: [],
		values_isValid: true,
		split: true,
		row_name: '',
		row_name_isValid: true,
		only_to_first_appropriate: false,
	},
};
