/* eslint-disable */
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { instance } from '../../api/api.config';
import { RootState } from '../store';
import { convertDate } from '../../utils/convertDateAndTime/convertDate';
import { langSlice } from '../lang/lang.slice';
import { getTokens } from 'utils/tokens';

export const getAllTasks = createAsyncThunk('tasks/getAllTasks', async (_, thunkAPI) => {
	try {
		const { token } = getTokens();
		const { data } = await instance.get<TaskType[]>(`/progress_tasks`, {
			headers: { Authorization: `Bearer ${token}` },
		});
		return data;
	} catch (error) {
		thunkAPI.dispatch(langSlice.actions.setSnackbar({ type: 'error', time: 2000, text: 'Something wrong' }));
		console.log(error);
	}
});

export const getActionFiles = createAsyncThunk(
	'tasks/getActionFiles',
	async (payload: { taskId: string; action: string }, thunkAPI) => {
		try {
			const { token } = getTokens();
			const res = await instance.get(`/task/${payload.taskId}/attachment`, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'multipart/form-data',
				},
				responseType: 'arraybuffer',
			});
			const contentType =
				(payload.action === 'audio_archive' && 'application/zip') ||
				(['stt_export', 'xlsx_export'].find((item) => item === payload.action) &&
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8');
			const blob = new Blob([res.data], { type: contentType });
			const downloadUrl = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = downloadUrl;
			// @ts-ignore
			const state: RootState = thunkAPI.getState();
			if (state.search.calls.date[0] && state.search.calls.date[1]) {
				a.download = `${convertDate(state.search.calls.date[0], '.')}-${convertDate(
					state.search.calls.date[1],
					'.',
				)}-imot.${['stt_export', 'xlsx_export'].find((item) => item === payload.action) ? 'xlsx' : 'zip'}`;
			} else a.download = `all-time_calls`;
			document.body.appendChild(a);
			a.click();
		} catch (error) {
			console.log(error);
		}
	},
);

export const getTask = createAsyncThunk('tasks/getTask', async (taskId: string, thunkAPI) => {
	try {
		const { token } = getTokens();
		const { data } = await instance.get(`/task/${taskId}`, { headers: { Authorization: `Bearer ${token}` } });
		return data;
	} catch (error) {
		thunkAPI.dispatch(langSlice.actions.setSnackbar({ type: 'error', time: 2000, text: 'Something wrong' }));
		console.log(error);
	}
});

export const getTaskStatus = createAsyncThunk('tasks/getTask', async (taskId: string, thunkAPI) => {
	try {
		const { token } = getTokens();
		const { data } = await instance.get(`/task/${taskId}/status`, {
			headers: { Authorization: `Bearer ${token}` },
		});
		return data;
	} catch (error) {
		thunkAPI.dispatch(langSlice.actions.setSnackbar({ type: 'error', time: 2000, text: 'Something wrong' }));
		console.log(error);
	}
});

export const deleteTask = createAsyncThunk('tasks/deleteTask', async (taskId: string, thunkAPI) => {
	try {
		const { token } = getTokens();
		const { data } = await instance.delete(`/task/${taskId}`, { headers: { Authorization: `Bearer ${token}` } });
		return data;
	} catch (error) {
		thunkAPI.dispatch(langSlice.actions.setSnackbar({ type: 'error', time: 2000, text: 'Something wrong' }));
		console.log(error);
	}
});

export type TaskType = {
	task_id: string;
	owner: string;
	action?: string;
	status: 'PENDING' | 'PROGRESS' | 'SUCCESS' | 'FAILURE';
	progress?: {
		current: number;
		total: number;
		base: number;
		reminder: number;
		start_time: number;
		cur_time: number;
		name: string;
		title: string;
	};
	current?: number;
	total?: number;
	add_time: string;
	done_time: string;
	title: string;
	task_interval: string;
	startProgress?: boolean;
};

type initialStateType = {
	tasks: TaskType[];
	realtimeTasks: TaskType[];
	newTaskMessage: boolean;
};

const initialState: initialStateType = {
	tasks: [],
	realtimeTasks: [],
	newTaskMessage: false,
};

export const tasksSlice = createSlice({
	name: 'tasks',
	initialState,
	reducers: {
		setTasks(state, action: PayloadAction<TaskType[]>) {
			state.tasks = action.payload;
		},
		addTask(state, action: PayloadAction<TaskType>) {
			state.tasks.push(action.payload);
		},

		addRealtimeTask(state, action: PayloadAction<TaskType>) {
			state.realtimeTasks.push(action.payload);
		},
		replaceRealtimeTask(state, action: PayloadAction<{ task: TaskType; index: number }>) {
			const tasks = [...current(state.realtimeTasks)];
			tasks[action.payload.index] = action.payload.task;
			state.realtimeTasks = tasks;
		},
		removeRealtimeTask(state, action: PayloadAction<number>) {
			const tasks = [...current(state.realtimeTasks)];
			tasks.splice(action.payload, 1);
			state.realtimeTasks = tasks;
		},
		setNewTaskMessage(state, action: PayloadAction<boolean>) {
			state.newTaskMessage = action.payload;
		},
	},
});
