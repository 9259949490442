import React, { FC } from 'react';
import styles from './styles.module.scss';

interface SettingsTitleProps {
	className?: string;
}

const CardContainerTitle: FC<SettingsTitleProps> = ({ children, className }) => (
	<h3 className={`${styles.title} ${className}`}>{children}</h3>
);

export default CardContainerTitle;
