import { makeStyles } from '@mui/styles';
import variables from 'styles/variables.scss';

export const getGlobalStyles = makeStyles({
	// скрытый тег
	grayTag: {
		backgroundColor: '#F8FAFC',
		color: '#738094',
		border: '1px solid #E3E8EF',
		padding: '1px 8px',
		marginRight: '4px',
		marginBottom: '4px',
		borderRadius: '5px',
		cursor: 'pointer',
		fontSize: '12px',
	},
	blueTag: {
		backgroundColor: variables.geekblue_2,
		color: variables.geekblue_9,
		border: '1px solid #85A5FF',
		padding: '1px 8px',
		marginRight: '4px',
		marginBottom: '4px',
		borderRadius: '5px',
		cursor: 'pointer',
	},
	purpleTag: {
		backgroundColor: variables.purple_2,
		color: variables.purple_7,
		border: '1px solid #B37FEB',
		padding: '1px 8px',
		marginRight: '4px',
		marginBottom: '4px',
		borderRadius: '5px',
		cursor: 'pointer',
		fontSize: '12px',
	},
	orangeTag: {
		backgroundColor: variables.orange_3,
		color: variables.orange_9,
		border: `1px solid ${variables.orange_6}`,
		padding: '1px 8px',
		marginRight: '4px',
		marginBottom: '4px',
		borderRadius: '5px',
		cursor: 'pointer',
		fontSize: '12px',
	},
	lightBlueTag: {
		backgroundColor: variables.cyan_2,
		color: variables.cyan_9,
		border: `1px solid ${variables.cyan_5}`,
		padding: '1px 8px',
		marginRight: '4px',
		marginBottom: '4px',
		borderRadius: '5px',
		cursor: 'pointer',
		fontSize: '12px',
	},
	selectWrapperComplex: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		marginBottom: '2px',
	},
	selectWrapperMultiString: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
	},
	selectTitleComplex: {
		fontSize: '14px !important',
		color: '#738094 !important',
		marginBottom: '4px !important',
	},
	selectTitleMultiString: {
		fontSize: '13px !important',
		fontWeight: '700 !important',
		color: '#738094 !important',
		marginBottom: '4px !important',
	},
	selectListWrapper: {
		height: '380px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	resetButton: {
		backgroundColor: 'transparent',
		padding: 0,
	},
});
