import React, { FC, memo } from 'react';
import { searchSlice } from 'store';
import { useAppDispatch } from 'hooks/redux';
import { useMuiTextSelectStyles } from '../AddFilterSelect.jss';
import { CrossSvgWithBg, OnBottomArrow, OnTopArrow } from '../AddFilterSelect.svg';

const Arrow = memo(({ isMenuOpen, ifArrowColor }: {isMenuOpen: boolean, ifArrowColor: string}) => {
	const classes = useMuiTextSelectStyles({ arrowColor: ifArrowColor });

	if (isMenuOpen) {
		return (
			<div className={classes.selectArrowOnTopBox}>
				<OnTopArrow className={classes.selectArrow} />
			</div>
		);
	}
	return <OnBottomArrow className={classes.selectArrow} />;
});

const CustomSelectControl: FC<any> = ({ children, ...props }) => {
	const dispatch = useAppDispatch();
	// выполняется клике на селекта.
	const handleSelectClick = () => {
		props.setIsMenuOpen(true);
	};

	return (
		<div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', margin: '2px' }}>
			<div style={{ display: 'flex', alignItems: 'center' }} onClick={handleSelectClick}>
				{props.iconPosition === 'left' ? props.icon || <Arrow isMenuOpen={props.isMenuOpen} ifArrowColor={props.ifArrowColor} /> : null}
				{props.customControl}
				{props.iconPosition === 'right' ? props.icon || <Arrow isMenuOpen={props.isMenuOpen} ifArrowColor={props.ifArrowColor} /> : null}
			</div>
			{props.name === 'templatesSelect' && props.value && (
				<CrossSvgWithBg
					style={{ fill: '#722ED1', cursor: 'pointer', marginLeft: '15px' }}
					onClick={() => {
						dispatch(searchSlice.actions.setCurrentTemplate(null));
						dispatch(searchSlice.actions.removeAllActiveCriterias({ page: 'calls', data: null }));
					}}
				/>
			)}
		</div>
	);
}

export default CustomSelectControl;
