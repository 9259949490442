import { WidgetProps } from '@rjsf/utils';
import { CustomCheckbox } from 'components/common';
import { useAppSelector } from 'hooks/redux';
import { translate } from 'localizations';

const CustomCheckboxWidget = (props: WidgetProps) => {
	const { language } = useAppSelector((state) => state.lang);

	return (
		<div style={{ marginBottom: '8px' }}>
			<CustomCheckbox
				id={props.name}
				checked={props.value}
				name={props.name}
				style={{
					marginRight: '10px',
				}}
				color="default"
				theme="black"
				testid={props.name}
				tooltipTheme="dark"
				sizeCheckbox="overall"
				tooltipPlacement="right"
				tooltip={translate(`${props.name}_tooltip`, language)}
				onChange={(e) => {
					props.onChange(e.target.checked);
				}}
				onFocus={() => props.onFocus(props.name, props.value)}
				onBlur={() => props.onBlur(props.name, props.value)}
				label={translate(props.name, language) ?? props.label}
			/>
		</div>
	);
};

export default CustomCheckboxWidget;
