import { Skeleton } from '@mui/material';
import { FC } from 'react';

interface SkeletonBlockProps {
	className?: string;
}

const SkeletonBlock: FC<SkeletonBlockProps> = ({ className }) => (
	<div className={className}>
		<Skeleton variant="text" width={550} height={25} />
		<Skeleton variant="text" width={550} height={45} />
	</div>
);

export default SkeletonBlock;
