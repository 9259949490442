import { useEffect, useState } from 'react';
import { optionsForSelects, optionsForSelectsWithType } from 'pages/Notifications/types/NotificationsTypes';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'pages/Settings/types';
import { SelectDataItem } from '../ui/DynamicSelects';
import { translate } from 'localizations';
import { useNotifyRules } from 'pages/Notifications/hooks/useNotifications';

interface useDynamicSelectsProps {
	initialValue?: any;
	options?: optionsForSelectsWithType[];
	onChange?: (value: any) => void;
	tagNamesWithValues?: string[];
}

export const useDynamicSelects = (props: useDynamicSelectsProps) => {
	const { initialValue, options, onChange, tagNamesWithValues } = props;
	const { lang, notifyRuleId } = useParams<RouteParams>();

	const { data } = useNotifyRules();
	const { getAmoFillableCustomFieldsError, getBitrixFillableCustomFieldsError } = data;

	const [selectData, setSelectData] = useState<SelectDataItem[]>([]);
	const [dataIsLoading, setDataIsLoading] = useState(true);
	const [lastUpdatedRuleId, setLastUpdatedRuleId] = useState<string | null>(null);

	// Обрабатываем флаг dataIsLoading
	useEffect(() => {
		if (initialValue?.length > 0 && (options?.length ?? 0) > 0 && (tagNamesWithValues?.length ?? 0) > 0) {
			setDataIsLoading(false);
		}
	}, [initialValue, options, tagNamesWithValues]);

	// Устанавливаем данные из initialValue в selectData
	useEffect(() => {
		if (!dataIsLoading) {
			setSelectData(
				initialValue?.map((val: any) => {
					const leftSelected = options?.find((el) => el.value === val.value);
					const rightSelectedData = leftSelected?.value === val.value && val.label;

					const rightSelected = leftSelected?.options
						? leftSelected?.options.find((el) => el.label === rightSelectedData)
						: { label: val.label, value: val.value };

					return {
						leftSelected: leftSelected || { label: '', value: '' },
						rightSelected: rightSelected || { label: '', value: '' },
						leftOptions: options?.map((el) => {
							return { label: el.label, value: el.value };
						}),
						rightOptions: leftSelected?.options,
					};
				}),
			);
		}
	}, [dataIsLoading, initialValue, options, tagNamesWithValues]);

	// Перезаписываем данные из initialValue в selectData, если юзер переключился между правилами
	useEffect(() => {
		if (notifyRuleId && lastUpdatedRuleId !== notifyRuleId) {
			setSelectData(
				initialValue?.map((val: any) => {
					const leftSelected = options?.find((el) => el.value === val.value);
					const rightSelectedData = leftSelected?.value === val.value && val.label;
					const rightSelected = leftSelected?.options
						? leftSelected?.options.find((el) => el.label === rightSelectedData)
						: { label: val.label, value: val.value };

					return {
						leftSelected: leftSelected || { label: '', value: '' },
						rightSelected: rightSelected || { label: '', value: '' },
						leftOptions: options?.map((el) => {
							return { label: el.label, value: el.value };
						}),
						rightOptions: leftSelected?.options,
					};
				}),
			);
			setLastUpdatedRuleId(notifyRuleId);
		}
	}, [notifyRuleId, initialValue, options, tagNamesWithValues, lastUpdatedRuleId]);

	const handleAdd = () => {
		setSelectData([
			...selectData,
			{
				leftSelected: { label: translate('selectValue', lang), value: translate('selectValue', lang) },
				rightSelected: '',
				leftOptions: options?.map((el) => {
					return { label: el.label, value: el.value };
				}),
				rightOptions: [] as optionsForSelects[],
			},
		]);
	};

	const handleRemove = (index: number) => {
		const newSelects = [...selectData];
		newSelects.splice(index, 1);
		setSelectData(newSelects);

		if (onChange) {
			onChange(newSelects);
		}
	};

	const handleSelectChange = (value: any, index: number, isLeft: boolean) => {
		setSelectData((prevSelectData) => {
			const newSelectData = [...prevSelectData];
			if (isLeft) {
				newSelectData[index].leftSelected = value;
				newSelectData[index].rightOptions = options
					?.filter((el) => el.value === value.value)[0]
					?.options?.map((el) => {
						return { label: el.label, value: el.value };
					});
			} else {
				newSelectData[index].rightSelected = value;
			}
			return newSelectData;
		});

		if (onChange) {
			onChange(selectData);
		}
	};

	const handleTextareaChenge = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setSelectData((prevSelectData) => {
			const newSelectData = [...prevSelectData];
			const index = e.target.dataset.testid?.split('-')[1];
			const objId = e.target.dataset.testid?.split('-')[0];
			newSelectData[Number(index)].rightSelected = { label: e.target.value, value: objId };

			onChange?.(newSelectData);

			return newSelectData;
		});
	};

	const dynamicSelectsData = {
		lang,
		selectData,
		getAmoFillableCustomFieldsError,
		getBitrixFillableCustomFieldsError,
	};

	const dynamicSelectsMathods = { handleAdd, handleRemove, handleSelectChange, handleTextareaChenge };

	return { dynamicSelectsData, dynamicSelectsMathods };
};
