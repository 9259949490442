import React, { FC } from 'react';

const OnBottomArrow: FC<React.SVGProps<SVGSVGElement>> = (props) => (
	<svg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M7.39101 0.400166C7.61026 0.636846 7.61026 1.02059 7.39101 1.25725L4.39685 4.48958C4.1776 4.72627 3.8222 4.72627 3.60295 4.48958L0.608766 1.25725C0.389526 1.02059 0.389526 0.636846 0.608766 0.400166C0.828006 0.163486 1.18347 0.163486 1.40271 0.400166L3.9999 3.20392L6.59711 0.400166C6.81636 0.163486 7.17176 0.163486 7.39101 0.400166Z'
		/>
	</svg>
);

export default OnBottomArrow;
