import { FC } from 'react';
import classNames from 'classnames';
import { TitleWithTooltip } from '../index';
import { TitleWithTooltipProps } from '../TitleWithTooltip/TitleWithTooltip';

import s from './styles.module.scss';

const WithTitle: FC<TitleWithTooltipProps & { containerClassName?: string }> = ({
	title,
	tooltipTitle,
	theme,
	size,
	containerClassName,
	rootClassName,
	...props
}) => (
	<div className={containerClassName}>
		<TitleWithTooltip
			rootClassName={classNames(s.title, rootClassName)}
			title={title}
			tooltipTitle={tooltipTitle}
			theme={theme}
			size={size}
		/>
		{props.children}
	</div>
);

export default WithTitle;
