import { SttValues, SttValuesForRequest, SttValuesLocal } from "../namespaces";

const useValuesStt = () => {
  // возврат sttOptions в нужной форме
  const returnSTTValues = (values: SttValues | SttValuesLocal, sttOptionsMap: SttValues["sttOptionsMap"]): SttValuesForRequest => {
    return {
      sttOptions: {
        sttEngine: values.sttEngine?.value as string,
        sttEconomize: values.sttEconomize,

        sttOptionsMap: {
          language: values.sttLanguage?.value as string,
          model: values.sttModel?.value as string,
          ...sttOptionsMap,
        },
      }
    };
  }

  return {  
    returnSTTValues
  }
}

export default useValuesStt;