import { FC } from 'react';
import cn from 'classnames';
import { TagsClassNamesEnum } from './types';
import { TagFormat } from 'widgets/ManualTagging/types';
import s from './style.module.scss';

interface EmptyTagProps {
	classNameTag: TagsClassNamesEnum;
	tag: TagFormat | null | undefined;
}

const EmptyTag: FC<EmptyTagProps> = ({ classNameTag, tag }) => {
	return <div className={cn(s[classNameTag], [s.emptyTagWidth])}>{tag && tag?.value}</div>;
};

export default EmptyTag;
