import { EnvsManager } from './enviroments';

export const isDevStand = () => {
	const hrefArr = window.location.href.split('/');
	return hrefArr.find((item) => item.includes('feature'));
};

/**
 * Формирует url с учетом окружения
 * при деплое на дев стенд вернет url с названием ветки
 */
export const getUrlForDev = (url: string) => {
	const branchName = isDevStand();
	const base = EnvsManager.isDev && branchName ? `/${branchName}` : '';

	return `${base}/${url}`;
};
