import { FC, SVGProps } from 'react';

interface CloudIconProps extends SVGProps<SVGSVGElement> {
	color?: string;
}

const CloudIcon: FC<CloudIconProps> = ({ color = '#738094', ...props }) => (
	<svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.1598 11.1609C15.9467 11.3767 15.9467 11.7266 16.1598 11.9424L18.6831 14.499L16.1598 17.0557C15.9467 17.2715 15.9467 17.6213 16.1598 17.8372C16.3728 18.053 16.7181 18.053 16.9311 17.8372L19.8402 14.8898C20.0533 14.674 20.0533 14.3241 19.8402 14.1083L16.9311 11.1609C16.7181 10.9451 16.3728 10.9451 16.1598 11.1609Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.1598 11.1609C11.9467 11.3767 11.9467 11.7266 12.1598 11.9424L14.6831 14.499L12.1598 17.0557C11.9467 17.2715 11.9467 17.6213 12.1598 17.8372C12.3728 18.053 12.7181 18.053 12.9311 17.8372L15.8402 14.8898C16.0533 14.674 16.0533 14.3241 15.8402 14.1083L12.9311 11.1609C12.7181 10.9451 12.3728 10.9451 12.1598 11.1609Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20.1598 11.1609C19.9467 11.3767 19.9467 11.7266 20.1598 11.9424L22.6831 14.499L20.1598 17.0557C19.9467 17.2715 19.9467 17.6213 20.1598 17.8372C20.3728 18.053 20.7181 18.053 20.9311 17.8372L23.8402 14.8898C24.0533 14.674 24.0533 14.3241 23.8402 14.1083L20.9311 11.1609C20.7181 10.9451 20.3728 10.9451 20.1598 11.1609Z"
			fill={color}
		/>
		<path
			d="M24 8.49503C24 -0.504778 12.3751 -0.379836 11.5 8.00064C9.50007 4.00398 2.62501 6.38548 4.62498 11.3815C-0.374988 11.3815 0.125021 17.8831 4.12492 17.8794H8.5"
			stroke={color}
			strokeLinecap="round"
			strokeLinejoin="bevel"
		/>
	</svg>
);

export default CloudIcon;
