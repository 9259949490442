import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { TextArea } from 'components/shared/Forms/TextArea/TextArea';
import TitleWithTooltip, { TitleWithTooltipProps } from '../TitleWithTooltip/TitleWithTooltip';
import cls from './styles.module.scss';

export interface TextareaWithHeaderProps extends Partial<TitleWithTooltipProps> {
	externalValue?: string;
	placeholder: string;
	onChangeValue?: (value: string) => void;
	titleClassName?: string;
	rootClassName?: string;
	textareaClassName?: string;
	textFieldClassName?: string;
	isRequired?: boolean;
	rows?: number;
}

const TextareaWithHeader: FC<TextareaWithHeaderProps> = ({
	externalValue = '',
	onChangeValue,
	placeholder,
	titleClassName,
	rootClassName,
	textareaClassName,
	isRequired = false,
	textFieldClassName,
	rows = 3,
	...props
}) => {
	const [value, setValue] = useState(externalValue || '');

	useEffect(() => {
		setValue(externalValue);
	}, [externalValue]);

	const handleChangeValue = (e: React.ChangeEvent<any>) => {
		const newValue = e.target.value;
		setValue(newValue);

		if (onChangeValue) onChangeValue(newValue);
	};

	return (
		<div className={classNames(cls.root, rootClassName)}>
			<TitleWithTooltip
				title={props.title || ''}
				tooltipTitle={props.tooltipTitle}
				theme={props.theme || 'gray'}
				size={props.size || 'md'}
				rootClassName={titleClassName}
				isRequired={isRequired}
			/>

			<TextArea
				classNameRoot={textareaClassName}
				className={classNames(cls.textarea, textFieldClassName)}
				placeholder={placeholder}
				value={value}
				onChange={handleChangeValue}
				rows={rows}
			/>
		</div>
	);
};

export default TextareaWithHeader;
