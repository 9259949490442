import React, { FC } from 'react';

const SendIcon: FC<React.SVGProps<SVGSVGElement>> = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
		{/* eslint-disable-next-line max-len */}
		<path d="M13.9921 7.12488L3.49207 1.13176C3.31507 1.03247 3.11206 0.989387 2.91 1.00822C2.70794 1.02706 2.51639 1.10693 2.3608 1.23722C2.20522 1.36751 2.09295 1.54206 2.03892 1.73767C1.98489 1.93328 1.99166 2.1407 2.05832 2.33238L3.99582 7.98676C3.99557 7.98883 3.99557 7.99093 3.99582 7.99301C3.99547 7.99507 3.99547 7.99719 3.99582 7.99926L2.05832 13.6661C2.00493 13.8169 1.9885 13.9783 2.01039 14.1367C2.03228 14.2952 2.09186 14.4461 2.18413 14.5767C2.27639 14.7074 2.39866 14.814 2.54065 14.8876C2.68265 14.9613 2.84024 14.9998 3.00019 14.9999C3.17373 14.9994 3.34423 14.9542 3.49519 14.8686L13.9896 8.86551C14.1444 8.77879 14.2733 8.65245 14.3632 8.49945C14.453 8.34644 14.5005 8.17229 14.5008 7.99486C14.5012 7.81742 14.4543 7.6431 14.365 7.48978C14.2757 7.33645 14.1472 7.20965 13.9927 7.12238L13.9921 7.12488ZM3.00019 13.9999V13.9943L4.88394 8.49988H8.50019C8.6328 8.49988 8.75998 8.4472 8.85374 8.35344C8.94751 8.25967 9.00019 8.13249 9.00019 7.99988C9.00019 7.86727 8.94751 7.7401 8.85374 7.64633C8.75998 7.55256 8.6328 7.49988 8.50019 7.49988H4.88894L3.00394 2.00738L3.00019 1.99988L13.5002 7.98926L3.00019 13.9999Z" fill="white"/>
	</svg>
);

export default SendIcon;
