import { FC } from 'react';
import { ContainedSelect } from 'components/common';
import { OptionType } from 'store/user/user.slice';
import { translate } from 'localizations';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'pages/Settings/types';
import { useUserSelect } from '../hooks/useUserSelect';
import cls from './CustomSelect.module.scss';

interface UserSelectProps {
	mode: 'SelectForHeader' | 'SimpleSelect';
	isSearchable: boolean;
	width: string;
	height?: string;
	onUserSelect: (selectedUser: OptionType) => void;
	defaultUser?: OptionType;
	externalSelectOptions?: OptionType[];
}

const UserSelect: FC<UserSelectProps> = (props) => {
	const { lang } = useParams<RouteParams>();
	const {
		mode,
		isSearchable,
		width,
		height,
		onUserSelect,
		defaultUser = { value: 'user', label: translate('select_user', lang) },
		externalSelectOptions,
	} = props;
	const { useUserSelectData, useUserSelectMethods } = useUserSelect({ mode, onUserSelect, defaultUser });
	const {
		selectOptions,
		selectedUser,
		customStyles,
		controlStyles,
		valueContainerStyles,
		indicatorStyles,
		convertedValue,
	} = useUserSelectData;
	const { handleUserChange } = useUserSelectMethods;

	return (
		<div className={mode === 'SelectForHeader' ? cls.selectForHeader : cls.simpleSelect}>
			<ContainedSelect
				width={width}
				height={height}
				onSelectChange={handleUserChange}
				options={externalSelectOptions ? externalSelectOptions : selectOptions}
				value={mode === 'SelectForHeader' ? convertedValue : selectedUser}
				isSearchable={isSearchable}
				customStyleProps={customStyles}
				controlStyles={controlStyles}
				valueContainerStyles={valueContainerStyles}
				indicatorStyles={indicatorStyles}
			/>
		</div>
	);
};

export default UserSelect;
