import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NotificationsState {
	selectedCall: string;
}

const initialState: NotificationsState = {
	selectedCall: '',
};

const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		setSelectedCall: (state, action: PayloadAction<string>) => {
			state.selectedCall = action.payload;
		},
	},
});

export const { setSelectedCall } = notificationsSlice.actions;

export default notificationsSlice.reducer;
