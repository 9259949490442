import { WidgetProps } from '@rjsf/utils';
import STTSelect from 'widgets/STT/components/STTSelect/STTSelect';

const CustomSelectWidget = (props: WidgetProps) => {
	return (
		<div style={{ marginBottom: '16px' }}>
			<STTSelect
				{...props}
				options={props.options.enumOptions}
				onSelectionChange={props.onChange}
				testId={props.name}
				// title={props.label}
				value={props.value ?? props.options.enumOptions?.[0]}
			/>
		</div>
	);
};

export default CustomSelectWidget;
