import { FC } from 'react';
import { IconButton, MinusIcon } from 'shared/ui';

type MinusButtonPropsType = {
	margin?: string;
	handleClick?: any;
	disabled?: boolean;
	iconColor?: string;
	iconWidth?: string;
	iconHeight?: string;
};

const MinusButton: FC<MinusButtonPropsType> = (props) => {
	const { disabled, margin, handleClick, iconColor, iconWidth, iconHeight } = props;

	return (
		<IconButton
			disabled={disabled}
			margin={margin || '0'}
			backgroundColor="#ffcbcb"
			icon={<MinusIcon width={iconWidth} height={iconHeight} color={iconColor} />}
			onClick={handleClick}
		/>
	);
};

export default MinusButton;
