import en from './en.json';
import ru from './ru.json';
import es from './es.json';
import pt from './pt.json';

// Определение типа для данных перевода
type TranslationData = Record<string, string>;

// Уточнение типа для langDataMap
const langDataMap: { [key: string]: TranslationData } = {
	en,
	ru,
	es,
	pt,
};

export const translate = (key: string, language: string): string => {
	const langData = langDataMap[language] || en;

	// Я не знаю, как это исправить. Всё перепробовал.
	// @ts-ignore
	return langData[key] ?? en[key];
};
