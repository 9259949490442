import { translate } from 'localizations';
import { useAppSelector } from 'hooks/redux';
import { RootState } from 'store/store';
import { ChartType } from 'store/reports/reports.types';

export const useReportTranslations = () => {
	const { language } = useAppSelector((state: RootState) => state.lang);

	const selectTitlesOptions = {
		calls: translate('reportTypeCalls', language),
		time: translate('reportGroupByRowTime', language),
		search_items: translate('reportGroupByRowSearchItems', language),
		tag: translate('reportGroupByRowTag', language),
		call: translate('reportCall', language),
		tag_name_list: translate('reportGroupByRowTagNameList', language),
		tag_value_list: translate('reportGroupByRowTagValueList', language),
		tag_name_value: translate('reportGroupByRowTagNameValue', language),
		operator_phone: translate('reportGroupByRowOperatorPhone', language),
		client_phone: translate('reportGroupByRowClientPhone', language),
		calls_count: translate('reportGroupByRowCallsCount', language),
		checklist_avg: translate('reportGroupByChecklist', language),
		stt_engine: translate('reportGroupByRowSttEngine', language),
		hour: translate('reportGroupByTimeHour', language),
		day: translate('reportGroupByTimeDay', language),
		week: translate('reportGroupByTimeWeek', language),
		month: translate('reportGroupByTimeMonth', language),
		no_grouping: translate('reportGroupByNoGrouping', language),
	};

	const chartTypeOptions: { value: ChartType; label: string }[] = [
		{ label: translate('barChart', language), value: 'histogram' },
		{ label: translate('lineChart', language), value: 'line_chart' },
	];

	const translatedText = {
		callsCount: translate('reportCallsCount', language),
		no_grouping: translate('reportGroupByNoGrouping', language),

		calls_count: translate('reportCalls', language),
		calls_seconds: translate('reportsTime', language),
		percent_from_total_count: translate('reportPercent', language),
		percent_from_all_calls_col: translate('reportPercentageFromAllCallsCol', language),
		percent_from_all_calls_row: translate('reportPercentageFromAllCallsRow', language),
		percent_from_sum_calls_row: translate('reportPercentageFromSumRow', language),
		percent_from_sum_calls_col: translate('reportPercentageFromSumCol', language),
		checklist_avg: translate('reportAverage', language),
		checklist_avg_percent: translate('reportCheckListPercent', language),
		client_talk_time: translate('reportClientTalk', language),
		client_talk_time_percentage: translate('reportClientPercent', language),
		operator_talk_time: translate('reportOperatorTalk', language),
		operator_talk_time_percentage: translate('reportOperatorPercent', language),
		first_call_dt: translate('reportCallDtFirst', language),
		last_call_dt: translate('reportCallDtLast', language),
		silence_duration: translate('reportSilence', language),
		silence_duration_percentage: translate('reportDurationPercent', language),
		tags_count: translate('reportTagsCount', language),
		client_phones: translate('reportClientPhones', language),
		operator_phones: translate('reportOperatorPhones', language),
		points: translate('reportSumPoints', language),
		max_points: translate('reportMaxSumPoints', language),
		from_services: translate('reportFromServices', language),

		row_sum: translate('reportSumCalls', language),
		row_all: translate('reportAllCalls', language),
		row_missed: translate('reportMissedCol', language),

		sumCalls: translate('reportSumCalls', language),
		all: translate('reportAll', language),
		allCalls: translate('reportAllCalls', language),
		missedCol: translate('reportMissedCol', language),
	};

	return {
		selectTitlesOptions,
		chartTypeOptions,
		translatedText,
	};
};
