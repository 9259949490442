import { FC, SVGProps } from 'react';

const TranslateIcon: FC<SVGProps<SVGSVGElement>> = ({ fill, ...props }) => (
	<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M7.90498 14L10.7873 6.8H12.1176L15 14H13.6697L12.9887 12.17H9.91629L9.23529 14H7.90498ZM2.90045 12.2L2.01357 11.36L5.21267 8.33C4.84314 7.98 4.50792 7.58 4.20701 7.13C3.90611 6.68 3.62896 6.17 3.37557 5.6H4.70588C4.91704 5.99 5.1282 6.33 5.33937 6.62C5.55053 6.91 5.80392 7.2 6.09955 7.49C6.44796 7.16 6.80958 6.6975 7.18439 6.1025C7.5592 5.5075 7.84163 4.94 8.03167 4.4H1V3.2H5.43439V2H6.70136V3.2H11.1357V4.4H9.29864C9.07692 5.12 8.74434 5.86 8.3009 6.62C7.85747 7.38 7.41931 7.96 6.98642 8.36L8.50679 9.83L8.03167 11.06L6.09955 9.185L2.90045 12.2ZM10.3122 11.12H12.5928L11.4525 8.06L10.3122 11.12Z"
			fill={fill}
		/>
	</svg>
);

export default TranslateIcon;
