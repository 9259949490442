import { FC } from 'react';

interface RebootSvgProps {
	width?: string;
	height?: string;
	color?: string;
	props?: any;
}

const RebootSvg: FC<RebootSvgProps> = ({ width, height, color = '#738094', props }) => (
	<svg
		width={width}
		height={height}
		viewBox={`0 0 ${width} ${height}`}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M4.36267 5.44226C4.21919 5.40441 4.09994 5.30478 4.03716 5.17032C3.97439 5.03586 3.97458 4.88047 4.03769 4.74617L5.14486 2.38991C5.22736 2.21431 5.404 2.1023 5.59801 2.10254C5.79202 2.10278 5.96837 2.21523 6.05044 2.39103L6.53886 3.43718C6.55632 3.43032 6.57436 3.42437 6.59291 3.41939C9.49111 2.64282 12.4702 4.36275 13.2467 7.26097C14.0233 10.1592 12.3034 13.1382 9.40518 13.9148C6.50692 14.6914 3.5279 12.9714 2.75132 10.0732C2.50436 9.15157 2.37364 8.83264 2.58992 7.96678C2.65685 7.69891 2.92829 7.53598 3.1962 7.60291C3.46411 7.66978 3.62704 7.94124 3.56011 8.20918C3.38392 8.91451 3.51534 9.06091 3.71724 9.81444C4.35088 12.1792 6.78158 13.5825 9.14631 12.9489C11.5111 12.3152 12.9144 9.88457 12.2808 7.51984C11.6575 5.19371 9.29551 3.79783 6.96778 4.35587L7.46058 5.4114C7.54264 5.58719 7.51558 5.7946 7.39124 5.94349C7.26684 6.09237 7.06758 6.15587 6.87998 6.10638L4.36267 5.44226Z"
			fill={color}
		/>
	</svg>
);

export default RebootSvg;
