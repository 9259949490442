import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { instance } from '../../api/api.config';
import { SnackbarParams } from '../../components/common/Snackbar/Snackbar';
import { getTokens } from '../../utils/tokens';

export type LangType = 'ru' | 'en' | 'es' | 'pt';

export const getLang = createAsyncThunk('search/getBaseSearchCriterias', async (payload: string, thunkAPI) => {
	try {
		const { token } = getTokens();
		await instance.get(`set_language?language=${payload}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (e) {
		console.error(e);
	}
});

export const getAllLanguages = createAsyncThunk('search/getAllLanguages', async (_, thunkAPI) => {
	try {
		const { token } = getTokens();
		const { data } = await instance.get('translation/get_all_languages', {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		// eslint-disable-next-line no-use-before-define
		thunkAPI.dispatch(langSlice.actions.setAllLanguages(data));
	} catch (e) {
		console.error(e);
	}
});

type InitialStateType = {
	// TODO: поменять string на LangType здесь и везде
	language: string;
	loading: boolean;
	snackbar: SnackbarParams | null;
	allLanguages: { value: string; label: string }[];
};

const initialState: InitialStateType = {
	language: 'ru',
	loading: false,
	snackbar: null,
	allLanguages: [],
};

export const langSlice = createSlice({
	name: 'language',
	initialState,
	reducers: {
		setLang(state, action: PayloadAction<string>) {
			state.language = action.payload;
		},

		setDefaultLang(state, action: PayloadAction<null>) {
			state.language = 'ru';
		},

		setAllLanguages(state, action: PayloadAction<{ value: string; label: string }[]>) {
			state.allLanguages = action.payload;
		},

		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},

		setSnackbar(state, action: PayloadAction<SnackbarParams>) {
			state.snackbar = action.payload;
		},

		langReset: () => initialState,
	},
});
