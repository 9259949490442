import React, { FC, memo } from 'react';
import { TagThemesTypes } from 'pages/Calls/types';
import { translate } from 'localizations';
import { LoadingButton } from '@mui/lab';
import { useToggleOpen } from 'hooks';
import cn from 'classnames';
import s from './styles.module.scss';
import TagTablet from './ui/TagTablet';
import TagSimple from './ui/TagSimple';
import ModalWindow from '../ModalWindow/ModalWindow';

interface ReusableTagProps {
	id?: string | null | undefined;
	name: string | undefined;
	value: string | null;
	theme: TagThemesTypes;
	language: string;
	count?: number | null;
	additionalStyles?: React.CSSProperties;
	cursor?: string;
	isStack?: boolean; // вид стопки, обычно внутри коммуникации
	accessRights: boolean | undefined;
	settings?: boolean; // флаг включения меню при ховере
	deleteTag?: ((id: string) => Promise<void>) | undefined;
	handleClickTag: (value: string | null) => void;
	isActiveTag: boolean; // флаг активного тега по клику
	onClick?: (e: any) => void;
}

const NewTagComponent: FC<ReusableTagProps> = ({
	id,
	name,
	value,
	theme,
	count,
	additionalStyles,
	isStack,
	accessRights,
	settings,
	language,
	handleClickTag,
	deleteTag,
	isActiveTag,
	onClick,
	cursor = 'default',
}) => {
	const className = `${theme}Tag`;
	const maxLength = 20;
	const tagLength = name?.length || 0;
	const valueLength = value ? value.length : 0;

	const hasTagTooltip = tagLength > maxLength;
	const hasValueTooltip = valueLength > maxLength;

	const classNameTablet = isStack ? s.tagTabletStack : s.tagTablet;

	const { toggle: handleModalToDeleteTag, isShowing: isOpenModalToDeleteTag } = useToggleOpen();

	const handleOpenModalToDelete = () => {
		handleModalToDeleteTag();
	};

	const deleteTagAsync = async () => {
		if (id) {
			await deleteTag?.(id);
			handleModalToDeleteTag();
		}
	};

	
	return (
		<div
			className={cn(s[className], { [s.tagMinWidth]: settings })}
			style={{ cursor: cursor, ...additionalStyles }}
			onClick={onClick ?? undefined}
		>
			{value ? (
				// вариант со значением = таблетка
				<TagTablet
					id={id}
					name={name}
					count={count}
					value={value}
					theme={theme}
					isActiveTag={isActiveTag}
					settings={settings}
					language={language}
					accessRights={accessRights}
					handleDeleteTag={handleOpenModalToDelete}
					classNameTablet={classNameTablet}
					hasTagTooltip={hasTagTooltip}
					hasValueTooltip={hasValueTooltip}
					handleClickTag={handleClickTag}
				/>
			) : (
				// вариант обычный
				<TagSimple
					id={id}
					name={name}
					count={count}
					theme={theme}
					isActiveTag={isActiveTag}
					settings={settings}
					language={language}
					accessRights={accessRights}
					hasTagTooltip={hasTagTooltip}
					handleDeleteTag={handleOpenModalToDelete}
					handleClickTag={handleClickTag}
				/>
			)}
			<ModalWindow
				isMWOpen={isOpenModalToDeleteTag}
				handleMWClose={handleModalToDeleteTag}
				text={translate('manualModalDelete', language)}
			>
				<div className={s.modalBtns}>
					<LoadingButton variant="contained" color="primary" type="submit" onClick={deleteTagAsync}>
						{translate('delete', language)}
					</LoadingButton>
					<LoadingButton variant="outlined" color="primary" onClick={handleModalToDeleteTag}>
						{translate('cancelButton', language)}
					</LoadingButton>
				</div>
			</ModalWindow>
		</div>
	);
};

export default memo(NewTagComponent);
