import { useHistory } from 'react-router-dom';
import { getMeAccessRights, getUserFilter, getUserToken, userSlice } from '../store/user/user.slice';
import { UserType } from '../store/user/user.types';
import { useAppDispatch } from './redux';
import { getTokenWithAuthCheck } from '../utils/tokens';

const useResetChildUser = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();

	const getAccessRights = async () => {
		const accessRights = await dispatch(getMeAccessRights());
		if (accessRights) {
			// @ts-ignore
			dispatch(userSlice.actions.setAccessRights(accessRights.payload));
		}
	};
	const resetChildUser = async (accessToken?: string) => {
		const token = getTokenWithAuthCheck();
		window.sessionStorage.setItem('sessionToken', JSON.stringify({ sessionToken: token }));

		let currentToken = accessToken;

		await getAccessRights();

		if (!accessToken) {
			const pathArray = history?.location?.pathname.split('/');
			const tokenData = await dispatch(getUserToken(pathArray[2]));
			const tokenDataPayload = tokenData.payload;
			currentToken = tokenDataPayload?.access_token;
		}

		if (currentToken) {
			const childUserData = await dispatch(getUserFilter({ sessionToken: currentToken }));
			const childUser = childUserData.payload as UserType;
			if (childUser) {
				dispatch(userSlice.actions.setChildUser(childUser));
			}
		}
	};

	return { resetChildUser };
};

export default useResetChildUser;
