/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import React, { useState } from 'react';
import { Typography, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import CallScore from 'components/shared/Score/CallScore';
import { TCallCheckList } from 'store/checkLists/namespaces/responses';
import { TUpdateCurrentCallCheckListAnswerById } from 'store/checkLists/namespaces/payloads';
import { declinationOfNumber } from 'utils/declination';
import { getPercents, getRoundedPercent } from 'utils/numbers';
import CallBody from './CallBody/CallBody';
import TagsBlock from './components/TagsBlock/TagsBlock';
import { useStyles } from './Call.jss';
import { CommonPropsType } from './types';
import { TagWithValue, useCalls } from './hooks/useCalls';
import GPTCallComments from '../../widgets/GPTCallComments/GPTCallComments';
import EmployeeBlock from './components/EmployeeBlock/EmployeeBlock';
import ClientBlock from './components/ClientBlock/ClientBlock';

import s from './styles.module.scss';
import { NewTagComponent } from 'shared/ui';
import useManualTaggingActions from 'widgets/ManualTagging/hooks/useManualTaggingActions';

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters TransitionProps={{ unmountOnExit: true, timeout: 0 }} elevation={0} {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {},
	borderBottom: 0,
	'&:before': {
		display: 'none',
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface CallPropsType extends CommonPropsType {
	index: number | null;
	isCheckListsLoading: boolean;
	updateCheckList(target: TUpdateCurrentCallCheckListAnswerById): void;
	checkLists: TCallCheckList[];
	currentPage?: number | undefined;
	showParams?: boolean;
}

const Call: React.FC<CallPropsType> = ({
	callInfo,
	index,
	language,
	isAuth,
	solo,
	expanded,
	accessRights,
	isCheckListsLoading,
	checkLists,
	updateCheckList,
	userInfo,
	currentPage,
	showParams = true,
	loadCheckLists,
	showOpenLink = true,
}) => {
	const classes = useStyles();

	const { methods, data } = useCalls({
		callInfo,
		expanded,
		loadCheckLists,
		language,
		userInfo,
		isAuth,
		solo,
		isLoading: isCheckListsLoading,
		showOpenLink,
		accessRights,
	});

	const {
		newMetaTags,
		tagsAndFragmentsArray,
		hasAccessToPhone,
		hasAccessToTags,
		accordion,
		accordionSummary,
		declinationArray,
		currentFragment,
		audioDivRef,
		tagsDivRef,
		paramsDivRef,
		AccordionSummary,
	} = data;

	const { setCurrentFragmentHandle, onClick, stopPropagation } = methods;

	const { handleDeleteTag } = useManualTaggingActions(callInfo?.id);

	const [clickedTagId, setClickedTagId] = useState<string | null>(null);
	const handleClickTagSettings = (id: string | null) => {
		setClickedTagId(id);
	};

	const renderTag = (tag: TagWithValue) => {
		if (solo && !tag.visible) {
			return (
				<NewTagComponent
					id={tag?.id}
					name={tag.name}
					value={tag.value ?? null}
					theme="gray"
					language={language}
					accessRights={accessRights?.manual_tagging}
					settings={(solo || expanded) && true}
					deleteTag={handleDeleteTag}
					isActiveTag={clickedTagId === tag?.id}
					handleClickTag={handleClickTagSettings}
				/>
			);
		} else if (tag.visible) {
			return (
				<NewTagComponent
					id={tag?.id}
					name={tag.name}
					value={tag.value ?? null}
					theme="blue"
					language={language}
					accessRights={accessRights?.manual_tagging}
					settings={(solo || expanded) && true}
					deleteTag={handleDeleteTag}
					handleClickTag={handleClickTagSettings}
					isActiveTag={clickedTagId === tag?.id}
				/>
			);
		}
		return null;
	};

	return (
		<div ref={accordion}>
			<Accordion id={callInfo?.id ?? index?.toString()} className={classes.accordion} expanded={expanded}>
				<div className={classes.accordionInner} ref={accordionSummary}>
					<AccordionSummary
						id="accordionSummary"
						onClick={onClick}
						className={classes.accordionSummary}
						tabIndex={-1}
					>
						<div className={classes.summaryInfo}>
							{index && (
								<div className={classes.summaryInfoIndex}>
									<div className={classes.summaryNumber}>
										<Typography className={classes.numberText}>{index}</Typography>
									</div>
								</div>
							)}

							<div className={classes.summaryInfoClient}>
								<EmployeeBlock callInfo={callInfo} classes={classes} />
								<div className={s.container} onClick={stopPropagation}>
									{newMetaTags?.operatorTags &&
										newMetaTags?.operatorTags.map((tag) => (
											<div className={s.tagContainer} key={`${tag.name}-${index}`}>
												{renderTag(tag)}
											</div>
										))}
								</div>
							</div>

							{callInfo ? (
								// TODO: попробовать убрать проверку внутри проверки
								// eslint-disable-next-line react/jsx-no-useless-fragment
								<div className={classes.summaryScore}>
									{typeof callInfo?.points === 'number' &&
										typeof callInfo?.maxPoints === 'number' && (
											<div>
												<CallScore
													scorePercent={getPercents(callInfo.points, callInfo.maxPoints)}
													score={`${getRoundedPercent(callInfo.points)} ${declinationOfNumber(
														callInfo.points,
														declinationArray,
													)}`}
												/>
											</div>
										)}
								</div>
							) : (
								<div className={classes.summaryScore}>
									<Skeleton width={85} height={20} variant="text" />
								</div>
							)}

							{callInfo ? (
								<div className={classes.summaryInfoEmployee}>
									<ClientBlock
										classes={classes}
										hasAccess={hasAccessToPhone}
										clientPhone={callInfo.clientPhone}
										duration={callInfo.duration}
									/>
									<div className={s.container} onClick={stopPropagation}>
										{newMetaTags?.clientTags &&
											newMetaTags?.clientTags.map((tag) => (
												<div className={s.tagContainer} key={`${tag.name}-${index}`}>
													{renderTag(tag)}
												</div>
											))}
									</div>
								</div>
							) : (
								<Skeleton width={85} height={20} variant="text" />
							)}
						</div>

						<div className={classes.summaryTags}>
							{hasAccessToTags && (
								<TagsBlock // @ts-ignore
									tagsAndFragmentsArray={tagsAndFragmentsArray}
									callInfo={callInfo}
									classes={classes}
									expanded={expanded}
									language={language}
									accessRights={accessRights}
									setCurrentFragmentHandle={setCurrentFragmentHandle}
									solo={solo}
									deleteTag={handleDeleteTag}
								/>
							)}
						</div>
					</AccordionSummary>
				</div>
				{/* Основная информация о звонке. */}
				<AccordionDetails className={classes.accordionDetails}>
					{callInfo && (
						<CallBody
							hasAccessToTags={!!hasAccessToTags}
							showParams={showParams}
							updateCheckList={updateCheckList}
							isAuth={isAuth}
							isCheckListsLoading={isCheckListsLoading}
							checkLists={checkLists}
							solo={solo}
							callInfo={callInfo}
							currentFragment={currentFragment}
							fragments={callInfo?.tags}
							audioDivRef={audioDivRef}
							tagsDivRef={tagsDivRef}
							paramsDivRef={paramsDivRef}
							userInfo={userInfo}
							currentPage={currentPage}
							deleteTag={handleDeleteTag}
						/>
					)}
				</AccordionDetails>
			</Accordion>
			{callInfo && !expanded && callInfo?.comments.length > 0 && (
				<GPTCallComments callId={callInfo.id} expanded={false} comments={callInfo?.comments} />
			)}
		</div>
	);
};

export default React.memo(Call);
