import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSTTLanguages, getSTTEngines, getSTTModels, OptionType, getSTTOptions } from 'store/user/user.slice';
import { UserType } from 'store/user/user.types';
import { LangType } from 'store/lang/lang.slice';
import { parserSchemaToDelete, setDefaultValues } from 'widgets/DynamicForm/utils/utils';
import { SttValuesLocal, SttValuesForRequest } from './../namespaces';
import { setDeleteSTT, sttDefault } from './../consts';
import { useValidateStt } from './../hooks/useValidateStt';
import useValuesStt from './../hooks/useValuesStt';
import { FormikProps } from '../namespaces';

const useStt = (user: UserType | null, language: LangType | string, onSubmitFunction: (values: SttValuesForRequest) => void) => {
	const dispatch = useDispatch();
	const [contentIsLoading, setContentIsLoading] = useState(true);
	const fetchSTTLanguages = async () => dispatch(getSTTLanguages());
	const fetchSTTEngines = async (language: string) => dispatch(getSTTEngines(language));
	const fetchSTTModels = async (info: { language: string; engine: string }) => dispatch(getSTTModels(info));
	const fetchSTTOptions = async (engine: string) => dispatch(getSTTOptions(engine));

	const [STTLanguagesList, setSTTLanguagesList] = useState<OptionType[]>([]);
	const [STTEnginesList, setSTTEnginesList] = useState<OptionType[]>([]);
	const [STTModelsList, setSTTModelsList] = useState<OptionType[]>([]);

	const [sttOptions, setSttOptions] = useState<null | {}>(null);
	const [convertedOptions, setConvertedOptions] = useState<Record<string, string | number>>({});
	const [defaultValuesOfUser, setDefaultValuesOfUser] = useState({ values: sttDefault });

	const { validate, validateInputNumber, validateInputNumberValue } = useValidateStt(language);
	const { returnSTTValues } = useValuesStt();

	const handleSubmit = async (values: SttValuesLocal) => {
		const convertedValues = returnSTTValues(values, convertedOptions);

		const validation = validate(convertedValues.sttOptions.sttOptionsMap);
		if (validation) {
			await onSubmitFunction(convertedValues);
		}
	};

	const setModel = async (language: string, engine: string) => {
		const models = await fetchSTTModels({ language, engine }); // @ts-ignore
		const modelsArray = models.payload;
		setSTTModelsList(modelsArray);
		let model;
		if (modelsArray) {
			if (user?.sttOptions?.sttOptionsMap?.model) {
				model = modelsArray?.find((item: OptionType) => item.value === user?.sttOptions?.sttOptionsMap?.model) || '';
			} else if (user?.sttModel) {
				model = modelsArray?.find((item: OptionType) => item.value === user?.sttModel) || '';
			}

			setContentIsLoading(true);
			return { sttModel: model };
		}
		setContentIsLoading(true);
		return { sttModel: null };
	};

	const setEngine = async (languageSTT: OptionType) => {
		const engines = await fetchSTTEngines(languageSTT.value); // @ts-ignore
		const enginesPayload = engines.payload;
		setSTTEnginesList(enginesPayload.data);
		let sttModel = '';
		let sttEngine = '';

		if (enginesPayload.data.length && user?.sttOptions?.sttEngine) {
			const engine = enginesPayload.data.find((item: OptionType) => item.value === user?.sttOptions?.sttEngine);
			sttEngine = engine;

			if (engine) {
				const res = await setModel(enginesPayload.language, engine.value);
				sttModel = res.sttModel;

				const fetchedSTTOptions = await fetchSTTOptions(engine.value);

				const splittedSTTOptions = parserSchemaToDelete(fetchedSTTOptions.payload, setDeleteSTT);
				const withDefaultValues = setDefaultValuesToSchema(splittedSTTOptions, user?.sttOptions?.sttOptionsMap);
				setSttOptions(withDefaultValues);
			} else {
				setContentIsLoading(true);
			}
		}

		return { sttEngine, sttModel };
	};

	const setLanguage = async () => {
		setContentIsLoading(true);
		const languages = await fetchSTTLanguages(); // @ts-ignore
		const languagesArray = languages.payload;
		setSTTLanguagesList(languagesArray);
		let sttLanguage;
		let engine = '';
		let model = '';

		if (user?.sttOptions?.sttOptionsMap?.language) {
			sttLanguage = languagesArray?.find((item: OptionType) => item.value === user?.sttOptions?.sttOptionsMap?.language);
		} else if (user?.sttLanguage) {
			sttLanguage = languagesArray?.find((item: OptionType) => item.value === user?.sttLanguage);
		}

		if (sttLanguage) {
			const { sttModel, sttEngine } = await setEngine(sttLanguage);
			engine = sttEngine;
			model = sttModel;
		} else {
			setContentIsLoading(true);
		}

		return {
			sttLanguage,
			sttEngine: engine,
			sttModel: model,
		};
	};

	const setDefaultValuesToSchema = (schema: any, defaultValues: Record<string, string | number> | undefined) => {
		if (Object.keys(schema).length && defaultValues) return setDefaultValues(schema, defaultValues);
	};

	const handleChangeOptions = (data: any) => {
		setConvertedOptions(data);
	};

	const resetSchema = () => {
		setSttOptions(null);
	};

	const handleLanguage = async (
		e: any,
		values: SttValuesLocal,
		setFieldValue: FormikProps['setFieldValue'],
		setErrors: FormikProps['setErrors'],
	) => {
		setFieldValue('sttLanguage', e);
		if (e.value !== values.sttLanguage?.value) {
			setSTTEnginesList([]);
			setSTTModelsList([]);

			setFieldValue('sttEngine', '');
			setFieldValue('sttModel', '');

			const enginges = await fetchSTTEngines(e.value);
			setSTTEnginesList(enginges?.payload?.data);

			resetSchema();

			setErrors({
				sttEngine: {
					label: 'is required',
					value: 'is required',
				},
				sttModel: {
					label: 'is required',
					value: 'is required',
				},
			});
		}
	};

	const handleEngine = async (
		e: any,
		values: SttValuesLocal,
		setFieldValue: FormikProps['setFieldValue'],
		setErrors: FormikProps['setErrors'],
	) => {
		setFieldValue('sttEngine', e);
		if (e.value !== values.sttEngine?.value) {
			setSTTModelsList([]);

			setFieldValue('sttModel', '');

			const models = await fetchSTTModels({
				language: values.sttLanguage?.value as string,
				engine: e.value,
			});
			setSTTModelsList(models?.payload);

			// запрашиваем динамические поля json schema
			const fetchedSTTOptions = await fetchSTTOptions(e.value);
			const splittedSTTOptions = parserSchemaToDelete(fetchedSTTOptions?.payload, setDeleteSTT);

			if (values.sttLanguage?.value === user?.sttLanguage && e.value === user?.sttEngine) {
				const withDefaultValues = setDefaultValuesToSchema(splittedSTTOptions, user?.sttOptions?.sttOptionsMap);
				setSttOptions(withDefaultValues);
			} else {
				setSttOptions(splittedSTTOptions);
			}

			setErrors({
				sttModel: {
					label: 'is required',
					value: 'is required',
				},
			});
		}
	};

	const handleModel = (e: any, setFieldValue: FormikProps['setFieldValue'], setErrors: FormikProps['setErrors']) => {
		setFieldValue('sttModel', e);
		setErrors({});
	};

	const getUserSTTSettings = async () => {
		const { sttLanguage, sttModel, sttEngine } = await setLanguage();
		setContentIsLoading(false);
		setDefaultValuesOfUser({
			values: {
				sttLanguage,
				// @ts-ignore
				sttEngine,
				// @ts-ignore
				sttModel,
				sttEconomize: !!user?.sttOptions?.sttEconomize,
			},
		});
	};

  return {
    methods: {
      getUserSTTSettings,
      handleSubmit,
      handleLanguage,
      handleEngine,
      handleModel,
      handleChangeOptions,
      validateInputNumber,
      validateInputNumberValue
    },
    data: {
      contentIsLoading,
      STTLanguagesList,
      STTEnginesList,
      STTModelsList,
      sttOptions,
      convertedOptions,
      defaultValuesOfUser,
    }
  }
}

export default useStt;