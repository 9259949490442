import queryString from 'query-string';

export const getTokens = () => {
	// Извлечение токена из sessionStorage
	const sessionTokenData = JSON.parse(sessionStorage.getItem('sessionToken') || '{}');
	const sessionStorageToken = sessionTokenData.sessionToken;
	// Извлечение токена из localStorage
	const localTokenData = JSON.parse(localStorage.getItem('localToken') || '{}');
	const localStorageToken = localTokenData.localToken;
	// Определение итогового токена
	const token = sessionStorageToken ? sessionStorageToken : localStorageToken;

	const { adminToken } = JSON.parse(localStorage.getItem('adminToken') || '{}');

	return { token, adminToken };
};

const getJWTToken = () => {
	const searchUrlObj = queryString.parse(window.location.search);
	return searchUrlObj.token;
};

export const getTokenWithAuthCheck = () => {
	const { token } = getTokens(); // токен, полученный при авторизации
	const jwtToken = getJWTToken(); // токен из ссылки
	let userToken = token;

	// если пользователь незалогинен и у него нет токена авторизации,
	// но есть jwt токен из ссылки, используем его
	if (!token && jwtToken && typeof jwtToken === 'string') {
		userToken = jwtToken;
	}

	return userToken;
};
