import { FC } from 'react';
import Form, { IChangeEvent } from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';
import { UiSchema, RegistryWidgetsType, WidgetProps } from '@rjsf/utils';
import { CustomCheckboxWidget, CustomSelectWidget, CustomTextWidget } from './components';

// динамическая форма из JSON schema

interface DynamicFormProps {
	schema: any;
	hideLegend?: boolean; // скрывать легенду формы
	hideSubmit?: boolean; // скрывать кнопку submit
	styles?: Record<string, string>;
	onChange: (data: any, id: string | undefined) => void;
	validateInputNumber?: (label: string, fromTo: 'min' | 'max') => number | undefined;
	validateInputNumberValue?: (value: string) => boolean;
}

const DynamicForm: FC<DynamicFormProps> = ({
	schema,
	hideLegend = true,
	hideSubmit = true,
	styles,
	onChange,
	validateInputNumber,
	validateInputNumberValue,
}) => {
	const widgets: RegistryWidgetsType = {
		CheckboxWidget: CustomCheckboxWidget,
		TextWidget: (props: WidgetProps) => CustomTextWidget(props, validateInputNumber, validateInputNumberValue),
		SelectWidget: CustomSelectWidget,
	};

	const uiSchema: UiSchema = {
		// скрываем кнопку submit
		'ui:submitButtonOptions': { norender: hideSubmit },
		// скрываем легенду таблицы
		'ui:options': { label: !hideLegend },
		// задаем класс
		'ui:classNames': 'customForm',
		//  скрываем label у всего (чекбоксы и тп)
		'ui:globalOptions': { label: false },
	};

	return (
		<div style={{ ...styles }}>
			<Form
				uiSchema={uiSchema}
				schema={schema}
				widgets={widgets}
				validator={validator}
				onChange={(data: IChangeEvent<any, any, any>, id?: string | undefined) => {
					onChange(data.formData, id);
				}}
				onSubmit={() => console.log('submitted')}
				onError={() => console.log('errors')}
			/>
		</div>
	);
};

export default DynamicForm;
