import { CriteriasType } from 'store/search/search.types';

export interface NotifyRule {
	id: string;
	owner: string;
	title: string;
	enabled: boolean;
	cloneParent: string;
	direction: string;
}

export type NotifyRules = NotifyRule[];

export interface ComplexValue {
	name: string;
	value?: string;
}

interface FilterItem {
	key: string;
	values: string[];
	complexValues?: ComplexValue[];
	logic: string;
}

interface GlobalFilter {
	title: string;
	items: CriteriasType[];
}

export type amoFields = { [key: string]: any };

export interface NotifyRuleDetailed {
	id?: string;
	direction?: 'Telegram' | 'Email' | 'API' | 'AmoCRM' | 'Bitrix24';
	owner: string;
	title: string;
	enabled: boolean;
	cloneParent: string;
	activeTgChats: string[];
	tgBody: string;
	tgAudio: boolean;
	emailDst: string;
	emailSubject: string;
	emailBody: string;
	apiUrl: string;
	apiResend: boolean;
	allowOwerwriteFields: boolean;
	apiHeaders: string;
	apiBody: string;
	apiMethod: string;
	amoNotifyEnabled: boolean;
	amoBody: string;
	amoLeadFields: amoFields;
	amoContactFields: amoFields;
	globalFilter: GlobalFilter;
	bitrixContactFields: amoFields;
	bitrixLeadFields: amoFields;
	bitrixDealFields: amoFields;
	bitrixBody: string;
	bitrixNotificationDirection: string;
}

type NotifyRuleAction = 'clone'; // Заглушка

export interface NotifyRuleActionRequest {
	action: NotifyRuleAction;
	start_date: string;
	end_date: string;
}

export interface NotifyRuleVariablesResponse {
	call_vars: Record<string, unknown>;
	descriptions: Record<string, string>;
}

export enum AmoFillableCustomFields {
	CONTACTS = 'contacts',
	LEADS = 'leads',
	DEALS = 'deals',
}

export enum BitrixFillableCustomFields {
	CONTACT = 'contact',
	LEAD = 'lead',
	DEAL = 'deal',
}

export interface AmoFillableCustomFieldsType {
	entityType: AmoFillableCustomFields;
	id: string;
	name: string;
	options: optionsForSelects[];
	type: string;
}

export interface BitrixFillableCustomFieldsType {
	entityType: BitrixFillableCustomFields;
	id: string;
	name: string;
	options: optionsForSelects[];
	type: string;
}

export interface optionsForSelects {
	label: string;
	value: any;
}

export interface optionsForSelectsWithType extends optionsForSelects {
	type?: string;
	options?: optionsForSelects[] | null;
}
