import React from 'react';

export const Ellipsis = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M2.75 7.98438C2.75 8.09928 2.77263 8.21306 2.81661 8.31922C2.86058 8.42538 2.92503 8.52184 3.00628 8.60309C3.08753 8.68434 3.18399 8.7488 3.29015 8.79277C3.39631 8.83674 3.51009 8.85938 3.625 8.85938C3.73991 8.85938 3.85369 8.83674 3.95985 8.79277C4.06601 8.7488 4.16247 8.68434 4.24372 8.60309C4.32497 8.52184 4.38942 8.42538 4.43339 8.31922C4.47737 8.21306 4.5 8.09928 4.5 7.98438C4.5 7.86947 4.47737 7.75569 4.43339 7.64953C4.38942 7.54337 4.32497 7.44691 4.24372 7.36566C4.16247 7.28441 4.06601 7.21995 3.95985 7.17598C3.85369 7.13201 3.73991 7.10938 3.625 7.10938C3.51009 7.10938 3.39631 7.13201 3.29015 7.17598C3.18399 7.21995 3.08753 7.28441 3.00628 7.36566C2.92503 7.44691 2.86058 7.54337 2.81661 7.64953C2.77263 7.75569 2.75 7.86947 2.75 7.98438ZM7.125 7.98438C7.125 8.21644 7.21719 8.439 7.38128 8.60309C7.54538 8.76719 7.76794 8.85938 8 8.85938C8.23206 8.85938 8.45462 8.76719 8.61872 8.60309C8.78281 8.439 8.875 8.21644 8.875 7.98438C8.875 7.75231 8.78281 7.52975 8.61872 7.36566C8.45462 7.20156 8.23206 7.10938 8 7.10938C7.76794 7.10938 7.54538 7.20156 7.38128 7.36566C7.21719 7.52975 7.125 7.75231 7.125 7.98438ZM11.5 7.98438C11.5 8.21644 11.5922 8.439 11.7563 8.60309C11.9204 8.76719 12.1429 8.85938 12.375 8.85938C12.6071 8.85938 12.8296 8.76719 12.9937 8.60309C13.1578 8.439 13.25 8.21644 13.25 7.98438C13.25 7.75231 13.1578 7.52975 12.9937 7.36566C12.8296 7.20156 12.6071 7.10938 12.375 7.10938C12.1429 7.10938 11.9204 7.20156 11.7563 7.36566C11.5922 7.52975 11.5 7.75231 11.5 7.98438Z"
			fill="#738094"
		/>
	</svg>
);
