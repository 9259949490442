export const colors = [
	'#B37FEB',
	'#95DE64',
	'#FFA940',
	'#FF4D4F',
	'#597EF7',
	'#FF85C0',
	'#36CFC9',
	'#F759AB',
	'#FF9C6E',
];
