import React, { FC, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from 'hooks/redux';
import { gptCommentAPI } from 'services/gptCommentService';
import { useActionsFunctions } from 'pages/Calls/components/CallsHeader/components/CallHeadersSettings/hooks/useActionsFunctions';
import { BlockBox, CustomCheckbox } from 'components/common';
import { IconButton, UniversalInput } from 'shared/ui';
import { translate } from 'localizations';
import { TextArea } from 'components/shared/Forms/TextArea/TextArea';
import SendIcon from 'shared/ui/icons/general/SendIcon/SendIcon';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'pages/Settings/types';

import s from './styles.module.scss';

interface GPTCommentFormProps {
	callId: string;
	handleCloseForm: () => void;
}
const GPTCommentForm: FC<GPTCommentFormProps> = ({ callId, handleCloseForm }) => {
	const { lang } = useParams<RouteParams>();

	const [postGptComment] = gptCommentAPI.usePostGptCommentMutation();

	const [commentTitle, setCommentTitle] = useState('');
	const [commentText, setCommentText] = useState('');
	const [isPrivate, setIsPrivate] = useState(false);
	const [showTitle, setShowTitle] = useState(false);

	const { updateCall } = useActionsFunctions(undefined, callId);

	const handleSubmitComment = async () => {
		const scrollPosition = window.scrollY;
		if (commentText.length > 0) {
			await postGptComment({
				call: callId,
				title: commentTitle,
				message: commentText,
				isPrivate,
			});
			await updateCall(callId);
			window.scrollTo(0, scrollPosition);
		}
	};

	return (
		<BlockBox width="auto" height="auto" padding="16px" id={`post_comment_${callId}`}>
			<div className={s.header}>
				<CustomCheckbox
					rootClassname={s.checkbox}
					label={translate('hiddenComment', lang)}
					checked={isPrivate}
					onChange={(e) => setIsPrivate(e.target.checked)}
				/>
				<button className={s.addTitleButton} onClick={() => setShowTitle(!showTitle)}>
					{translate(!showTitle ? 'addTitle' : 'hideTitle', lang)}
				</button>
				<IconButton
					onClick={handleCloseForm}
					width="15px"
					height="15px"
					className={s.closeButton}
					backgroundColor="transparent"
					icon={<CloseIcon style={{ color: '#a3aebe', width: '15px', height: '15px' }} />}
					buttonStyles={{ border: 'none', padding: '0' }}
				/>
			</div>
			{showTitle && (
				<UniversalInput
					inputStyle="outlined"
					classNameWrapper={s.titleWrapper}
					classNameInput={s.title}
					placeholder={translate('enterTitle', lang)}
					value={commentTitle}
					handleChange={(e) => setCommentTitle(e.target.value)}
					color="#738094"
				/>
			)}
			<div className={s.body}>
				<TextArea
					classNameRoot={s.textareaWrapper}
					className={s.textarea}
					placeholder={translate('enterCommentText', lang)}
					value={commentText}
					onChange={(e: React.ChangeEvent<any>) => setCommentText(e.target.value)}
					style={{ resize: 'vertical' }}
				/>
				<IconButton
					disabled={!commentText}
					onClick={handleSubmitComment}
					icon={<SendIcon />}
					buttonStyles={{
						border: 'none',
						padding: '0',
					}}
					backgroundColor="#9254DE"
				/>
			</div>
		</BlockBox>
	);
};

export default GPTCommentForm;
