import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { OptionType } from 'components/common/Selects/MultiValueSelect/multiValuesSelect';
import { isDevStand } from 'utils/url';
import { getUserToken } from 'store/user/user.slice';
import { callsActions } from 'store/calls';
import { useAppSelector } from './redux';
import useResetChildUser from './useResetChildUser';

const useUserChange = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { resetChildUser } = useResetChildUser();
	const { loggedUser } = useAppSelector((state) => state.user);
	const { adminToken } = JSON.parse(localStorage.getItem('adminToken') || '{}');

	const getChildUser = async (value: string) => {
		const tokenData = await dispatch(getUserToken(value));

		// @ts-ignore
		return tokenData?.payload?.access_token;
	};

	const getOriginalUser = () => {
		sessionStorage.setItem('sessionToken', JSON.stringify({ sessionToken: adminToken }));
	};

	const handleUserChange = async (event: OptionType, customPath?: string) => {
		if (loggedUser && event) {
			const pathArray = window?.location?.pathname.split('/');
			const path = isDevStand() ? pathArray[4] : pathArray[3];
			const newCustomPath = typeof customPath === 'string' ? customPath : '';
			let id: string;
			let accessToken;

			if (loggedUser.id !== event.value) {
				accessToken = await getChildUser(event.value);
				id = event.value;
			} else {
				getOriginalUser();
				id = loggedUser.id;
			}

			history?.push(`/${loggedUser?.language}/${id}/${newCustomPath || path}`);
			await resetChildUser(accessToken);
		}

		dispatch(callsActions.setSearchCallsHash(undefined));
		dispatch(callsActions.setFilterCallsHash(undefined));
	};

	return { handleUserChange };
};

export default useUserChange;
