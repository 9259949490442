import React, { FC } from 'react';
import { PersonSvg } from 'components/Icons/PersonSvg';
import variables from 'styles/variables.scss';
import { EyeClosedIcon } from 'shared/ui';
import { convertDateFormString } from 'utils/convertDateAndTime/dateTimeFormating';
import { GPTCallComment as GPTComment } from 'store/calls/calls.types';

import s from './styles.module.scss';

type CommentHeaderProps = Pick<GPTComment, 'editorName' | 'creatorName' | 'editTime' | 'addTime' | 'isPrivate'>;
const CommentHeader: FC<CommentHeaderProps> = ({
	editorName,
	creatorName,
	editTime,
	addTime,
	isPrivate,
}) => (
	<div className={s.head}>
		{editorName || creatorName ? (
			<div className={s.author}>
				<PersonSvg fill="#738094" />
				<span>{editorName || creatorName}</span>
			</div>
		) : null}
		{editTime || addTime ? (
			<div className={s.time}>
				{convertDateFormString(editTime || addTime || '')}
			</div>
		) : null}
		{isPrivate && <EyeClosedIcon pathFill={variables.gray_7} />}
	</div>
);

export default CommentHeader;
