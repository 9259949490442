export const getTime = (seconds: number) => {
	const sign = Math.sign(seconds);

	seconds = Math.abs(seconds);
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = seconds % 60;

	const convertHours = hours < 10 ? `0${hours}` : hours;
	const convertMinutes = minutes < 10 ? `0${minutes}` : minutes;
	const convertSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

	const result =
		sign === -1
			? `-${convertHours}:${convertMinutes}:${convertSeconds}`
			: `${convertHours}:${convertMinutes}:${convertSeconds}`;

	return result;
};
