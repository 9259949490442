import React from 'react';

function useOnClickOutside<T extends HTMLElement>(
	ref: React.RefObject<T>,
	handler: (event: MouseEvent | TouchEvent) => void,
): void {
	React.useEffect(() => {
		const listener = (event: MouseEvent | TouchEvent) => {
			const el = ref?.current;
			if (!el || el.contains(event.target as Node)) {
				return;
			}
			handler(event);
		};
		document.addEventListener('mousedown', listener);
		document.addEventListener('touchstart', listener);
		return (): void => {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
		};
	}, [ref, handler]);
}

export default useOnClickOutside;
