import { dateFormattingOptions } from 'pages/Reports/ReportsFunctions/TableFunctions';
import { convertDate } from './convertDate';

/**
 * Приведение даты с бэка к виду - DD.MM.YYYY, HH:MM:SS
 */
export function datetimeToString(datetimeString: string): string {
	return new Date(datetimeString).toLocaleString();
}

/* Приведение даты с бэка, парсинг строки типа " DD-MM-YYYY HH:MM:SS" */
export function dateTimeToStringForTable(datetimeString: string): Date {
	const [dateValues, timeValues] = datetimeString.split(' ');
	const [day, month, year] = dateValues.split('-');
	const [hours, minutes, seconds] = timeValues.split(':');

	return new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
}

export const dateTimeToStringForTableWithLocale = (datetimeString: string) => {
	const [dateValues, timeValues] = datetimeString.split(' ');
	const [day, month, year] = dateValues.split('-');
	const [hours, minutes, seconds] = timeValues.split(':');

	return new Date(+year, +month - 1, +day, +hours, +minutes, +seconds).toLocaleDateString(
		'ru-RU',
		dateFormattingOptions,
	);
};

/**
 * Приведение даты с бэка к виду - DD.MM.YYYY HH:mm:ss
 */

export const convertDateFormString = (date: Date | string, withSeconds?: boolean): string | null => {
	if (!date) {
		return null;
	}

	const parsedDate = new Date(date);
	if (Number.isNaN(parsedDate.getTime())) {
		return null;
	}

	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
	};

	if (withSeconds) {
		options.second = '2-digit';
	}

	return new Intl.DateTimeFormat('ru-RU', options).format(parsedDate);
};

/**
 * Приведение даты с бэка к виду - DD.MM.YYYY
 */

export const convertOnlyDateFormString = (date: Date | string): string | null => {
	if (!date) {
		return null;
	}

	const parsedDate = new Date(date);
	if (Number.isNaN(parsedDate.getTime())) {
		return null;
	}

	return new Intl.DateTimeFormat('ru-RU', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	}).format(parsedDate);
};

/**
 * Приведение секунд к виду - HH:mm:ss
 */

export const convertSecondsToFormat = (seconds: number) => new Date(seconds * 1000).toISOString().slice(11, 19);

/**
 * Приведение секунд к минутам с округлением
 */
export const convertSecondsToMin = (seconds: number) => Math.round(seconds / 60);

/**
 * конвертирует время для показа
 */
export const timeConverter = (s: number, hoursIsDisplay: boolean) => {
	const pad = (n: number) => `00${n}`.slice(-2);
	const ms = s % 1000;
	s = (s - ms) / 1000;
	const secs = s % 60;
	s = (s - secs) / 60;
	const mins = s % 60;
	const hrs = (s - mins) / 60;
	if (hoursIsDisplay || s >= 60) {
		return `${pad(hrs)}:${pad(mins)}:${pad(secs)}`;
	}
	return `${pad(mins)}:${pad(secs)}`;
};

/**
 * конвертирует минуты в секунды
 */
export const convertToSeconds = (minutes: string) => Number(minutes) * 60;

/**
 * преобразует диапазон дат и строку времени в форматированные даты и время в секундах.
 */
export const convertDateAndTime = (date: [Date, Date] | null, minutes: string, isInfinitely: boolean) => {
	let datetime_from = null;
	let datetime_to = null;
	let time_nominal = 0;
	if (date && !isInfinitely) {
		datetime_from = convertDate(date[0]);
		datetime_to = convertDate(date[1]);
	}
	if (minutes) {
		if (minutes) {
			time_nominal = convertToSeconds(minutes);
		}
	}
	return {
		datetime_from,
		datetime_to,
		time_nominal,
	};
};
