export class EnvsManager {
	/**
	 * Only for local development
	 */
	static get isDevelopment(): boolean {
		return process.env.NODE_ENV === 'development';
	}

	/**
	 * Only for development stand
	 */
	static get isDevStand(): boolean {
		return window.location.host.includes('stand') || window.location.host.includes('192.168.');
	}

	static get isFeatureReports(): boolean {
		return window.location.host.includes('reports.dev.imot.ru');
	}

	/**
	 * For local development or dev stand
	 */
	static get isDev(): boolean {
		return EnvsManager.isDevelopment || EnvsManager.isDevStand || EnvsManager.isFeatureReports;

	}

	/**
	 * Only for production stand
	 */
	static get isProdStand(): boolean {
		return !EnvsManager.isDevStand && !EnvsManager.isDevStand;
	}
}
