import { useAppDispatch } from 'hooks/redux';
import { getCallInfo } from 'store/calls/actions';
import { callsActions } from 'store/calls';
import { getCallStt } from 'store/calls/calls.slice';

const useCallData = () => {
	const dispatch = useAppDispatch();

	const getCallDataById = async (id: string, token?: string) => {
		const callInfoData = await dispatch(getCallInfo({ id, token })); // @ts-ignore
		const callInfo = callInfoData.payload;

		if (callInfo) {
			dispatch(
				// @ts-ignore
				callsActions.setCurrentCall({
					id,
					info: callInfo,
					stt: null,
					token: null,
				}),
			);
			dispatch(callsActions.setInfo(callInfo));
			const callSttData = await dispatch(getCallStt({ id, token })); // @ts-ignore
			const callStt = callSttData.payload;
			if (callStt) {
				await dispatch(callsActions.setStt(callStt));
			} else {
				dispatch(callsActions.setCurrentCall(false));
			}
		} else {
			dispatch(callsActions.setCurrentCall(false));
		}
	}

	return {
		getCallDataById,
	}
};

export default useCallData;
