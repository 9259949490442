import { FC, useState } from 'react';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks/redux';
import { translate } from 'localizations';
import { createTemplate, updateTemplate } from 'store/search/search.slice';
import { TemplateType } from 'store/search/search.types';
import { createSnackbarOptions } from 'components/common/Snackbar/Snackbar';
import { ModalWindow, UniversalInput } from 'shared/ui';

type CreateNameTemplateMWPropsType = {
	isOpen: boolean;
	handleClose: () => void;
	method: 'post' | 'put' | null;
	currentTemplate: TemplateType | null;
};

const CreateNameTemplateModalWindow: FC<CreateNameTemplateMWPropsType> = ({
	isOpen,
	handleClose,
	method,
	currentTemplate,
}) => {
	const { language } = useAppSelector((state) => state.lang);
	const activeCriterias = useAppSelector((state) => state.search.calls.activeCriterias);
	const dispatch = useDispatch();

	const [disabled, setDisabled] = useState<boolean>(true);
	const [loading, setLoading] = useState<boolean>(false);

	const validate = (values: { name: string }) => {
		if (values.name.length < 1) {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
	};

	const { enqueueSnackbar } = useSnackbar();
	const formik = useFormik({
		initialValues: {
			name: '',
		},
		validate,
		onSubmit: async (values) => {
			setLoading(true);
			if (method === 'put' && currentTemplate) {
				const data = await dispatch(
					updateTemplate({
						...currentTemplate,
						title: values.name,
					}),
				); // @ts-ignore
				if (!data.payload) {
					enqueueSnackbar(
						null,
						createSnackbarOptions({
							time: 2000,
							text: translate('alreadyTemplateWithThisName', language),
							type: 'error',
						}),
					);
				}
				handleClose();
				values.name = '';
			}
			if (method === 'post') {
				const data = await dispatch(
					createTemplate({
						title: values.name,
						items: [...activeCriterias],
					}),
				); // @ts-ignore
				if (!data.payload) {
					enqueueSnackbar(
						null,
						createSnackbarOptions({
							time: 2000,
							text: translate('alreadyTemplateWithThisName', language),
							type: 'error',
						}),
					);
				}
				handleClose();
				values.name = '';
			}
			setLoading(false);
			setDisabled(true);
		},
	});

	return (
		<div>
			<ModalWindow isMWOpen={isOpen} handleMWClose={handleClose} text={translate('enterTemplateName', language)}>
				<form onSubmit={formik.handleSubmit}>
					<div style={{ margin: '15px 0' }}>
						<UniversalInput
							inputStyle="outlined"
							name="name"
							bcColor="#EEF2F6"
							placeholder={translate('title', language)}
							autoComplete="off"
							handleChange={formik.handleChange}
							value={formik.values.name}
						/>
					</div>

					{/* Кнопоньки */}
					<LoadingButton
						disabled={disabled}
						loading={loading}
						style={{ marginRight: '15px' }}
						variant="contained"
						type="submit"
						color="primary"
					>
						{translate('sendButton', language)}
					</LoadingButton>
				</form>
			</ModalWindow>
		</div>
	);
};

export default CreateNameTemplateModalWindow;
