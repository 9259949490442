import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
	chartListItemWrap: {
		marginTop: '16px',
		display: '-webkit-box',
		'-webkit-line-clamp': 6,
		'-webkit-box-orient': 'vertical',
		overflowY: 'hidden',
		textAlign: 'center',
	},
	chartList: {
		marginBottom: '0',
		textAlign: 'center',
	},
	chartListItem: {
		display: 'inline-flex',
		alignItems: 'center',
		marginRight: '10px',
		fontSize: '11px',
	},
	chartTooltip: {
		maxHeight: '400px',
		height: '100%',
		background: '#fff',
		padding: '16px',
		borderRadius: '10px',
		boxShadow: '0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)',
		width: '300px',
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			height: '8px',
			width: '9px',
			backgroundColor: '#fff',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: '#A3AEBE',
			background: '#A3AEBE',
			borderRadius: '14px',
		},
	},
	legendCircle: {
		borderRadius: '50%',
		width: '6px',
		height: '6px',
		marginRight: '5px',
	},
	tooltip: {
		marginBottom: '8px',
	},
	tooltipTable: {
		height: '100%',
	},
	tableTd: {
		paddingLeft: '10px',
		whiteSpace: 'nowrap',
	},
	lineChart: {
		fontSize: '12px',
	},
	legend: {
		bottom: '0px',
	},
});
