import React, { FC, KeyboardEvent, ChangeEvent } from 'react';
import classNames from 'classnames';
import { IconButton } from '@mui/material';
import { CheckSvg } from 'components/Icons/CheckIcon';
import { CloseSvg } from 'components/Icons/CloseIcon';
import styles from './styles.module.scss';

type EditableInputProps = {
	value: string;
	onChange?: (newValue: string) => void;
	onConfirm?: (newValue: string) => void;
	onCancel?: () => void;
	type?: 'input' | 'textarea';
	size?: 'small' | 'medium';
	textareaClassName?: string;
	withActions?: boolean;
	isDisabled?: boolean;
	classNameInput?: string;
	placeholder?: string;
};

const EditableInput: FC<EditableInputProps> = ({
	value,
	onChange,
	onConfirm,
	onCancel,
	textareaClassName,
	size = 'medium',
	type = 'input',
	withActions = true,
	isDisabled = false,
	classNameInput,
	placeholder,
}) => {
	const [currentValue, setCurrentValue] = React.useState(value);

	const handleKeyDown = async (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (event.key === 'Enter') {
			event.stopPropagation();
			if (onConfirm) {
				await onConfirm(currentValue);
			}
		}
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setCurrentValue(event.target.value);
		if (onChange) onChange(event.target.value);
	};

	return (
		<div className={styles.root}>
			{type === 'input' ? (
				<input
					placeholder={placeholder}
					className={classNames(styles.input, classNameInput)}
					value={currentValue}
					onChange={handleChange}
					onKeyDown={handleKeyDown}
					onClick={(event): void => {
						event.stopPropagation();
					}}
				/>
			) : (
				<textarea
					placeholder={placeholder}
					className={classNames(styles.input, textareaClassName)}
					value={currentValue}
					onChange={handleChange}
					onKeyDown={handleKeyDown}
					onClick={(event): void => {
						event.stopPropagation();
					}}
				/>
			)}

			{withActions && (
				<>
					<IconButton
						className={styles.checkButton}
						disabled={isDisabled}
						size={size}
						onClick={async (e) => {
							e.stopPropagation();
							if (onConfirm) onConfirm(currentValue);
						}}
					>
						<CheckSvg />
					</IconButton>
					<IconButton size={size} onClick={(e) => {
						e.stopPropagation();
						if (onCancel) onCancel();
					}}>
						<CloseSvg />
					</IconButton>
				</>
			)}
		</div>
	);
};

export default EditableInput;
