// универсально можно удалять поля из json schema
export const parserSchemaToDelete = (schema: any, valuesToDelete: Set<string>) => {
	schema.required = schema.required?.filter((req: string) => !valuesToDelete.has(req));

	valuesToDelete.forEach((prop) => {
		if (!(prop in schema.properties)) return;
		if ('allOf' in schema.properties[prop]) {
			schema.properties[prop].allOf.forEach((propItem: any) => {
				delete schema.definitions[propItem.$ref.split('/').pop()];
			});
		} else if ('$ref' in schema.properties[prop]) {
			delete schema.definitions[schema.properties[prop].$ref.split('/').pop()];
		}
		delete schema.properties[prop];
	});
	if ('definitions' in schema && !Object.keys(schema.definitions).length) delete schema.definitions;
	if (!schema.required?.length) delete schema.required;
	return schema;
};

// задаем в схеме первоначальные значения
export const setDefaultValues = (schema: any, defaultValues: Record<string, string | number>) => {
	Object.keys(defaultValues).forEach((value) => {
		if (value in schema.properties) {
			schema.properties[value].default = defaultValues[value];
		}
	});
	return schema;
};
