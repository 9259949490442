import { FC, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import ContainedSelect from 'components/common/Selects/ContainedSelect/ContainedSelect';
import { optionsForSelects } from 'pages/Notifications/types/NotificationsTypes';

import cls from './styles.module.scss';

interface ContainedSelectWithHeaderProps {
	title: string;
	options?: optionsForSelects[];
	initialValue?: any;
	onChange?: (value: any) => void;
	disabled?: boolean;
}

const ContainedSelectWithHeader: FC<ContainedSelectWithHeaderProps> = ({
	title,
	options = [],
	initialValue,
	onChange,
	disabled,
}) => {
	const [selectedOption, setSelectedOption] = useState<any>(null);

	useEffect(() => {
		if (initialValue) {
			setSelectedOption(options.find((option) => option.value === initialValue));
		}

		if (!initialValue && options.length > 0) {
			setSelectedOption(options[0]);
			onChange?.(options[0]);
		}
	}, [initialValue, options]);

	return (
		<div className={cls.root}>
			<Typography className={cls.title}>{title}</Typography>

			<ContainedSelect
				width="100%"
				onSelectChange={(selected) => {
					setSelectedOption(selected);
					onChange?.(selected);
				}}
				value={selectedOption}
				options={options}
				disabled={disabled}
			/>
		</div>
	);
};

export default ContainedSelectWithHeader;
