const LoginSvg = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M13.0909 11.4227C12.7593 10.6372 12.2781 9.92377 11.6741 9.32207C11.0719 8.71863 10.3586 8.23751 9.57348 7.90527C9.56645 7.90176 9.55942 7.9 9.55239 7.89648C10.6475 7.10547 11.3594 5.81699 11.3594 4.36328C11.3594 1.95508 9.40825 0.00390625 7.00004 0.00390625C4.59184 0.00390625 2.64067 1.95508 2.64067 4.36328C2.64067 5.81699 3.35258 7.10547 4.4477 7.89824C4.44067 7.90176 4.43364 7.90352 4.42661 7.90703C3.63911 8.23926 2.93247 8.71562 2.32602 9.32383C1.72258 9.926 1.24146 10.6393 0.909224 11.4244C0.582837 12.193 0.406809 13.0171 0.390669 13.852C0.3902 13.8707 0.39349 13.8894 0.400347 13.9068C0.407204 13.9243 0.417488 13.9402 0.430593 13.9537C0.443698 13.9671 0.45936 13.9778 0.476654 13.9851C0.493949 13.9923 0.512527 13.9961 0.531294 13.9961H1.58598C1.66333 13.9961 1.72485 13.9346 1.72661 13.859C1.76176 12.502 2.30668 11.2311 3.26997 10.2678C4.26665 9.27109 5.59028 8.72266 7.00004 8.72266C8.40981 8.72266 9.73344 9.27109 10.7301 10.2678C11.6934 11.2311 12.2383 12.502 12.2735 13.859C12.2752 13.9363 12.3368 13.9961 12.4141 13.9961H13.4688C13.4876 13.9961 13.5061 13.9923 13.5234 13.9851C13.5407 13.9778 13.5564 13.9671 13.5695 13.9537C13.5826 13.9402 13.5929 13.9243 13.5997 13.9068C13.6066 13.8894 13.6099 13.8707 13.6094 13.852C13.5918 13.0117 13.4178 12.1943 13.0909 11.4227ZM7.00004 7.38672C6.19321 7.38672 5.43383 7.07207 4.86254 6.50078C4.29126 5.92949 3.97661 5.17012 3.97661 4.36328C3.97661 3.55645 4.29126 2.79707 4.86254 2.22578C5.43383 1.65449 6.19321 1.33984 7.00004 1.33984C7.80688 1.33984 8.56626 1.65449 9.13754 2.22578C9.70883 2.79707 10.0235 3.55645 10.0235 4.36328C10.0235 5.17012 9.70883 5.92949 9.13754 6.50078C8.56626 7.07207 7.80688 7.38672 7.00004 7.38672Z"
			fill="#2F3747"
		/>
	</svg>
);

export default LoginSvg;
