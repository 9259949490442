/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import { XAxis, YAxis, Line, LineChart, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import { useReportTranslations } from 'pages/Reports/hooks/useReportTranslations';
import { CheckTypeDataInChart } from 'pages/Reports/types/ReportsTypes';
import { convertValue } from 'pages/Reports/ReportsFunctions/ConvertValuesInCeil';
import { colors } from '../const';
import { useStyles } from './ChartArea.jss';

type ChartAreaPropsType = {
	data: any[];
	width: number;
	height: number;
	dataKey: string[];
	checkChart: CheckTypeDataInChart;
	dataChartLegend: string[];
	dataLegendLabelX: string;
};

const ChartArea: FC<ChartAreaPropsType> = React.memo(
	({ data, width, height, dataKey, checkChart, dataChartLegend, dataLegendLabelX }) => {
		const classes = useStyles();
		const { translatedText } = useReportTranslations();

		const CustomizedTooltip = ({ active, payload, label }: any): JSX.Element | null => {
			if (active && payload && payload.length) {
				return (
					<div className={classes.chartTooltip}>
						<div className={classes.tooltip}>
							<span>{dataLegendLabelX} -&nbsp;</span>
							<span>{label}</span>
						</div>
						<div className={classes.tooltipTable}>
							<table>
								<tbody>
									{payload.map((item: any, index: any) => (
										<tr key={index} style={{ color: `${item.color}` }}>
											<td>{dataChartLegend[index]}:</td>
											<td className={classes.tableTd}>{convertValue(checkChart, item.value)}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				);
			}
			return null;
		};

		const renderLegend = (props: any) => {
			const { payload } = props;
			return (
				<div className={classes.chartListItemWrap}>
					<ul className={classes.chartList}>
						{payload.map((entry: any, index: any) => (
							<div key={index} className={classes.chartListItem}>
								<span
									className={classes.legendCircle}
									style={{
										background: `${entry.color}`,
									}}
								/>
								<li style={{ color: `${entry.color}`, display: 'inline-flex' }} key={`item-${index}`}>
									{dataChartLegend[index]}
								</li>
							</div>
						))}
					</ul>
				</div>
			);
		};

		return (
			<ResponsiveContainer width="100%" height={height}>
				<LineChart
					width={width}
					height={height}
					data={data}
					margin={{
						top: 10,
						right: 20,
						left: 20,
						bottom: 0,
					}}
					className={classes.lineChart}
				>
					<XAxis
						dataKey="idName"
						label={{
							value: `${dataLegendLabelX}`,
							offset: -5,
							position: 'insideBottomRight',
							fontSize: '13px',
						}}
					/>

					<YAxis
						label={{
							value: `${translatedText[checkChart]}`,
							angle: -90,
							position: 'insideLeft',
							viewBox: { x: 10, y: 30, width: '100%', height: 30 },
							fontSize: '13px',
						}}
					/>

					<Tooltip wrapperStyle={{ zIndex: 100, pointerEvents: 'auto' }} content={<CustomizedTooltip />} />
					<Legend className={classes.legend} content={renderLegend} iconType="circle" />
					{dataKey.map((item, index) => (
						<Line
							key={index}
							type="monotone"
							dataKey={item}
							stroke={colors[index % colors.length]}
							strokeWidth={3}
						/>
					))}
				</LineChart>
			</ResponsiveContainer>
		);
	},
);

export default ChartArea;
