/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { instance } from 'api/api.config';
import { convertDate } from 'utils/convertDateAndTime/convertDate';
import { unitsOfTime } from 'utils/unitsOfTime';
import { convertComplexValuesForMarkup } from 'utils/convertDataForSearchFilters/convertData';
import { langSlice } from '../lang/lang.slice';
import { TagRuleType, Dict, Group, Item, Action, MR, TagRuleTypeResponse, MROwner } from './types';
import { mrSlice } from './index';
import { getRequestURL } from './utils';
import { OptionType } from 'store/reports/reports.types';

export const createNullArray = (count: number): null[] => {
	const result = [];
	for (let i = 0; i < count; i++) {
		result.push(null);
	}
	return result;
};

export const getActiveDicts = createAsyncThunk('tags/getActiveDicts', async (_, thunkAPI) => {
	try {
		const { data } = await instance.get<string[]>('/active_dicts/');
		thunkAPI.dispatch(mrSlice.actions.setActiveDicts(data));
	} catch (error) {
		thunkAPI.dispatch(
			langSlice.actions.setSnackbar({
				type: 'error',
				text: "We can't do activity",
				time: 2000,
			}),
		);
	}
});

export const getGroups = createAsyncThunk('mr/getGroups', async (payload: MR<null>, thunkAPI) => {
	try {
		const { data } = await instance.get<Group[]>(`${payload.page === 'dicts' ? 'dict' : 'tag_rule'}_groups/`);
		thunkAPI.dispatch(mrSlice.actions.setGroups({ page: payload.page, data }));
		return data;
	} catch (error) {
		thunkAPI.dispatch(
			langSlice.actions.setSnackbar({
				type: 'error',
				text: "We can't give you groups data",
				time: 2000,
			}),
		);
	}
});

interface IcloneGroupOrItem {
	id: string;
	type: 'group' | 'item';
	page: string;
}
export const cloneGroupOrItem = createAsyncThunk(
	'mr/cloneGroupOrItem',
	async (payload: IcloneGroupOrItem, thunkAPI) => {
		try {
			const { id, type, page } = payload;
			const { data } = await instance.post<string[]>(getRequestURL(type, page, id), {});
			return data;
		} catch (error) {
			thunkAPI.dispatch(
				langSlice.actions.setSnackbar({
					type: 'error',
					text: "We can't give you groups data",
					time: 2000,
				}),
			);
		}
	},
);

export const postGroup = createAsyncThunk(
	'mr/postGroup',
	async (payload: MR<{ title: string; enabled: boolean }>, thunkAPI) => {
		try {
			const { data } = await instance.post<string>(
				`${payload.page === 'dicts' ? 'dict' : 'tag_rule'}_group/`,
				payload.data,
			);
			return data;
		} catch (error: any) {
			thunkAPI.dispatch(
				langSlice.actions.setSnackbar({
					type: 'error',
					text: "We can't create group",
					time: 2000,
				}),
			);
			return thunkAPI.rejectWithValue(error.response?.status);
		}
	},
);

export const updateGroup = createAsyncThunk('mr/updateGroup', async (payload: MR<Group>, thunkAPI) => {
	try {
		const { data } = await instance.put<string>(
			`${payload.page === 'dicts' ? 'dict' : 'tag_rule'}_group/${payload.data.id}`,
			payload.data,
		);
		thunkAPI.dispatch(mrSlice.actions.updateGroup({ page: payload.page, data: { id: data } }));
		return data;
	} catch (error: any) {
		thunkAPI.dispatch(
			langSlice.actions.setSnackbar({
				type: 'error',
				text: "We can't update group",
				time: 2000,
			}),
		);
		return thunkAPI.rejectWithValue(error.response);
	}
});

export const deleteGroup = createAsyncThunk('mr/deleteGroup', async (payload: MR<{ id: string }>, thunkAPI) => {
	try {
		const { data } = await instance.delete(
			`${payload.page === 'dicts' ? 'dict' : 'tag_rule'}_group/${payload.data.id}`,
		);
		return data;
	} catch (error) {
		thunkAPI.dispatch(
			langSlice.actions.setSnackbar({
				type: 'error',
				text: "We can't delete group",
				time: 2000,
			}),
		);
	}
});

export const getItems = createAsyncThunk(
	'mr/getItems',
	async (payload: MR<{ sort: string; sort_desc: boolean; filter?: string; group?: string }>, thunkAPI) => {
		try {
			const { data } = await instance.get<Item[]>(`${payload.page === 'dicts' ? 'dicts' : 'tag_rules'}/`, {
				params: {
					sort: payload.data.sort,
					sort_desc: payload.data.sort_desc,
					filter: payload.data?.filter,
					group: payload.data?.group,
					show_disabled: true,
				},
			});
			thunkAPI.dispatch(mrSlice.actions.setItems({ page: payload.page, data }));
			thunkAPI.dispatch(mrSlice.actions.setGroupsBlock({ page: payload.page, data: false }));
			return data;
		} catch (error) {
			thunkAPI.dispatch(mrSlice.actions.setItems({ page: payload.page, data: null }));
			thunkAPI.dispatch(mrSlice.actions.setGroupsBlock({ page: payload.page, data: false }));
			thunkAPI.dispatch(
				langSlice.actions.setSnackbar({
					type: 'error',
					text: "We can't get items",
					time: 2000,
				}),
			);
		}
	},
);

export const getItem = createAsyncThunk('mr/getItem', async (payload: MR<{ id: string }>, thunkAPI) => {
	try {
		if (payload.page === 'tags') {
			thunkAPI.dispatch(mrSlice.actions.setTagIsLoading(true));
			const { data } = await instance.get<TagRuleType>(`tag_rule/${payload.data.id}`);
			const newData = payload.complexCriteria
				? convertComplexValuesForMarkup(data, payload.complexCriteria)
				: data;

			thunkAPI.dispatch(mrSlice.actions.setTag(newData));
			thunkAPI.dispatch(mrSlice.actions.setTagIsLoading(false));
			return data;
		}
		if (payload.page === 'dicts') {
			const { data } = await instance.get<Dict>(`dict/${payload.data.id}`);
			thunkAPI.dispatch(mrSlice.actions.setDict(data));
			return data;
		}
	} catch (error: any) {
		thunkAPI.dispatch(langSlice.actions.setSnackbar({ type: 'error', text: "We can't get item", time: 2000 }));
	}
});

export const updateItem = createAsyncThunk(
	'mr/updateItem',
	async (payload: MR<TagRuleTypeResponse | Dict | Item>, thunkAPI) => {
		try {
			if (payload.page === 'tags') {
				const { data } = await instance.put<TagRuleType>(`tag_rule/${payload.data.id}`, payload.data);
				return data;
			}
			if (payload.page === 'dicts') {
				const { data } = await instance.put<Dict>(`dict/${payload.data.id}`, payload.data);
				return data;
			}
		} catch (e: any) {
			return thunkAPI.rejectWithValue(e.response?.data);
		}
	},
);

export const postItem = createAsyncThunk('mr/postItem', async (payload: MR<TagRuleType | Dict>, thunkAPI) => {
	try {
		if (payload.page === 'tags') {
			const { data } = await instance.post<TagRuleType>('tag_rule/', payload.data);
			return data;
		}
		if (payload.page === 'dicts') {
			const { data } = await instance.post<Dict>('dict/', payload.data);
			return data;
		}
	} catch (e: any) {
		thunkAPI.dispatch(
			langSlice.actions.setSnackbar({
				type: 'error',
				text: "We can't create item",
				time: 2000,
			}),
		);

		return thunkAPI.rejectWithValue(e.response?.status);
	}
});

export const deleteItem = createAsyncThunk('mr/deleteItem', async (payload: MR<{ id: string }>, thunkAPI) => {
	try {
		if (payload.page === 'tags') {
			const { data } = await instance.delete<TagRuleType>(`tag_rule/${payload.data.id}`);
			return data;
		}
		if (payload.page === 'dicts') {
			const { data } = await instance.delete<Dict>(`dict/${payload.data.id}`);
			return data;
		}
	} catch (e) {
		thunkAPI.dispatch(
			langSlice.actions.setSnackbar({
				type: 'error',
				text: "We can't delete item",
				time: 2000,
			}),
		);
	}
});

export const doAction = createAsyncThunk('mr/doAction', async (payload: MR<{ id: string; action: Action }>) => {
	const date = {
		start_date: convertDate(unitsOfTime.today[0]),
		end_data: convertDate(unitsOfTime.today[1]),
	};
	let requestData = { action: payload.data.action };
	if (payload.data.action === 'retag') {
		requestData = { ...requestData, ...date };
	}
	const { data } = await instance.post(
		`${payload.page === 'dicts' ? 'dict' : 'tag_rule'}/${payload.data.id}/action`,
		requestData,
	);
	return data;
});

export const getOwnerItems = createAsyncThunk(
	'mr/getOwnerItems',
	async (payload: MR<{ sort: string; sort_desc: boolean; filter?: string; group?: string }>, thunkAPI) => {
		try {
			const requestedPage = payload.page === 'dicts' ? 'dicts' : 'tag_rules';
			const ruleOwner = payload.rule_owner ? `?rule_owner=${payload.rule_owner}` : '';
			const apiUrl = `${requestedPage}/${ruleOwner}`;

			const { data } = await instance.get<Item[]>(apiUrl, {
				params: {
					sort: payload.data.sort,
					sort_desc: payload.data.sort_desc,
					filter: payload.data?.filter,
					group: payload.data?.group,
					show_disabled: true,
				},
			});
			return { data, page: payload.page };
		} catch (error) {
			thunkAPI.dispatch(
				langSlice.actions.setSnackbar({
					type: 'error',
					text: "We can't get items",
					time: 2000,
				}),
			);
		}
	},
);

/**
 * Получаем группы пользователя,
 * у которого хотим импортировать правила / словари
 */
export const getOwnerGroups = createAsyncThunk('mr/getOwnerGroups', async (payload: MROwner<null>, thunkAPI) => {
	try {
		const apiURL = `${payload.page === 'dicts' ? 'dict' : 'tag_rule'}_groups/?rule_owner=${payload.rule_owner}`;
		const { data } = await instance.get<Group[]>(apiURL);

		return data;
	} catch (error) {
		thunkAPI.dispatch(
			langSlice.actions.setSnackbar({
				type: 'error',
				text: "We can't give you groups data",
				time: 2000,
			}),
		);
	}
});

// Получааем список пользователей для селекта в импорте.
// Этот список отличаается от основного списка пользователей, тем,
// что там только пользователи, без менеджеров и админов.
export const getUsersOnlyList = createAsyncThunk('mr/getUsersOnlyList', async (_, thunkAPI) => {
	try {
		const { data } = await instance.get<any>('/users/?with_childs=false&filter_only_users=true');
		return data;
	} catch (error) {
		thunkAPI.dispatch(
			langSlice.actions.setSnackbar({
				type: 'error',
				text: "We can't get users list",
				time: 2000,
			}),
		);
		return thunkAPI.rejectWithValue(error);
	}
});
