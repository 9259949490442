import { COUNT_PER_ITERATION } from '../consts';
import { ValidationEnum } from '../types';
import { SttValuesForRequest } from '../namespaces';
import { useSnackbar } from 'notistack';
import { createSnackbarOptions } from 'components/common/Snackbar/Snackbar';
import { translate } from 'localizations';

export const useValidateStt = (lang: string) => {
	const { enqueueSnackbar } = useSnackbar();

	/* валидация sttOptionsMap 

	 case 0 - если не выбраны селекты в стт
	 case 1 - если включены обе галочки диаризации
	 case 2 - если включена галочка "обьединить дорожки в один файл" и одна из диаризаций
	*/
	const validate = (values: SttValuesForRequest['sttOptions']['sttOptionsMap'], isDirty: boolean = false) => {
		let isValid = true;

		// case 0
		if (isDirty) {
			enqueueSnackbar(
				null,
				createSnackbarOptions({
					time: 10000,
					type: 'error',
					text: translate('reportSetParam', lang),
				}),
			);
			isValid = false;
		}
		// case 1
		if (ValidationEnum.diarization in values && ValidationEnum.engine_diarization in values) {
			if (values.diarization && values.engine_diarization) {
				enqueueSnackbar(
					null,
					createSnackbarOptions({
						time: 10000,
						type: 'error',
						text: translate('sttOptionsValidCaseOne', lang),
					}),
				);
				isValid = false;
			}
		}
		// case 2
		if (
			ValidationEnum.merge_all_to_one_audio in values &&
			(ValidationEnum.diarization in values || ValidationEnum.engine_diarization in values)
		) {
			if (values.merge_all_to_one_audio && (values.diarization || values.engine_diarization)) {
				enqueueSnackbar(
					null,
					createSnackbarOptions({
						time: 10000,
						type: 'error',
						text: translate('sttOptionsValidCaseTwo', lang),
					}),
				);
				isValid = false;
			}
		}
		return isValid;
	};

	/* валидация инпута числа от 1 до 10 включительно */
	const validateInputNumber = (label: string, fromTo: 'min' | 'max') => {
		if (label !== COUNT_PER_ITERATION) return;

		if (fromTo === 'min') return 1;
		else return 10;
	};

	/* валидация инпута числа от 1 до 10 включительно при вводе */
	const validateInputNumberValue = (value: string) => {
		if (!value.trim().length) return false;
		const newValue = parseInt(value, 10);
		return newValue >= 1 && newValue < 11;
	};

	return {
		validate,
		validateInputNumber,
		validateInputNumberValue,
	};
};
