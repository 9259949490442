import { useState, FC, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { OptionType } from 'components/common/Selects/MultiValueSelect/multiValuesSelect';

export interface CustomToggleBtnGroupProps {
	buttons: OptionType[],
	defaultValue: OptionType,
	onHandleSelect?: (value: OptionType) => void;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
	'& .MuiToggleButtonGroup-grouped': {
		textTransform: 'none',
		fontSize: '14px',
		fontWeight: '400',
		lineHeight: '22px',
		color: '#2F3747',
		margin: theme.spacing(0.5),
		border: 0,
		'&.Mui-disabled': {
			border: 0,
		},
		'&:not(:first-of-type)': {
			borderRadius: '5px',
		},
		'&:first-of-type': {
			borderRadius: '5px',
		},
		'&.Mui-selected': {
			backgroundColor: '#fff',
		},
	},
}));

const CustomToggleBtnGroup: FC<CustomToggleBtnGroupProps> = ({
	buttons,
	defaultValue,
	onHandleSelect,
}) => {
	const [selected, setSelected] = useState(defaultValue.value);

	useEffect(() => {
		setSelected(defaultValue.value);
	}, [defaultValue]);

	const handleAlignment = (event: any) => {
		setSelected(event.target.name);
		if (onHandleSelect) {
			onHandleSelect({ value: event.target.name, label: event.target.name });
		}
	};

	return (
		<div style={{
			display: 'inline-block',
			backgroundColor: '#EEF2F6',
			borderRadius: '8px',
		}}>
			<StyledToggleButtonGroup
				size="small"
				value={selected}
				exclusive
				onChange={handleAlignment}
				aria-label="text alignment"
			>
				{buttons.map(item => (
					<ToggleButton
						key={item.value}
						value={item.value}
						aria-label={item.label}
						name={item.label}
					>
						{item.label}
					</ToggleButton>
				))}
			</StyledToggleButtonGroup>
		</div>
	);
};

export default CustomToggleBtnGroup;

