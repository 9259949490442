import React, { FC, useEffect } from 'react';
import { useAppSelector } from 'hooks/redux';
import { translate } from 'localizations';
import { Hint, ContainedSelectWithLabel } from 'components/common';
import { OptionType } from 'components/common/Selects/MultiValueSelect/multiValuesSelect';
import { useHintTranslations } from 'hooks';
import { showError } from 'utils/forms';
import { labelValueObj } from 'shared/ui/PeopleList/types';
import { TextArea } from 'components/shared/Forms/TextArea/TextArea';
import { optionsCreatorVEL } from 'utils/optionsCreator';
import { UniversalInput } from 'shared/ui';
import { UserType } from 'store/user/user.types';

// TODO: вынести общие стили для формы отдельно на верхний уровень
import s from 'shared/ui/PeopleList/components/PeopleList.module.scss';

interface IUseFormFields {
	formik: any;
	setDisplayErrors: React.Dispatch<React.SetStateAction<string[]>>;
	displayErrors: string[];
	rolesArr?: labelValueObj[];
	industries?: labelValueObj[];
	partners?: labelValueObj[];
	person?: UserType | null;
	canEdit?: boolean;
	newEmployee?: boolean;
}

const useFormFields = (props: IUseFormFields) => {
	const { formik, setDisplayErrors, displayErrors, rolesArr, industries, partners, person, canEdit, newEmployee } =
		props;

	const { user, loggedUser } = useAppSelector((state) => state.user);
	const { language } = useAppSelector((state) => state.lang);
	const { allTimezones } = useAppSelector((state) => state.user);

	const canEditLogin = loggedUser?.accessRights?.includes('edit_user_login');

	const loginIsDisabled = newEmployee ? false : !canEditLogin;

	const hints = useHintTranslations();

	interface IFormField {
		type: string;
		component: FC<any>;
		props: any;
	}

	useEffect(() => {
		if (user?.role === 'manager') {
			const partnerValue = partners?.[0];
			if (partnerValue) {
				formik.setFieldValue('partner', partnerValue);
			}
		}
	}, [user?.role]);

	// TODO: убрать захардкоженную логику (handleChange и т.п.)
	// Сейчас здесь данные для pages/Employees/components/AddUserForm.tsx
	const config: Record<string, IFormField> = {
		name: {
			type: 'text_input',
			component: UniversalInput,
			props: {
				inputStyle: 'outlined',
				dataTestid: 'inputName',
				required: true,
				name: 'name',
				label: translate('nameOnly', language),
				placeholder: translate('nameOnly', language),
				value: formik.values.name,
				borderColor: showError('name', formik, displayErrors),
				handleChange: (event: React.ChangeEvent<HTMLInputElement>) => {
					formik.handleChange(event);
					setDisplayErrors(displayErrors.filter((error: string) => error !== 'name'));
				},
				tooltipTitle: hints.name,
				disabled: canEdit,
			},
		},
		login: {
			type: 'text_input',
			component: UniversalInput,
			props: {
				inputStyle: 'outlined',
				dataTestid: 'inputLogin',
				required: true,
				name: 'login',
				label: translate('login', language),
				placeholder: translate('login', language),
				value: formik.values.login,
				borderColor: showError('login', formik, displayErrors),
				handleChange: (event: React.ChangeEvent<HTMLInputElement>) => {
					formik.handleChange(event);
					setDisplayErrors(displayErrors.filter((error: string) => error !== 'login'));
				},
				tooltipTitle: hints.login,
				disabled: loginIsDisabled,
			},
		},
		password: {
			type: 'text_input',
			component: UniversalInput,
			props: {
				inputStyle: 'outlined',
				dataTestid: 'inputPassword',
				type: 'password',
				required: true,
				isPassword: true,
				name: 'password',
				autoComplete: 'new-password',
				label: translate('password', language),
				placeholder: translate('password', language),
				value: formik.values.password,
				borderColor: showError('password', formik, displayErrors),
				handleChange: (event: React.ChangeEvent<HTMLInputElement>) => {
					formik.handleChange(event);
					setDisplayErrors(displayErrors.filter((error: string) => error !== 'password'));
				},
				tooltipTitle: hints.password,
				disabled: canEdit,
			},
		},
		email: {
			type: 'text_input',
			component: UniversalInput,
			props: {
				inputStyle: 'outlined',
				dataTestid: 'inputEmail',
				width: '333px',
				name: 'email',
				margin: '0 0 16px 0',
				label: translate('user_email', language),
				placeholder: translate('user_email', language),
				value: formik.values.email,
				handleChange: (event: React.ChangeEvent<HTMLInputElement>) => {
					formik.handleChange(event);
				},
				tooltipTitle: hints.email,
				disabled: canEdit,
			},
		},
		role: {
			type: 'select',
			component: ContainedSelectWithLabel,
			props: {
				testId: 'selectRole',
				required: true,
				title: translate('userRole', language),
				placeholder: translate('select_role', language),
				onSelectChange: (event: OptionType): void => {
					setDisplayErrors(displayErrors.filter((error) => error !== 'role'));
					if (event?.value) {
						formik.setFieldValue('role', event);
					}
				},
				isSearchable: true,
				options: rolesArr,
				disabled: rolesArr?.length === 0 || canEdit,
				value: formik.values.role,
				hasError: !!showError('role', formik, displayErrors),
				labelBrother: <Hint className={s.form__input__hint} title={hints.role} />,
			},
		},
		industry: {
			type: 'select',
			component: ContainedSelectWithLabel,
			props: {
				testId: 'selectIndustry',
				required: false,
				title: translate('userIndustry', language),
				placeholder: translate('selectIndustry', language),
				onSelectChange: (event: OptionType): void => {
					setDisplayErrors(displayErrors.filter((error) => error !== 'industry'));
					if (event?.value) {
						formik.setFieldValue('industry', event);
					}
				},
				isSearchable: true,
				options: industries || [],
				disabled: industries?.length === 0 || !loggedUser?.accessRights?.includes('industry'),
				value: formik.values.industry,
				hasError: !!showError('industry', formik, displayErrors),
				labelBrother: <Hint className={s.form__input__hint} title={hints.industry} />,
			},
		},
		partner: {
			type: 'select',
			component: ContainedSelectWithLabel,
			props: {
				testId: 'selectPartner',
				required: false,
				title: translate('userPartner', language),
				placeholder: translate('selectPartner', language),
				onSelectChange: (event: OptionType): void => {
					setDisplayErrors(displayErrors.filter((error) => error !== 'partner'));
					if (event?.value) {
						formik.setFieldValue('partner', event);
					}
				},
				isSearchable: true,
				options: partners || [],
				disabled: partners?.length === 0 || !loggedUser?.accessRights?.includes('admin'),
				value: person?.role === 'manager' ? partners?.[0] : formik.values.partner,
				hasError: !!showError('partner', formik, displayErrors),
				labelBrother: <Hint className={s.form__input__hint} title={hints.partner} />,
			},
		},
		comment: {
			type: 'textarea',
			component: TextArea,
			props: {
				dataTestid: 'textAreaComment',
				name: 'comment',
				classNameRoot: s.form__comment__root,
				className: s.form__comment,
				classNameTitle: s.form__input__label,
				onChange: formik.handleChange,
				value: formik.values.comment,
				hasPlaceholder: true,
				placeholder: translate('leave_comment', language),
				title: translate('commentary', language),
				labelBrother: <Hint className={s.form__input__hint} title={hints.comment} />,
				disabled: canEdit,
			},
		},

		quotaRemindTime: {
			type: 'text_input',
			component: UniversalInput,
			props: {
				inputStyle: 'outlined',
				dataTestid: 'inputTime',
				required: true,
				className: s.form__minutes,
				width: '100%',
				name: 'quotaRemindTime',
				margin: '0 0 16px 0',
				label: translate('quotaAddnominal', language),
				placeholder: '',
				value: formik.values.quotaRemindTime,
				borderColor: showError('quotaRemindTime', formik, displayErrors),
				handleChange: (event: React.ChangeEvent<HTMLInputElement>) => {
					if (Number(event.target.value) || !event.target.value) {
						formik.handleChange(event);
						setDisplayErrors(displayErrors.filter((error: string) => error !== 'name'));
					}
				},
				tooltipTitle: hints.time,
			},
		},
		timezone: {
			type: 'select',
			component: ContainedSelectWithLabel,
			props: {
				testId: 'selectTimezone',
				disabled: !allTimezones?.length || canEdit,
				required: true,
				title: translate('timeZone', language),
				placeholder: translate('timeZone', language),
				onSelectChange: (event: OptionType): void => {
					if (event?.value) {
						formik.setFieldValue('timezone', event);
					}
				},
				options: optionsCreatorVEL(allTimezones),
				value: formik.values.timezone,
				hasError: !!showError('timezone', formik, displayErrors),
			},
		},
		phoneNumber: {
			type: 'text_input',
			component: UniversalInput,
			props: {
				inputStyle: 'outlined',
				dataTestid: 'inputPhone',
				width: '343px',
				name: 'phoneNumber',
				margin: '0 0 16px 0',
				label: translate('userPhone', language),
				placeholder: translate('userPhone', language),
				value: formik.values.phoneNumber,
				handleChange: (event: React.ChangeEvent<HTMLInputElement>) => {
					formik.handleChange(event);
				},
				tooltipTitle: hints.phone,
				disabled: canEdit,
			},
		},
	};

	return config;
};

export default useFormFields;
