import { useState } from 'react';
import { OptionType } from 'store/user/user.slice';
import useUsersOptions from './useUsersOptions';

interface useUserSelectProps {
	mode: 'SelectForHeader' | 'SimpleSelect';
	onUserSelect?: (selectedUser: OptionType) => void;
	defaultUser: OptionType;
}

export const useUserSelect = (props: useUserSelectProps) => {
	const { mode, onUserSelect, defaultUser } = props;

	const [selectedUser, setSelectedUser] = useState<OptionType>(defaultUser);

	const { SimpleUserSelectOptions, HeaderUserSelectOptions, convertedValue } = useUsersOptions();

	const handleUserChange = (selected: OptionType) => {
		if (!selected) return;
		setSelectedUser(selected);
		onUserSelect?.(selected);
	};

	let selectOptions = [{ value: '', label: '' }];
	let customStyles = {};
	let controlStyles = {};
	let valueContainerStyles = {};
	let indicatorStyles = {};

	switch (mode) {
		case 'SelectForHeader':
			selectOptions = [...HeaderUserSelectOptions];
			customStyles = {
				dropdownIndicator: (base: any) => ({
					...base,
					color: '#9254DE',
				}),
			};
			controlStyles = {
				position: 'relative !important',
				alignItems: 'start !important',
				borderRadius: '30px !important',
				border: '1px #9254DE solid !important',
				fontSize: '12px !important',
				lineHeight: '22px !important',
				color: '#2F3747 !important',
				height: '26px !important',
			};
			valueContainerStyles = {
				position: 'absolute !important',
				top: '-5px !important',
				width: '100% !important',
			};
			indicatorStyles = {
				position: 'absolute !important',
				top: '-4px !important',
				right: '0 !important',
			};
			break;
		case 'SimpleSelect':
			selectOptions = [...SimpleUserSelectOptions];
			break;
		default:
			customStyles = {};
			break;
	}

	const useUserSelectData = {
		selectOptions,
		selectedUser,
		customStyles,
		controlStyles,
		valueContainerStyles,
		indicatorStyles,
		convertedValue,
	};

	const useUserSelectMethods = { handleUserChange };

	return { useUserSelectData, useUserSelectMethods };
};
