import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { GPTCallNewComment } from '../store/calls/calls.types';
import { baseQueryWithReauth } from 'api/api.config';

export const gptCommentAPI = createApi({
	reducerPath: 'gptCommentAPI',
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		// queries
		// перевод комментария
		getCommentTranslation: builder.query({
			query: ({ comment_id, language }) => ({
				url: `/comment/${comment_id}/translate`,
				params: { language },
			}),
		}),
		// mutations
		// создание нового комментария
		postGptComment: builder.mutation<string, GPTCallNewComment>({
			query: (body) => ({
				url: '/comment/',
				method: 'POST',
				body,
			}),
		}),
		// удаление коммента по id
		deleteGptCommentById: builder.mutation<void, string>({
			query: (id) => ({
				url: `/comment/${id}`,
				method: 'DELETE',
			}),
		}),
		// редактирование коммента по id
		updateGptCommentById: builder.mutation<void, { id: string; body: GPTCallNewComment }>({
			query: ({ id, body }) => ({
				url: `/comment/${id}`,
				method: 'PATCH',
				body,
			}),
		}),
	}),
});
