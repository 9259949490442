import React, { FC, memo, PropsWithChildren, ReactElement } from 'react';
import { components } from 'react-select';
import { OptionType } from 'components/common/Selects/MultiValueSelect/multiValuesSelect';
import OnBottomArrow from 'shared/ui/icons/general/OnBottomArrow/OnBottomArrow';
import OnTopArrow from 'shared/ui/icons/general/OnTopArrow/OnTopArrow';
import { PlusIcon } from 'shared/ui';
import MinusIcon from 'shared/ui/icons/general/MinusIcon/MinusIcon';

import s from './customStyles.module.scss';
import { useMuiTextSelectStyles } from './styles.jss';

const Arrow: FC<{ isMenuOpen: boolean; ifArrowColor: string }> = ({ isMenuOpen, ifArrowColor }) => {
	const classes = useMuiTextSelectStyles({ arrowColor: ifArrowColor });

	return isMenuOpen ? (
		<div className={classes.selectArrowOnTopBox}>
			<OnTopArrow className={classes.selectArrow} />
		</div>
	) : (
		<OnBottomArrow className={classes.selectArrow} />
	);
};

interface CustomControlProps {
	isMenuOpen: boolean;
	ifArrowColor?: string;
	handleSelectClick?: () => void;
	iconPosition?: 'left' | 'right';
	icon?: ReactElement;
	customControl?: ReactElement;
}

export const CustomControl: FC<CustomControlProps & PropsWithChildren<any>> = ({
	isMenuOpen,
	ifArrowColor,
	handleSelectClick,
	iconPosition = 'left',
	icon,
	customControl,
	...props
}) => {
	const arrowIcon = <Arrow isMenuOpen={isMenuOpen} ifArrowColor={ifArrowColor} />;
	return (
		<components.Control {...props}>
			<div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', margin: '2px' }}>
				<div style={{ display: 'flex', alignItems: 'center' }} onClick={handleSelectClick}>
					{iconPosition === 'left' ? icon || arrowIcon : null}
					{customControl}
					{iconPosition === 'right' ? icon || arrowIcon : null}
				</div>
			</div>
		</components.Control>
	);
};

interface CustomOptionProps {
	selected: OptionType[];
	handleSelectOption: (option: OptionType) => void;
}

export const CustomOption: FC<PropsWithChildren<any> & CustomOptionProps> = ({ ...props }) => {
	const { handleSelectOption, selected, label, value } = props;
	const getIsActive = (selectedValue: string) => !!selected.find((item: OptionType) => item.value === selectedValue);

	return (
		<components.Option {...props}>
			<div className={s.option} onClick={() => handleSelectOption({ value, label })}>
				<span key={value}>{label}</span>
				{getIsActive(value) ? <MinusIcon style={{ width: '10px' }} /> : <PlusIcon />}
			</div>
		</components.Option>
	);
};

export const CustomMenuList: FC = memo((props: PropsWithChildren<any>) => <div>{props.children}</div>);
