import { ChangeEvent, ForwardedRef, forwardRef, HTMLProps, ReactNode } from 'react';
import cn from 'classnames';
import { Typography } from '@mui/material';

import s from './styles.module.scss';

interface IOwnProps extends HTMLProps<HTMLTextAreaElement> {
	hasPlaceholder?: boolean;
	dataTestid?: string | number | null;
	className?: string;
	title?: any;
	classNameTitle?: string;
	classNameRoot?: string;
	labelBrother?: ReactNode;
	borderColor?: string;
}

export const TextArea = forwardRef<HTMLTextAreaElement, IOwnProps>(
	(
		{
			className = '',
			hasPlaceholder,
			onChange,
			title,
			classNameTitle,
			classNameRoot,
			dataTestid,
			labelBrother,
			rows,
			cols,
			value,
			borderColor,
			...props
		},
		ref: ForwardedRef<HTMLTextAreaElement>,
	) => {
		const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
			onChange?.(event);
		};

		const textareaStyle = { borderColor };

		return (
			<div className={cn(s.textarea__wrap, classNameRoot)}>
				{title && (
					<div className={s.textarea__head}>
						<Typography className={cn(s.textarea__title, classNameTitle)}>{title}</Typography>
						{labelBrother}
					</div>
				)}
				<textarea
					ref={ref}
					data-testid={dataTestid}
					onChange={handleChange}
					value={value}
					className={cn(s.textarea, className, {
						hasPlaceholder,
					})}
					rows={rows}
					cols={cols}
					style={textareaStyle}
					{...props}
				/>
			</div>
		);
	},
);
