import { useSnackbar } from 'notistack';
import { createSnackbarOptions, SnackbarParamsType } from 'components/common/Snackbar/Snackbar';
import { translate } from 'localizations';

const useAPIResponseToasts = () => {
	const { enqueueSnackbar } = useSnackbar();

	return (
		requestStatus: 'fulfilled' | 'rejected',
		successText: string,
		errorText: string,
		language?: string,
		options?: { errorCode: number; errorMessage?: string },
	) => {
		const isSuccess = requestStatus === 'fulfilled';

		let messageText = isSuccess ? successText : errorText;
		let messageType: SnackbarParamsType = isSuccess ? 'success' : 'error';

		// Проверяем наличие специфического кода ошибки и настраиваем сообщение
		if (options && options.errorCode && !isSuccess) {
			if (options.errorCode === 409) {
				messageText = options.errorMessage || translate('profileWasNotUpdated', language || 'en');
				messageType = 'warning';
			}
			// Можно добавить обработку других кодов ошибок здесь
		}

		enqueueSnackbar(
			null,
			createSnackbarOptions({
				text: messageText,
				time: 2000,
				type: messageType,
			}),
		);
	};
};

export default useAPIResponseToasts;
