import { CriteriasType } from 'store/search/search.types';
import { OptionType } from 'components/common/Selects/LabelSelect/LabelSelect';
import {
	IChecklistSuggest,
	ICommentsSuggest,
	ISuggest,
} from 'components/common/Selects/MultiValueSelect/multiValuesSelect';
import { Logic } from 'components/common/Selects/MultiValueSelect/components/MultiSelectWithConditions/MultiSelectWithConditions';
import { TagRuleType } from 'store/markupRules/types';
import { sortCriteria } from 'store/search/utils';
import { ReportParametersType } from 'store/reports/reports.types';
import { criteriaWithComplexValues } from 'configs/searchCriteria';

export interface ICriterias {
	key: string;
	values?: string[];
	complexValues?: ISuggest[] | IChecklistSuggest[];
	logic?: Logic;
}

/**
 * Собираем строку из названия тега и его значения,
 * если оно есть
 * для селекта с типом "search_by_tags"
 */
export const getTagLabel = (tagName: string, tagValue: string | undefined) =>
	tagValue ? `${tagName}: ${tagValue}` : tagName;

/**
 * Собираем строку из по данным чеклиста
 * для селекта с типом "search_by_checklists"
 */
export const getChecklistLabel = (
	checklistTitle: string | undefined,
	questionText: string | undefined,
	answerText: string | undefined,
) => {
	const labelArr = [];
	if (checklistTitle) labelArr.push(checklistTitle);
	if (questionText) labelArr.push(questionText);
	if (answerText) labelArr.push(answerText);

	return labelArr.join(': ');
};

/**
 * Конвертируем complexValues в массив строк
 */
export const convertComplexValuesIntoTags = (values: ISuggest[] | IChecklistSuggest[]) =>
	values.map((value) => JSON.stringify(value));

export const convertComplexValuesForAppraisers = (values: ISuggest[] | IChecklistSuggest[] | undefined) =>
	values?.map((value) => JSON.stringify(value));

/**
 * Конвертируем сохраненные выбранные значения в формат
 * для селекта с типом "complex"
 */
export const getValuesForComplexSelect = (values: OptionType[], criteriaKey: string) =>
	values.map(({ value }) => {
		let label = '';

		switch (criteriaKey) {
			case 'search_by_checklists':
				const { checklist_title, question_text, answer_text } = JSON.parse(value);
				label = getChecklistLabel(checklist_title, question_text, answer_text);
				break;
			case 'search_by_comments':
				label = value;
				break;
			case 'search_by_tags':
			default:
				const { name: tagName, value: tagValue } = JSON.parse(value);
				label = getTagLabel(tagName, tagValue);
				break;
		}

		return { label, value };
	});

/**
 * Конвертируем данные, полученные из запроса,
 * в формат для списка селекта с типом "search_by_tags"
 */
export const getOptionsForComplexSelect = (data: ISuggest[], search: string) =>
	data.map(({ name, value, id }) => ({
		label: getTagLabel(name, value),
		value: JSON.stringify({ name, value }),
		id,
		search, // строка из инпута поиска
	}));

/**
 * Конвертируем данные, полученные из запроса,
 * в формат для списка селекта с типом "search_by_checklists"
 */
export const getOptionsForChecklistSearch = (data: IChecklistSuggest[], search: string) =>
	data.map(({ checklist_id, checklist_title, question_id, question_text, answer_text }) => ({
		label: getChecklistLabel(checklist_title, question_text, answer_text),
		value: JSON.stringify({ checklist_id, checklist_title, question_id, question_text, answer_text }),
		id: question_id || checklist_id,
		search, // строка из инпута поиска
	}));

/**
 * Конвертируем данные, полученные из запроса,
 * в формат для списка селекта с типом "search_by_comments"
 */
export const getOptionsForCommentsSearch = (data: ICommentsSuggest[], search: string) =>
	data.map((dataValue) => ({
		value: dataValue,
		label: dataValue,
	}));
/**
 * Конвертируем значения из селекта с типом "search_by_tags"
 * в формат {name: string; value?: string} для ключа complexValues
 * внутри тела запроса
 */
export const getComplexValues = (values: string[]): ISuggest[] => values.map((item) => JSON.parse(item));

/**
 * Формируем данные для запроса по определенной схеме
 */
const getCriteriaSchema = ({ key, values, logic }: ICriterias) => ({
	key,
	complexValues: getComplexValues(values as string[]),
	logic: logic || 'and',
});

export const convertValuesForGlobalFilter = (
	type: string,
	items: string[],
): { complexValues: ISuggest[] } | { values: string[] } =>
	criteriaWithComplexValues.includes(type)
		? { complexValues: items.map((item) => JSON.parse(item)) }
		: { values: items };

/**
 * Формируем данные для запроса
 */
export const convertCriteriasForRequest = (activeCriterias: CriteriasType[]): ICriterias[] => {
	const requestArray: ICriterias[] = [];
	activeCriterias.forEach((criteria) => {
		const { values, key } = criteria;

		if (values.length > 0) {
			const newValues = criteriaWithComplexValues.includes(key) ? getCriteriaSchema(criteria) : { values, key };
			requestArray.push(newValues);
		}
	});
	return requestArray;
};

export const getDataForActiveCriteria = (
	fullCriteria: CriteriasType,
	values: string[],
	logic?: Logic,
	conditionItemId?: number | undefined,
) => {
	const { key } = fullCriteria;
	const data = { ...fullCriteria, values };
	if (criteriaWithComplexValues.includes(key) && logic) data.logic = logic;
	if (criteriaWithComplexValues.includes(key) && conditionItemId !== undefined)
		data.conditionItemId = conditionItemId;

	return data;
};

/**
 * Формируем данные о полученном в ответе теге
 * с complex values для setTag (разметка)
 */
export const convertComplexValuesForMarkup = (tagData: TagRuleType, currentCriteria: CriteriasType) => {
	const { complexCriteria, otherCriteria } = sortCriteria(tagData.globalFilter);
	const convertedComplexCriteria = complexCriteria.map((item, i) => {
		if (criteriaWithComplexValues.includes(item.key)) {
			const newItem = {
				...currentCriteria,
				...item,
				values: convertComplexValuesIntoTags(item.complexValues as ISuggest[]),
				conditionItemId: i,
			};
			delete newItem.complexValues;
			return newItem;
		}
		return item;
	});

	return {
		...tagData,
		globalFilter: [...otherCriteria, ...convertedComplexCriteria],
	};
};

/**
 * Формируем данные тела запроса для сохранения нового
 * шаблона в звонках
 */
export const getPayloadForTemplate = (payload: { title: string; items: any[] }) => {
	const newPayload = payload.items.map(({ key, values, logic }) => {
		if (criteriaWithComplexValues.includes(key)) {
			const newItem = {
				key,
				logic,
				complexValues: values.map((value: string) => JSON.parse(value)),
			};

			return newItem;
		}

		return { key, values };
	});

	return {
		title: payload.title,
		items: newPayload,
	};
};

/**
 * Конвертируем данные для отображения сохраненных фильтров
 * в разметке, учитывая фильтры с complexValues
 */
export const convertDataForTags = (data: TagRuleType | false | null) => {
	if (!data) return data;

	return {
		...data,
		globalFilter: data.globalFilter.map((filter, i) => {
			if (criteriaWithComplexValues.includes(filter.key)) {
				return {
					...filter,
					values: convertComplexValuesIntoTags(filter.complexValues as ISuggest[]),
					conditionItemId: i,
				};
			}

			return filter;
		}),
	};
};

/**
 * Конвертируем данные для отображения сохраненных фильтров
 * в отчетах, учитывая фильтры с complexValues
 */
export const convertDataForReports = (data: ReportParametersType) => {
	const newColumnGroup = data.cols_group_by.map((column) => {
		// @ts-ignore
		if (column?.value?.search_items) {
			// @ts-ignore
			const newSearchItems = column.value.search_items.map((item, i) => {
				if (criteriaWithComplexValues.includes(item.key)) {
					return {
						...item,
						values: convertComplexValuesIntoTags(item.complexValues),
						logic: item.logic || 'and',
						conditionItemId: i,
					};
				}
				return item;
			});
			return {
				...column,
				value: {
					// @ts-ignore
					col_name: column.value.col_name,
					search_items: newSearchItems,
				},
			};
		}
		return column;
	});
	return {
		...data,
		cols_group_by: newColumnGroup,
	};
};
