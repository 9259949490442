import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import { useAppSelector } from 'hooks/redux';
import { searchSlice } from 'store';
import { translate } from 'localizations';
import { deleteTemplate } from 'store/search/search.slice';
import { TemplateType } from 'store/search/search.types';
import { ModalWindow } from 'shared/ui';

const useStyles = makeStyles({
	mwTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '15px',
	},
	mwTitleText: {
		fontWeight: '700 !important',
	},
	mwIconButton: {},
	mwText: {
		color: '#738094 !important',
	},
	mwHelp: {
		marginBottom: '35px !important',
	},
	mwButtonBox: {
		marginTop: '25px',
		textAlign: 'right',
	},
});

type CreateNameTemplateMWPropsType = {
	isOpen: boolean;
	handleClose: () => void;
	currentTemplate: TemplateType | null;
};

const CreateNameTemplateModalWindow: FC<CreateNameTemplateMWPropsType> = ({ isOpen, handleClose, currentTemplate }) => {
	const allTemplates = useAppSelector((state) => state.search.calls.allTemplates);
	const dispatch = useDispatch();
	const getTemplate = () => {
		if (allTemplates && currentTemplate) {
			return allTemplates.find((item) => item.title === currentTemplate.title);
		}
		return undefined;
	};

	const [loading, setLoading] = useState<boolean>(false);

	const formik = useFormik({
		initialValues: {
			name: '',
		},
		onSubmit: async () => {
			const template = getTemplate();
			if (template) {
				setLoading(true);
				await dispatch(deleteTemplate(template.id));
				dispatch(searchSlice.actions.setCurrentTemplate(null));
				dispatch(searchSlice.actions.removeAllActiveCriterias({ page: 'calls', data: null }));
				handleClose();
				setLoading(false);
			}
		},
	});
	const classes = useStyles();
	const { language } = useAppSelector((state) => state.lang);

	return (
		<div>
			<ModalWindow isMWOpen={isOpen} handleMWClose={handleClose} text={translate('deleteTemplateText', language)}>
				<form onSubmit={formik.handleSubmit}>
					<div className={classes.mwButtonBox}>
						<LoadingButton
							loading={loading}
							type="submit"
							style={{ marginRight: '15px' }}
							variant="contained"
							color="error"
						>
							{translate('deleteButton', language)}
						</LoadingButton>
						<LoadingButton onClick={handleClose} variant="contained" color="secondary">
							{translate('cancelButtonMW', language)}
						</LoadingButton>
					</div>
				</form>
			</ModalWindow>
		</div>
	);
};

export default CreateNameTemplateModalWindow;
