import { createNullArray } from './actions';
import { InitialState } from './types';

export const initialState: InitialState = {
	page: 'tags',
	ownerPage: 'tags',
	activeDicts: [],
	dicts: {
		groups: createNullArray(5),
		ownerGroups: createNullArray(5),
		groupsBlock: true,
		groupsOwnerBlock: true,
		currentGroup: null,
		currentOwnerGroup: null,
		dicts: createNullArray(15),
		ownerDicts: createNullArray(15),
		currentDict: false,
		currentOwnerDict: false,
		sort: { sort: 'title', sort_desc: false },
		ownerSort: { sort: 'title', sort_desc: false },
	},
	tags: {
		groups: createNullArray(5),
		ownerGroups: createNullArray(5),
		groupsBlock: true,
		groupsOwnerBlock: true,
		currentGroup: null,
		currentOwnerGroup: null,
		tags: createNullArray(15),
		ownerTags: createNullArray(15),
		currentTag: false,
		currentOwnerTag: false,
		sort: { sort: 'update_time', sort_desc: true },
		ownerSort: { sort: 'update_time', sort_desc: true },
	},
	copyMode: false,
	copyModeSuccess: false,
	ownerGroupsAndItemsLoading: false,
	rulesOwner: {
		value: '',
		label: '',
	},
	usersOnlyListForSelect: [],
	fragments: [],
	tagsIsLoading: false,
};
