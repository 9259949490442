import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'pages/Settings/types';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { UserType } from 'store/user/user.types';
import { getBaseCallsData, getTagNames } from 'store/calls/actions';
import { getUser, updateUser, userSlice } from 'store/user/user.slice';
import { RootState } from 'store/store';
import { useCurrentUser } from 'hooks';

export interface MultiValueSelectValueTypes {
	value: string;
	label: string;
}

interface useTagsFilterProps {
	toggleModalTagsFilter: () => void;
}

export const useTagsFilter = (props: useTagsFilterProps) => {
	const { toggleModalTagsFilter } = props;

	const { lang } = useParams<RouteParams>();
	const dispatch = useAppDispatch();

	const currentUser = useCurrentUser();
	const filterUser = useAppSelector((state) => state.user.childUser);
	const sort = useAppSelector((state: RootState) => state.calls.sort);

	const [isLoading, setIsLoading] = useState(false);
	const [userInfo, setUserInfo] = useState<UserType>();

	useEffect(() => {
		dispatch(getTagNames());

		const fetchUserInfo = async () => {
			if (currentUser) {
				const actionResult = await dispatch(getUser({ id: currentUser.id }));
				const userInfoFetch = actionResult.payload as UserType | undefined;
				setUserInfo(userInfoFetch);
			}
		};
		fetchUserInfo();
	}, []);

	const tagNames = useAppSelector((state) => state.calls.tagNames);

	// searchHideTags
	const [searchHideTagsInitialValues, setSearchHideTagsInitialValues] = useState<MultiValueSelectValueTypes[]>([]);
	useEffect(() => {
		setSearchHideTagsInitialValues(userInfo?.searchHideTags?.map((tag) => ({ value: tag, label: tag })) || []);
	}, [userInfo]);
	const [searchHideTagsValueHandler, setSearchHideTagsValueHandler] = useState<MultiValueSelectValueTypes[]>([]);

	// employeeTags
	const [employeeTagsInitialValues, setEmployeeTagsInitialValues] = useState<MultiValueSelectValueTypes[]>([]);
	useEffect(() => {
		setEmployeeTagsInitialValues(userInfo?.operatorTags?.map((tag) => ({ value: tag, label: tag })) || []);
	}, [userInfo]);
	const [employeeTagsValueHandler, setEmployeeTagsValueHandler] = useState<MultiValueSelectValueTypes[]>([]);

	// clientTags
	const [clientTagsInitialValues, setClientTagsInitialValues] = useState<MultiValueSelectValueTypes[]>([]);
	useEffect(() => {
		setClientTagsInitialValues(userInfo?.clientTags?.map((tag) => ({ value: tag, label: tag })) || []);
	}, [userInfo]);
	const [clientTagsValueHandler, setClientTagsValueHandler] = useState<MultiValueSelectValueTypes[]>([]);

	// toManyValuesExcludeTags
	const [toManyValuesExcludeTagsInitialValues, setToManyValuesExcludeTagsInitialValues] = useState<
		MultiValueSelectValueTypes[]
	>([]);
	useEffect(() => {
		setToManyValuesExcludeTagsInitialValues(
			userInfo?.toManyValuesExcludeTags?.map((tag) => ({ value: tag, label: tag })) || [],
		);
	}, [userInfo]);
	const [toManyValuesExcludeTagsHandler, setToManyValuesExcludeTagsHandler] = useState<MultiValueSelectValueTypes[]>(
		[],
	);

	const [searchHideTagsModified, setSearchHideTagsModified] = useState(false);
	const [employeeTagsModified, setEmployeeTagsModified] = useState(false);
	const [clientTagsModified, setClientTagsModified] = useState(false);
	const [toManyValuesExcludeTagsModified, setToManyValuesExcludeTagsModified] = useState(false);

	// Обработчики изменений для отслеживания модификаций
	const handleSearchHideTagsChange = (values: MultiValueSelectValueTypes[]): void => {
		setSearchHideTagsValueHandler(values);
		setSearchHideTagsModified(true);
	};

	const handleEmployeeTagsChange = (values: MultiValueSelectValueTypes[]): void => {
		setEmployeeTagsValueHandler(values);
		setEmployeeTagsModified(true);
	};

	const handleClientTagsChange = (values: MultiValueSelectValueTypes[]): void => {
		setClientTagsValueHandler(values);
		setClientTagsModified(true);
	};

	const handleToManyValuesExcludeTagsChange = (values: MultiValueSelectValueTypes[]): void => {
		setToManyValuesExcludeTagsHandler(values);
		setToManyValuesExcludeTagsModified(true);
	};

	const onSave = async () => {
		setIsLoading(true);

		if (currentUser) {
			const tagsObject = {
				id: currentUser.id,
				searchHideTags: searchHideTagsModified
					? searchHideTagsValueHandler.map((tag) => tag.value)
					: userInfo?.searchHideTags || [],
				operatorTags: employeeTagsModified
					? employeeTagsValueHandler.map((tag) => tag.value)
					: userInfo?.operatorTags || [],
				clientTags: clientTagsModified
					? clientTagsValueHandler.map((tag) => tag.value)
					: userInfo?.clientTags || [],
				toManyValuesExcludeTags: toManyValuesExcludeTagsModified
					? toManyValuesExcludeTagsHandler.map((tag) => tag.value)
					: userInfo?.toManyValuesExcludeTags || [],
			};
			await dispatch(updateUser(tagsObject));

			await dispatch(getBaseCallsData({ page: 1, sort: sort.sort, sortDesc: sort.sortDesc }));

			const updatedUser = await dispatch(getUser({ id: currentUser.id }));

			if (filterUser) {
				dispatch(userSlice.actions.setChildUser(updatedUser.payload as UserType));
			} else {
				dispatch(userSlice.actions.setCurrentUser(updatedUser.payload as UserType));
			}
		}

		setIsLoading(false);

		toggleModalTagsFilter();
	};

	const data = {
		lang,
		isLoading,
		userInfo,
		tagNames,
		searchHideTagsInitialValues,
		employeeTagsInitialValues,
		clientTagsInitialValues,
		toManyValuesExcludeTagsInitialValues,
	};

	const methods = {
		handleSearchHideTagsChange,
		handleEmployeeTagsChange,
		handleClientTagsChange,
		handleToManyValuesExcludeTagsChange,
		onSave,
	};

	return { data, methods };
};
