import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const select = (state: RootState) => state.calls;

export const selectCalls = createSelector(select, (calls) => calls.callsData);

export const selectCallsInfo = createSelector(select, (calls) => calls.callsInfo);

export const selectCallsLimitOnOnePage = createSelector(select, (calls) => calls.callsLimitOnOnePage);

export const selectCallsExpandedId = createSelector(select, (calls) => calls.expandedId);

export const selectCallsSort = createSelector(select, (calls) => calls.sort);

export const selectCurrentCall = createSelector(select, (calls) => calls.currentCall);

export const selectCallById = (id: string) =>
	createSelector(select, (calls) => {
		const candidate = (calls.callsInfo || [])?.find((x) => x?.id === id);

		return candidate ?? null;
	});

export const selectIsCallsLoad = createSelector(select, (calls) => calls.isCallsLoading);

export const selectFilterCallsHash = createSelector(select, (calls) => calls.filterHash);

export const selectSoloPage = createSelector(
	select,
	(calls) =>
		/**
		 * @unknown
		 */
		// @ts-ignore
		calls.soloPage,
);

export const selectMetaInfo = createSelector(select, (calls) => calls.metaInfo);
