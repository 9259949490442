/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import { BarChart, Bar, XAxis, YAxis, ReferenceLine, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useReportTranslations } from 'pages/Reports/hooks/useReportTranslations';
import { CheckTypeDataInChart } from 'pages/Reports/types/ReportsTypes';
import { convertValue } from 'pages/Reports/ReportsFunctions/ConvertValuesInCeil';
import { colors } from '../const';
import { useStyles } from './ChartBar.jss';

type ChartBarPropsType = {
	data: any[];
	width: number;
	height: number;
	dataKey: string[];
	checkChart: CheckTypeDataInChart;
	dataChartLegend: string[];
	dataLegendLabelX: string;
};

const ChartBar: FC<ChartBarPropsType> = React.memo(
	({ data, width, height, dataKey, checkChart, dataChartLegend, dataLegendLabelX }) => {
		const classes = useStyles();
		const { translatedText } = useReportTranslations();

		const CustomizedTooltip = ({ active, payload, label }: any) => {
			if (active && payload && payload.length) {
				return (
					<div className={classes.chartTooltip}>
						<div style={{ marginBottom: '8px' }}>
							<span>{dataLegendLabelX} -&nbsp;</span>
							<span>{label}</span>
						</div>
						<div style={{ height: '100%' }}>
							<table>
								<tbody>
									{payload.map((item: any, index: any) => (
										<tr key={index} style={{ color: `${item.fill}` }}>
											<td>{dataChartLegend[index]}:</td>
											<td style={{ paddingLeft: '10px', whiteSpace: 'nowrap' }}>
												{convertValue(checkChart, item.value)}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				);
			}
			return null;
		};

		const renderLegend = (props: any) => {
			const { payload } = props;
			return (
				<div className={classes.chartListItemWrap}>
					<ul className={classes.chartList}>
						{payload.map((entry: any, index: any) => (
							<div key={index} className={classes.chartListItem}>
								<span
									style={{
										fontSize: '12px',
										background: `${entry.color}`,
										borderRadius: '50%',
										width: '6px',
										height: '6px',
										marginRight: '5px',
									}}
								/>
								<li style={{ color: `${entry.color}`, display: 'inline-flex' }} key={`item-${index}`}>
									{dataChartLegend[index]}
								</li>
							</div>
						))}
					</ul>
				</div>
			);
		};

		return (
			<ResponsiveContainer width="100%" height={height}>
				<BarChart
					width={width}
					height={height}
					data={data}
					margin={{
						top: 5,
						right: 20,
						left: 20,
						bottom: 5,
					}}
					style={{ fontSize: '12px' }}
				>
					<XAxis
						dataKey="idName"
						label={{
							value: `${dataLegendLabelX}`,
							offset: -5,
							position: 'insideBottomRight',
							fontSize: '13px',
						}}
					/>

					<YAxis
						label={{
							value: `${translatedText[checkChart]}`,
							angle: -90,
							position: 'insideLeft',
							viewBox: { x: 10, y: 30, width: '100%', height: 30 },
							fontSize: '13px',
						}}
					/>

					<ReferenceLine y={0} stroke="#000" />
					<Tooltip wrapperStyle={{ zIndex: 100, pointerEvents: 'auto' }} content={<CustomizedTooltip />} />
					<Legend style={{ bottom: '0px' }} content={renderLegend} iconType="circle" />
					{dataKey.map((item, index) => (
						<Bar key={index} dataKey={item} fill={colors[index % colors.length]} stackId="stack" />
					))}
				</BarChart>
			</ResponsiveContainer>
		);
	},
);

export default ChartBar;
