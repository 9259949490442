import axios from 'axios';
import { API_URL } from '../config';
import { getTokens } from '../utils/tokens';
import { createBrowserHistory } from 'history';
import { AppDispatch } from 'store/store';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, fetchBaseQuery } from '@reduxjs/toolkit/dist/query';

const history = createBrowserHistory();
const lang = history.location.pathname.split('/')[1];
const pathArray = history.location.pathname.split('/');

export const instance = axios.create({
	baseURL: API_URL,
});

export const setupAxiosInterceptors = async (dispatch: AppDispatch) => {
	instance.interceptors.request.use(
		(config) => {
			const { token } = getTokens();
			if (token) {
				config.headers.Authorization = `Bearer ${token}`;
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		},
	);

	instance.interceptors.response.use(
		(response) => {
			return response;
		},
		async (error) => {
			if (!pathArray.includes('call')) {
				if (error.response && error.response.status === 401) {
					// Перенаправление на страницу авторизации
					await dispatch({ type: 'user/setAuth', payload: false });
					await dispatch({ type: 'user/removeAuthToken' });
					history.push(`/${lang}/auth`);
				}
			}

			return Promise.reject(error);
		},
	);
};

const baseQuery = fetchBaseQuery({
	baseUrl: API_URL,
	prepareHeaders: (headers) => {
		const { token } = getTokens();
		if (token) {
			headers.set('authorization', `Bearer ${token}`);
		}
		return headers;
	},
});

export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
	args,
	api,
	extraOptions,
) => {
	const result = await baseQuery(args, api, extraOptions);

	if (result.error && result.error.status === 401) {
		const dispatch = api.dispatch as AppDispatch;

		// Вызываем действия для обновления состояния
		await dispatch({ type: 'user/setAuth', payload: false });
		localStorage.clear();
		sessionStorage.clear();

		// Перенаправление на страницу авторизации
		history.push(`/${lang}/auth`);
	}

	return result;
};
