import { FC } from 'react';

interface EditIconSvgProps {
	props?: any;
	color: string;
	bg: string;
}

const EditIconSvg: FC<EditIconSvgProps> = ({ props, color, bg }) => (
	<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		{/* фон */}
		<path
			d="M22 17C22 19.7614 19.7614 22 17 22L5 22C2.23858 22 1.95703e-07 19.7614 4.37114e-07 17L1.48619e-06 5C1.7276e-06 2.23857 2.23858 -1.7276e-06 5 -1.48619e-06L17 -4.37114e-07C19.7614 -1.95703e-07 22 2.23858 22 5L22 17Z"
			fill={bg}
		/>
		{/* карандаш */}
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.7929 5.00123C13.9804 4.81369 14.2348 4.70833 14.5 4.70833C14.7652 4.70833 15.0196 4.81369 15.2071 5.00123L18 7.79412C18.1875 7.98166 18.2929 8.23601 18.2929 8.50123C18.2929 8.76644 18.1875 9.0208 18 9.20833L10.3536 16.8548C10.2598 16.9485 10.1326 17.0012 10 17.0012H7C6.73478 17.0012 6.48043 16.8959 6.29289 16.7083C6.10536 16.5208 6 16.2664 6 16.0012V13.2083C6 13.077 6.02587 12.947 6.07612 12.8257C6.12637 12.7043 6.20003 12.5941 6.29289 12.5012L13.7929 5.00123ZM17.2929 8.50123L14.5 5.70833L7 13.2083L6.65042 12.8588L7 13.2083L7 16.0012H9.79289L17.2929 8.50123Z"
			fill={color}
		/>
		{/* карандаш */}
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.1464 6.64645C12.3417 6.45118 12.6583 6.45118 12.8536 6.64645L16.3536 10.1464C16.5488 10.3417 16.5488 10.6583 16.3536 10.8536C16.1583 11.0488 15.8417 11.0488 15.6464 10.8536L12.1464 7.35355C11.9512 7.15829 11.9512 6.84171 12.1464 6.64645Z"
			fill={color}
		/>
	</svg>
);

export default EditIconSvg;
