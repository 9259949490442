import { IGPTQuestion } from 'widgets/GPTSettings/types';
import { GptEngine, GPTOptions, GPTOptionsModelAllOf, GPTOptionsModelWithoutAllOf, GPTOptionsProperties, IRuleTagForGpt } from 'store/gpt/types';
import { GptEngineParams } from '../gptService';

// Восстанавливаем порядок ответов, чтобы он совпадал
// с порядком вопросов в UI
// "временный" костыль, потому что АПИ ответы на вопросы-комментарии
// присылает массивом из строк
export const getAnswersFromRuleResponse = (
	answers: string[],
	tags: IRuleTagForGpt[][],
	questions?:  IGPTQuestion[],
) => {
	let answerInd = 0;

	return questions ? questions.map(question => {
		if (question.isComment) {
			const result = answers[answerInd];
			answerInd++;
			return result;
		}
		const tagsArr: string[] = [];

		tags.forEach(tag => tag.forEach(item => {
			if (question.tagName === item.tagName) {
				tagsArr.push(item.tagValue || 'no data');
			}
		}));

		return tagsArr.join(' ');
	}) : [];
};

const getAdditionalParams = (properties: GPTOptionsProperties) => {
	const params: Record<string, any> = {};

	const gptSettingsDict: Record<string, 'frequencyPenalty' | 'presencePenalty' | 'temperature'> = {
		frequency_penalty: 'frequencyPenalty',
		presence_penalty: 'presencePenalty',
		temperature: 'temperature',
	};

	(Object.keys(properties) as Array<keyof typeof properties>).forEach(item => {
		if (item in gptSettingsDict) {
			params[gptSettingsDict[item]] = properties[item];
		}
	});

	return params
};

export const getEngineOptions = (response: GPTOptions, engine: GptEngine): GptEngineParams => {
	const modelSchema = response.properties.model;
	let ref: string[] = [];
	if ('allOf' in modelSchema) {
		ref = (modelSchema as GPTOptionsModelAllOf).allOf[0].$ref.split('/');
	} else if ('$ref' in modelSchema) {
		ref = (modelSchema as GPTOptionsModelWithoutAllOf).$ref.split('/');
	}

	const model = ref[ref.length - 1];
	const modelProps = response.$defs[model];

	const additionalParams = getAdditionalParams(response.properties);

	return {
		currentGptEngine: engine,
		models: modelProps?.enum || [modelProps?.const],
		defaultModel: response.properties.model.default,
		additionalParams,
	};
}
