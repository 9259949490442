import { FC } from 'react';
import Select, { components, ControlProps, Props, StylesConfig, SingleValue } from 'react-select';
import TranslateIcon from 'shared/ui/icons/general/TranslateIcon/TranslateIcon';

import cls from './styles.module.scss';

interface DropdownButtonProps {
	buttonText: string;
	options: { value: string; label: string }[];
	onOptionSelect: (selectedOption: { value: string; label: string }) => void;
	disabled?: boolean;
	className?: string;
	dropdownButtonWrapper?: string;
	menuPositionRight?: string;
}

const Control = ({ children, ...props }: ControlProps<any, false>) => {
	const { isFocused } = props;
	const color = isFocused ? '#2F3747' : '#738094';

	return (
		<components.Control {...props} className={`${cls.control} ${isFocused ? cls.focused : ''}`}>
			<TranslateIcon className={`${cls.icon} ${isFocused ? cls.iconFocused : ''}`} style={{ fill: color }} />
			{children}
		</components.Control>
	);
};

const DropdownIndicator = () => null; // Убираем каретку
const IndicatorSeparator = () => null; // Убираем разделитель

const SelectWithIcon: FC<DropdownButtonProps> = (props) => {
	const { buttonText, options, onOptionSelect, className, dropdownButtonWrapper, disabled, menuPositionRight } =
		props;

	const handleChange = (selectedOption: SingleValue<{ value: string; label: string }>) => {
		if (selectedOption) {
			onOptionSelect(selectedOption);
		}
	};

	// StylesConfig для кастомизации стилей
	const customStyles: StylesConfig<{ value: string; label: string }, false> = {
		control: (provided, state) => ({
			...provided,
			width: 'max-content',
			cursor: 'pointer',
			padding: '1px 8px',
			color: '#738094',
			background: '#F8FAFC',
			border: '1px solid #CDD5DF',
			borderColor: '#CDD5DF',
			boxShadow: 'none',
			borderWidth: '0px',
			borderRadius: '5px',
			minHeight: 'auto',
			'&:hover': {
				borderColor: '#2F3747',
			},
		}),
		menu: (provided) => ({
			...provided,
			zIndex: 9999,
			width: '140px',
			right: '0',
		}),
		placeholder: (provided) => ({
			...provided,
			color: '#738094',
			'&:hover': {
				color: '#2F3747',
			},
		}),
		singleValue: (provided) => ({
			...provided,
			color: '#738094',
			'&:hover': {
				color: '#2F3747',
			},
		}),
	};

	return (
		<div className={`${cls.dropdownButton} ${dropdownButtonWrapper}`}>
			<Select
				components={{ Control, DropdownIndicator, IndicatorSeparator }}
				isSearchable={false}
				name="translate"
				options={options}
				onChange={handleChange}
				placeholder={buttonText}
				className={className}
				styles={customStyles}
				isDisabled={disabled}
				menuPortalTarget={document.body} // Рендерим меню в body
				menuPosition="fixed" // Используем фиксированное позиционирование
			/>
		</div>
	);
};

export default SelectWithIcon;
