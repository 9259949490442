import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from 'api/api.config';

export const consumptionHistoryApi = createApi({
	reducerPath: 'consumptionHistoryApi',
	baseQuery: baseQueryWithReauth,
	refetchOnMountOrArgChange: true,
	endpoints: (build) => ({
		getCallsHistory: build.query<any, { userId: string; startDate: string; endDate: string }>({
			query: ({ userId, startDate, endDate }) => ({
				url: `/user/${userId}/history/calls`,
				params: {
					start_date: startDate,
					end_date: endDate,
				},
			}),
		}),
		getChatHistory: build.query<any, { userId: string; startDate: string; endDate: string }>({
			query: ({ userId, startDate, endDate }) => ({
				url: `/user/${userId}/history/chats`,
				params: {
					start_date: startDate,
					end_date: endDate,
				},
			}),
		}),
		getGPTHistory: build.query<any, { userId: string; startDate: string; endDate: string }>({
			query: ({ userId, startDate, endDate }) => ({
				url: `/user/${userId}/history/gpt`,
				params: {
					start_date: startDate,
					end_date: endDate,
				},
			}),
		}),
	}),
});

export const { useGetCallsHistoryQuery, useGetChatHistoryQuery, useGetGPTHistoryQuery } = consumptionHistoryApi;
