import { Moment } from 'moment';
import { PeriodsType } from 'store/reports/reports.types';
import { unitsOfTime } from 'utils/unitsOfTime';

export const convertDateFromPeriod = (period: PeriodsType) => {
	const dateArray: (Moment | null)[] = unitsOfTime[period];

	let dateArrayFormating: (string | null)[] = [null, null];
	if (period !== 'all_time' && dateArray[0] && dateArray[1]) {
		dateArrayFormating = [dateArray[0].utc(true).format(), dateArray[1].utc(true).format()];
	}
	return { dateArray, dateArrayFormating };
};
