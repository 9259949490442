import React, { FC } from 'react';

const GPTIcon: FC<React.SVGProps<SVGSVGElement>> = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
		<g clipPath="url(#clip0_5657_27301)">
			<path fillRule="evenodd" clipRule="evenodd" d="M5.6 0L4 4L0 5.59846L4 7.2L5.6 11.2L7.2 7.2L11.2 5.60423L7.2 4L5.6 0Z" fill="#738094"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M12 8L10.8 10.797L8 11.9959L10.8 13.1945L12 16L13.2 13.1945L16 12L13.2 10.797L12 8Z" fill="#738094"/>
		</g>
		<defs>
			<clipPath id="clip0_5657_27301">
				<rect width="16" height="16" fill="white"/>
			</clipPath>
		</defs>
	</svg>
);

export default GPTIcon;
