import { FC, SVGProps } from 'react';

const AttachmentIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
	<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M8.34375 5.5V2.125H3.625V13.875H12.375V6.15625H9C8.82595 6.15625 8.65903 6.08711 8.53596 5.96404C8.41289 5.84097 8.34375 5.67405 8.34375 5.5Z"
			fill="#F9F0FF"
		/>
		<path
			d="M13.3531 4.50938L9.99063 1.14688C9.89688 1.05313 9.77031 1 9.6375 1H3C2.72344 1 2.5 1.22344 2.5 1.5V14.5C2.5 14.7766 2.72344 15 3 15H13C13.2766 15 13.5 14.7766 13.5 14.5V4.86406C13.5 4.73125 13.4469 4.60313 13.3531 4.50938ZM9.40625 2.15313L12.3469 5.09375H9.40625V2.15313ZM12.375 13.875H3.625V2.125H8.34375V5.5C8.34375 5.67405 8.41289 5.84097 8.53596 5.96404C8.65903 6.08711 8.82595 6.15625 9 6.15625H12.375V13.875Z"
			fill="#722ED1"
		/>
	</svg>
);

export default AttachmentIcon;
