import { FC } from 'react';
import { IconButton, PlusIcon } from 'shared/ui';

type PlusButtonPropsType = {
	margin?: string;
	handleClick?: any;
	disabled?: boolean;
	iconColor?: string;
	iconWidth?: string;
	iconHeight?: string;
};

const PlusButton: FC<PlusButtonPropsType> = (props) => {
	const { disabled, margin, handleClick, iconColor, iconWidth, iconHeight } = props;

	return (
		<IconButton
			disabled={disabled}
			margin={margin || '0'}
			backgroundColor="#D9F7BE"
			icon={<PlusIcon width={iconWidth} height={iconHeight} color={iconColor} />}
			onClick={handleClick}
		/>
	);
};

export default PlusButton;
