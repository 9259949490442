import TooltipQuestion from 'shared/ui/TooltipQuestion/TooltipQuestion';
import { SelectValueFormat } from '../ui/ManualTagging';
import { IconButton, PlusIcon } from 'shared/ui';
import { useAppSelector } from 'hooks/redux';

import s from './styles.module.scss';
import mixpanel from 'mixpanel-browser';
import { EnvsManager } from 'utils/enviroments';
import { FC } from 'react';

interface ManualTaggingHeaderProps {
	label: string;
	labelTooltip: string;
	disabledAddBtn: boolean;
	handlerAddManualTag: (e: SelectValueFormat) => void;
}

const ManualTaggingHeader: FC<ManualTaggingHeaderProps> = ({ label, labelTooltip, disabledAddBtn, handlerAddManualTag }) => {
	const { loggedUser } = useAppSelector((state) => state.user);

	const handleButtonClick = (e: any) => {
		if (!EnvsManager.isDev) {
			// Отправляем событие в Mixpanel с именем пользователя
			mixpanel.track('Manual Tagging Button Clicked', {
				userName: loggedUser?.name || 'Unknown User',
				label,
			});
		}

		// Вызываем оригинальный обработчик
		handlerAddManualTag(e);
	};

	return (
		<div className={s.manualGroupHeader}>
			<div className={s.manualGroupFlex}>
				<h4 className={s.manualGroupLabel}>{label}</h4>
				<TooltipQuestion tooltipTitle={labelTooltip} placement="top" rootClassName={s.manualGroupTootlip} />
			</div>
			<IconButton
				disabled={disabledAddBtn}
				onClick={handleButtonClick}
				icon={<PlusIcon color="#738094" />}
				height="22px"
				width="22px"
				padding="0 6px"
			/>
		</div>
	);
};

export default ManualTaggingHeader;
