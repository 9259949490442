import { manualTaggingAPI } from 'services/manualTaggingService';

export const useManualTaggingService = () => {
	const [addManualTag, { isError: isErrorAddManual, isLoading: isLoadingAddManual, isSuccess: isSuccessAddManual }] =
		manualTaggingAPI.useAddManualTagMutation();
	const [
		deleteManualTag,
		{ isError: isErrorDeleteManual, isLoading: isLoadingDeleteManual, isSuccess: isSuccessDeleteManual },
	] = manualTaggingAPI.useDeleteManualTagMutation();

	const { data: manualTags, refetch } = manualTaggingAPI.useGetManualTagsQuery(null);

	return {
		data: {
			isLoadingAddManual,
			isErrorAddManual,
			isSuccessAddManual,
			tagsListForSelect: manualTags,

			isErrorDeleteManual,
			isLoadingDeleteManual,
			isSuccessDeleteManual,
		},
		methods: {
			addManualTag,
			deleteManualTag,
			updateManualTags: refetch,
		},
	};
};
