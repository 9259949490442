import React, { FC } from 'react';
import { EditableInput } from 'shared/ui';
import { translate } from 'localizations';
import { GPTCallComment as GPTComment } from 'store/calls/calls.types';
import { useAppSelector } from 'hooks/redux';

import s from './styles.module.scss';

interface CommentHeaderProps extends Pick<GPTComment, 'title' | 'message'> {
	submitChanges: () => void;
	setEditMode: (param: boolean) => void;
	editTitle: string;
	setEditTitle: (param: string) => void;
	setEditMessage: (param: string) => void;
	editMessage: string;
}

const CommentEditMode: FC<CommentHeaderProps> = ({
	title,
	message,
	submitChanges,
	setEditMode,
	editTitle,
	setEditTitle,
	setEditMessage,
	editMessage,
}) => {
	const { language } = useAppSelector((state) => state.lang);
	const submitIsDisabled = !editMessage.length || (editTitle === title && editMessage === message);

	return (
		<>
			<EditableInput
				classNameInput={s.editableInput}
				value={editTitle}
				size="small"
				withActions={false}
				onChange={setEditTitle}
				placeholder={translate('enterTitle', language)}
			/>
			<EditableInput
				type="textarea"
				textareaClassName={s.editableTextarea}
				value={message}
				onChange={setEditMessage}
				onConfirm={submitChanges}
				onCancel={() => {
					setEditMode(false);
					setEditTitle(title);
					setEditMessage(message);
				}}
				size="small"
				isDisabled={submitIsDisabled}
			/>
		</>
	);
};

export default CommentEditMode;
