import React from 'react';

export const Share = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.6464 3.14645C10.8417 2.95118 11.1583 2.95118 11.3536 3.14645L14.3536 6.14645C14.5488 6.34171 14.5488 6.65829 14.3536 6.85355L11.3536 9.85355C11.1583 10.0488 10.8417 10.0488 10.6464 9.85355C10.4512 9.65829 10.4512 9.34171 10.6464 9.14645L13.2929 6.5L10.6464 3.85355C10.4512 3.65829 10.4512 3.34171 10.6464 3.14645Z"
			fill="#738094"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2 5C2.27614 5 2.5 5.22386 2.5 5.5V13H12C12.2761 13 12.5 13.2239 12.5 13.5C12.5 13.7761 12.2761 14 12 14H2.5C2.23478 14 1.98043 13.8946 1.79289 13.7071C1.60536 13.5196 1.5 13.2652 1.5 13V5.5C1.5 5.22386 1.72386 5 2 5Z"
			fill="#738094"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.5003 7C9.28081 7.0001 8.0959 7.40531 7.13171 8.15197C6.16751 8.89864 5.47868 9.94446 5.17341 11.1251C5.10429 11.3925 4.83152 11.5532 4.56417 11.4841C4.29682 11.4149 4.13612 11.1422 4.20525 10.8748C4.56599 9.47957 5.38001 8.24369 6.51944 7.36132C7.65887 6.47896 9.05913 6.00011 10.5003 6H14.0003C14.2764 6 14.5003 6.22386 14.5003 6.5C14.5003 6.77614 14.2764 7 14.0003 7H10.5003C10.5003 7 10.5003 7 10.5003 7Z"
			fill="#738094"
		/>
	</svg>
);
