import { FC } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useHistory } from 'react-router-dom';
import { UserType } from 'store/user/user.types';
import { useAppDispatch } from 'hooks/redux';
import { updateUser } from 'store/user/user.slice';
import { LangType, getLang } from 'store/lang/lang.slice';

import s from './styles.module.scss';

interface LangSelectorProps {
	user: UserType | null;
	childUser: UserType | null;
	path: string;
	language: LangType;
}

const languages = [
	{ value: 'ru', label: 'RU' },
	{ value: 'en', label: 'EN' },
	{ value: 'es', label: 'ES' },
	{ value: 'pt', label: 'PT' },
];

const LangSelector: FC<LangSelectorProps> = (props) => {
	const { user, childUser, path, language } = props;
	const dispatch = useAppDispatch();
	const history = useHistory();

	async function handleLangChange(event: SelectChangeEvent) {
		if (user) {
			const currentLang = event.target.value as LangType;
			await dispatch(
				updateUser({
					id: childUser ? childUser.id : user.id,
					language: currentLang,
				}),
			);
			await dispatch(getLang(currentLang));
			history.push(`/${currentLang}/${childUser ? childUser.id : user.id}/${path}`);
			window.location.reload();
		}
	}

	return (
		<Select
			MenuProps={{ disableScrollLock: true }}
			className={s.langHandler}
			variant="standard"
			value={language}
			onChange={handleLangChange}
		>
			{languages.map((lang) => (
				<MenuItem key={lang.value} className={s.langHandlerItem} value={lang.value}>
					{lang.label}
				</MenuItem>
			))}
		</Select>
	);
};

export default LangSelector;
