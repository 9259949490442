import React, { FC, ReactElement } from 'react';
import { Redirect } from 'react-router-dom';

interface WithRedirectProps {
	showContent: boolean;
	children: ReactElement;
	redirectUrl: string;
}
const WithRedirect: FC<WithRedirectProps> = ({
	showContent,
	children,
	redirectUrl,
}) => showContent ? children : <Redirect to={redirectUrl} />;

export default WithRedirect;
