import react from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface ScorePercentProps {
	condition: string;
	scorePercent: number | null;
}

const ScorePercent: React.FC<ScorePercentProps> = ({ condition, scorePercent }) => (
	<div
		className={cn(styles.root, {
			[styles.scorePercent]: true,
			[styles[`background--${condition}`]]: condition,
		})}
	>
		<div className={styles.circle} />
		<div>{`${scorePercent}%`}</div>
	</div>
);

export default ScorePercent;
