import { FC } from 'react';
import { translate } from 'localizations';
import LoadingButton from '@mui/lab/LoadingButton';
import { UniversalButton } from 'shared/ui';
import { useTagsFilter } from './hooks/useTagsFilter';
import TagsFilterSelect from './components/TagsFilterSelect/TagsFilterselect';
import SkeletonBlock from './components/SkeletonBlock/SkeletonBlock';

import cls from './styles.module.scss';

interface TagsFilterModalInnerProps {
	toggleModalTagsFilter: () => void;
}

const TagsFilterModalInner: FC<TagsFilterModalInnerProps> = ({ toggleModalTagsFilter }) => {
	const { data, methods } = useTagsFilter({ toggleModalTagsFilter });
	const {
		lang,
		isLoading,
		userInfo,
		tagNames,
		searchHideTagsInitialValues,
		clientTagsInitialValues,
		employeeTagsInitialValues,
		toManyValuesExcludeTagsInitialValues,
	} = data;

	const {
		handleSearchHideTagsChange,
		handleClientTagsChange,
		handleEmployeeTagsChange,
		handleToManyValuesExcludeTagsChange,
		onSave,
	} = methods;

	return (
		<div className={cls.tagsFilter}>
			<div className={cls.inputsBlock}>
				<div className={cls.inputWrapper}>
					{!userInfo ? (
						<SkeletonBlock className={cls.skeleton} />
					) : (
						<TagsFilterSelect
							titleClassName={cls.typographyTitleMini}
							title={translate('searchHideTags', lang)}
							testId="searchHideTags"
							tagNames={tagNames}
							handleSearchHideTagsChange={handleSearchHideTagsChange}
							searchHideTagsInitialValues={searchHideTagsInitialValues}
						/>
					)}
				</div>

				<div className={cls.inputWrapper}>
					{!userInfo ? (
						<SkeletonBlock className={cls.skeleton} />
					) : (
						<TagsFilterSelect
							titleClassName={cls.typographyTitleMini}
							title={translate('employeeTags', lang)}
							testId="employeeTags"
							tagNames={tagNames}
							handleSearchHideTagsChange={handleEmployeeTagsChange}
							searchHideTagsInitialValues={employeeTagsInitialValues}
						/>
					)}
				</div>

				<div className={cls.inputWrapper}>
					{!userInfo ? (
						<SkeletonBlock className={cls.skeleton} />
					) : (
						<TagsFilterSelect
							titleClassName={cls.typographyTitleMini}
							title={translate('clientTags', lang)}
							testId="clientTags"
							tagNames={tagNames}
							handleSearchHideTagsChange={handleClientTagsChange}
							searchHideTagsInitialValues={clientTagsInitialValues}
						/>
					)}
				</div>

				<div className={cls.inputWrapper}>
					{!userInfo ? (
						<SkeletonBlock className={cls.skeleton} />
					) : (
						<TagsFilterSelect
							titleClassName={cls.typographyTitleMini}
							title={translate('toManyValuesExcludeTags', lang)}
							testId="toManyValuesExcludeTags"
							tagNames={tagNames}
							handleSearchHideTagsChange={handleToManyValuesExcludeTagsChange}
							searchHideTagsInitialValues={toManyValuesExcludeTagsInitialValues}
						/>
					)}
				</div>
			</div>
			<div className={cls.buttonsBlock}>
				<UniversalButton
					testId=""
					text={translate('cancelButton', lang)}
					handleClick={toggleModalTagsFilter}
					buttonStyle="ghost"
					disabled={isLoading || !userInfo}
					className={cls.cancelButton}
				/>
				<LoadingButton
					className={cls.saveButton}
					color="primary"
					variant="contained"
					loading={isLoading || !userInfo}
					onClick={onSave}
					disabled={isLoading}
				>
					{translate('saveButton', lang)}
				</LoadingButton>
			</div>
		</div>
	);
};

export default TagsFilterModalInner;
