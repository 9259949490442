import React, { useState } from 'react';
import classNames from 'classnames';
import { StopIcon } from 'components/Icons/StopIcon';
import { TCheckList, TCheckListItem, TCheckListItemValue } from '../../namespace';

import './styles.css';

interface IOwnProps extends Omit<TCheckList, 'id'> {
	onClickCheckListItem(target: TCheckListItem): void;
	selectedItem: TCheckListItemValue;
	callCheckListDisabled: boolean;
	isManual: boolean;
}

const CheckList: React.FC<IOwnProps> = ({
	title,
	checkList,
	onClickCheckListItem,
	selectedItem,
	callCheckListDisabled,
	isManual,
}) => {
	const [activeBtn, setActiveBtn] = useState(selectedItem);
	const [isEditedByUser, setIsEditedByUser] = useState(isManual);

	const onItemClick = (item: TCheckListItem): void => {
		setActiveBtn(item.value);

		onClickCheckListItem(item);
	};
	const onClearHandle = (): void => {
		setIsEditedByUser(false);
		setActiveBtn(null);
		const clearResult: TCheckListItem = {
			value: null,
		};
		onItemClick(clearResult);
	};

	return (
		<div className="CheckList">
			<p className="CheckListTitle">{title}</p>
			<div className="CheckListBody">
				<button
					className={classNames('CheckListItem', {
						active: activeBtn === null,
					})}
					disabled={callCheckListDisabled}
					onClick={onClearHandle}
				>
					<StopIcon />
				</button>
				{checkList.map((checkListItem, currentIndex) => (
					<button
						className={classNames('CheckListItem', {
							active: activeBtn === checkListItem.label,
							isManual: isEditedByUser && (activeBtn === checkListItem.label),
						})}
						key={`${checkListItem.label}-${checkListItem.value ?? currentIndex}`}
						onClick={(): void => {
							onItemClick(checkListItem);
							setIsEditedByUser(true);
						}}
						disabled={callCheckListDisabled}
					>
						{checkListItem.label}
					</button>
				))}
			</div>
		</div>
	);
};

export default CheckList;
