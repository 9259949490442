import { MouseEvent, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { callsActions } from 'store/calls';
import { getCallPublicToken } from 'store/calls/calls.slice';
import { CallInfoType, CallTagType } from 'store/calls/calls.types';
import { getUrlForDev } from 'utils/url';
import { IconButton } from 'shared/ui';
import { Skeleton } from '@mui/material';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { Share } from 'components/Icons/Share';
import { styled } from '@mui/material/styles';
import ArrowIcon from 'components/Icons/ArrowIcon';
import { translate } from 'localizations';
import { useCurrentUser } from 'hooks';
import { useStyles } from '../Call.jss';
import { ISearchedWord } from '../components/TagsBlock/TagsBlock';
import { CommonPropsType } from '../types';
import { MetaTags } from '../CallsPage';

type useCallsProps = CommonPropsType;

export interface TagWithValue {
	id: string;
	name: string;
	value: string;
	visible: boolean;
}

interface NewMetaTagsType {
	clientTags: TagWithValue[] | undefined;
	operatorTags: TagWithValue[] | undefined;
}

export const useCalls = (props: useCallsProps) => {
	const {
		callInfo,
		expanded,
		loadCheckLists,
		language,
		userInfo,
		isAuth,
		solo,
		isLoading,
		showOpenLink,
		accessRights,
	} = props;

	const dispatch = useDispatch();
	const classes = useStyles();
	const host = window.origin;

	const currentUser = useCurrentUser();

	useEffect(() => {
		if (expanded && callInfo?.id) {
			loadCheckLists(callInfo?.id);
		}
	}, [callInfo?.id, expanded, loadCheckLists]);

	const scroll = async (currentTarget: any) => {
		if (currentTarget) {
			const topValue = window.pageYOffset + currentTarget.getBoundingClientRect().top;
			await window.scrollTo({
				top: topValue,
				behavior: 'smooth',
			});
		}
	};

	async function openLinkInNewTab() {
		if (callInfo) {
			const hrefWithEnv = getUrlForDev(
				`${language}/${userInfo?.id}/call?id=${callInfo.id}`,
			);
			const newHref = `${host}${hrefWithEnv}`;

			window.open(newHref, '_blank');
		}
	}

	const accordion = useRef<any | null>(null);
	const accordionSummary = useRef<any | null>(null);
	const audioDivRef = useRef<any | null>(null);
	const tagsDivRef = useRef<any | null>(null);
	const paramsDivRef = useRef<any | null>(null);

	const onClick = async (e: React.MouseEvent<HTMLElement>): Promise<void> => {
		if (!callInfo) {
			return;
		}

		const selected = window.getSelection();
		const selectionText = selected?.toString();
		if (selectionText) {
			return;
		}

		if (!solo) {
			if (!expanded && callInfo) {
				// сборс переведенного текста
				dispatch(callsActions.setCallTranslated(null));
				dispatch(callsActions.setExpanded({ id: callInfo.id }));
				scroll(e.target).then();
			} else if (e.target.tagName !== 'path') {
				dispatch(callsActions.setExpanded({ id: null }));
			}
	
			if (accordion.current && e.target.tagName !== 'path') {
				await window.scrollTo({
					top: window.scrollY + accordion.current.getBoundingClientRect().top,
					behavior: 'auto',
				});
			}
		}
	};

	const getOpenLinkButton = () => {
		if (!isAuth) return null;
		if (solo) return null;

		return isAuth && !isLoading ? (
			<IconButton
				margin="16px 0 0 8px"
				backgroundColor="#F8FAFC"
				icon={<Share />}
				onClick={openLinkInNewTab}
				testId="call_share"
				tooltipTitle={translate('tooltipAction', language)}
			/>
		) : (
			<Skeleton width={32} height={52} variant="text" style={{ margin: '5px 0 0 8px' }} />
		);
	};

	const AccordionSummary = styled((accordionProps: AccordionSummaryProps) => (
		<div style={{ display: 'flex', width: '100%' }}>
			<MuiAccordionSummary
				tabIndex={-1}
				expandIcon={
					!solo && (
						<div>
							{isAuth && !isLoading ? (
								<span onClick={onClick} className={classes.expandIcon} data-testid="call_expand">
									<ArrowIcon />
								</span>
							) : (
								<Skeleton width={32} height={52} variant="text" style={{ margin: '-11px 0 0 8px' }} />
							)}
						</div>
					)
				}
				{...accordionProps}
			/>
			{showOpenLink ? getOpenLinkButton() : null}
		</div>
	))(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
		'& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
			margin: '9px !important',
		},
		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			transform: 'rotate(180deg)',
		},
		'& .Mui-expanded': {
			background: '#F8FAFC !important',
		},
	}));

	const [currentFragment, setCurrentFragment] = useState<CallTagType | ISearchedWord | null>(null);

	const setCurrentFragmentHandle = (fragment: CallTagType | ISearchedWord | null): void => {
		setCurrentFragment(fragment);
	};

	const [metaTags, setMetaTags] = useState<MetaTags>();

	const getMetaTags = async () => {
		if (currentUser) {
			const { clientTags, operatorTags } = currentUser;

			const partialUserData = {
				clientTags: clientTags || [],
				operatorTags: operatorTags || [],
			};
			setMetaTags(partialUserData);
		}
	};

	useEffect(() => {
		if (currentUser && !['admin', 'manager'].includes(currentUser.role)) getMetaTags();
	}, [currentUser]);

	const createNewMetaTags = (
		metaTagsArr: MetaTags | undefined,
		newCallInfoTags: CallTagType[] | undefined,
	): NewMetaTagsType => {
		const createTagObjects = (tags: string[] | undefined, сallInfoTags: CallTagType[] | undefined) =>
			tags?.reduce((acc: TagWithValue[], tagName) => {
				const foundTag = сallInfoTags?.find((t) => t.name === tagName);
				if (foundTag) {
					acc.push({ name: tagName, value: foundTag.value, visible: foundTag.visible, id: foundTag.id });
				}
				return acc;
			}, []);

		return {
			clientTags: createTagObjects(metaTagsArr?.clientTags, newCallInfoTags),
			operatorTags: createTagObjects(metaTagsArr?.operatorTags, newCallInfoTags),
		};
	};

	const newMetaTags = createNewMetaTags(metaTags, callInfo?.tags);

	const filterCallInfoTags = (
		callInfoArg: CallInfoType | null,
		newMetaTagsArg: NewMetaTagsType,
	): CallInfoType | null => {
		if (!callInfoArg || !callInfoArg.tags) return callInfo;

		// Объединяем все теги из newMetaTags для удобства проверки
		const usedTags = [...(newMetaTagsArg.clientTags || []), ...(newMetaTagsArg.operatorTags || [])].map(
			(tag) => tag.name,
		);

		// Фильтруем теги callInfo, исключая уже использованные и скрытые
		const filteredTags = callInfoArg.tags.filter((tag) => !usedTags.includes(tag.name));

		// Возвращаем новый объект callInfo с отфильтрованными тегами
		return { ...callInfoArg, tags: filteredTags };
	};

	// Использование функции для получения объекта callInfo с отфильтрованными тегами
	const updatedCallInfo = filterCallInfoTags(callInfo, newMetaTags);

	const integrateDealTagsWithCallInfo = (callInfoArg: CallInfoType | null): CallInfoType | null => {
		if (!callInfoArg) return callInfoArg;

		const dealTags = callInfo?.dealTags?.map((tag: any) => tag) || [];

		return { ...callInfoArg, dealTags };
	};

	// Использование функции для интеграции dealTags в updatedCallInfo
	const updatedCallInfoWithDealTags = integrateDealTagsWithCallInfo(updatedCallInfo);

	// разделяет теги на теги и фрагменты
	const tagsAndFragmentsSeparator = () => {
		const tags = [];
		const fragments: { tag: CallTagType; count: number }[] = [];
		const dealTags: any = [];

		if (updatedCallInfoWithDealTags) {
			for (let i = 0; i < updatedCallInfoWithDealTags.tags?.length; i++) {
				// фрагменты
				if (updatedCallInfoWithDealTags.tags[i].fragment) {
					const currentFragmentTag = updatedCallInfoWithDealTags.tags[i];
					const forCallCurrentFragment = fragments.find(
						(fragment) =>
							fragment.tag.name === currentFragmentTag.name &&
							fragment.tag?.value === currentFragmentTag?.value,
					);

					if (forCallCurrentFragment) {
						const fragmentIndex = fragments.indexOf(forCallCurrentFragment);
						fragments[fragmentIndex] = {
							tag: forCallCurrentFragment.tag,
							count: (forCallCurrentFragment.count += 1),
						};
					} else {
						fragments.push({ tag: currentFragmentTag, count: 1 });
					}
				} else {
					// теги
					tags.push(updatedCallInfoWithDealTags.tags[i]);

					if (updatedCallInfoWithDealTags?.dealTags?.[i]) {
						dealTags.push(updatedCallInfoWithDealTags?.dealTags?.[i]);
					}
				}
			}
		}
		return { tags, fragments, dealTags, searchedWords: callInfo?.textSearchResult };
	};
	const tagsAndFragmentsArray = tagsAndFragmentsSeparator();

	useEffect(() => {
		function onResize(): void {
			if (accordionSummary.current && audioDivRef.current && tagsDivRef.current && paramsDivRef.current) {
				tagsDivRef.current.style.top = `${accordionSummary.current.offsetHeight + 76}px`;
				paramsDivRef.current.style.top = `${accordionSummary.current.offsetHeight + 76}px`;
			}
		}

		onResize();
		window.addEventListener('resize', onResize);
		return (): void => {
			window.addEventListener('resize', onResize);
		};
	});

	const declinationArray: string[] = [
		translate('score_1', language),
		translate('score_24', language),
		translate('score_many', language),
	];

	const stopPropagation = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();
	};

	const methods = { setCurrentFragmentHandle, onClick, stopPropagation };

	const data = {
		updatedCallInfo,
		newMetaTags,
		tagsAndFragmentsArray,
		accordion,
		accordionSummary,
		declinationArray,
		currentFragment,
		audioDivRef,
		tagsDivRef,
		paramsDivRef,
		hasAccessToPhone: callInfo?.communicationType === 'call' && accessRights?.phone_number,
		hasAccessToTags: accessRights?.tags,
		AccordionSummary,
	};

	return { methods, data };
};
