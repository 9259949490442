import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getConvertedUserValue, getUsersForImport } from 'utils/user';
import { getUsersWithoutChildren } from 'store/user/user.slice';
import { OptionType } from 'components/common/Selects/MultiValueSelect/multiValuesSelect';
import { ChildUserType } from 'store/user/user.types';

const useUsersOptions = () => {
	const dispatch = useAppDispatch();
	const { user, childUser, onlyUsers } = useAppSelector((state) => state.user);

	const [SimpleUserSelectOptions, setSimpleUserSelectOptions] = useState<OptionType[]>([]);

	const fetchUsers = async () => {
		await dispatch(getUsersWithoutChildren());
	};

	useEffect(() => {
		if (!onlyUsers) fetchUsers();
		const convertedUsers = getUsersForImport(onlyUsers, childUser?.id || '');
		setSimpleUserSelectOptions(convertedUsers);
	}, [onlyUsers]);

	const optionsUserChangerCreator = (childUsers: ChildUserType[] | null) => {
		const result = [];
		if (childUsers) {
			for (let i = 0; i < childUsers.length; i++) {
				if (['user', 'admin', 'manager'].find((role) => role === childUsers[i].role)) {
					result.push({
						value: childUsers[i].id,
						label: childUsers[i].name ? childUsers[i].name : childUsers[i].login,
					});
				}
			}
			return result;
		}
		return [];
	};

	const HeaderUserSelectOptions = optionsUserChangerCreator(onlyUsers);

	const convertedValue = getConvertedUserValue(HeaderUserSelectOptions, childUser, user);

	return { SimpleUserSelectOptions, HeaderUserSelectOptions, convertedValue };
};

export default useUsersOptions;
