import { FC, useState } from 'react';
import { Typography } from '@mui/material';
import CustomSwitch from '../CustomSwitch/CustomSwitch';
import cls from './styles.module.scss';

export interface TextWithSwitchProps {
	text: string;
	onSwitchChange: (checked: boolean) => void;
	initialChecked?: boolean;
	align?: 'default' | 'space-between';
	textClassName?: string;
}

const TextWithSwitch: FC<TextWithSwitchProps> = (props) => {
	const { text, onSwitchChange, initialChecked, align, textClassName } = props;
	const [switchChecked, setSwitchChecked] = useState(false);

	const handleSwitchChecked = () => {
		const newSwitchChecked = !switchChecked;
		setSwitchChecked(newSwitchChecked);
		onSwitchChange(newSwitchChecked);
	};

	return (
		<div className={`${cls.root} ${align === 'space-between' ? cls.spaceBetween : ''}`}>
			<Typography className={`${cls.text} ${textClassName || ''}`}>{text}</Typography>
			<CustomSwitch checked={initialChecked || false} onChecked={handleSwitchChecked} />
		</div>
	);
};

export default TextWithSwitch;
