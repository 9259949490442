import { TagFormat } from '../types';

export const createOptionsSelect = (state: string): TagFormat => {
	let local: TagFormat = { value: '', label: '', id: null };
	if (state) {
		local = { value: state, label: state, id: null };
	}
	return local;
};

// VEL - value equal label
export const createOptionsVEL = (state: string[] | undefined): TagFormat[] => {
	if (state) {
		return state.map((item) => ({ value: item, label: item, id: null }));
	}
	return [];
};
