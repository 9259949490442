import React, { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { IconQuestion } from 'components/Icons/IconQuestion';
import Tooltip, { TooltipPropsType } from 'components/common/Tooltip/Tooltip';

import s from './styles.module.scss'; // Путь к вашим стилям
import EyeOpenIcon from '../icons/general/EyeOpenIcon/EyeOpenIcon';
import EyeClosedIcon from '../icons/general/EyeClosedIcon/EyeClosedIcon';

export interface UniversalInputProps {
	name?: string;
	inputType?: string; // Это может быть любой валидный тип для HTML5 input(text, password, checkbox и т.д.).
	testId?: string | number | null;
	label?: string;
	placeholder?: string;
	tooltipTitle?: string;
	tooltipTheme?: 'dark' | 'white';
	tooltipPlacement?: TooltipPropsType['placement'];
	autoComplete?: 'on' | 'off';
	handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	value?: string | number | readonly string[] | undefined;
	inputStyle?: 'outlined' | 'filled' | 'plain';
	width?: string;
	height?: string;
	borderColor?: string;
	bcColor?: string;
	color?: string;
	margin?: string;
	classNameWrapper?: string;
	classNameLabel?: string;
	classNameInput?: string;
	disabled?: boolean;
	required?: boolean;
	readOnly?: boolean;
	autoFocus?: boolean;
	iconPosition?: 'start' | 'end';
	icon?: React.ReactNode;
	isPassword?: boolean;
	isFocused?: boolean;
	min?: string | number;
	max?: string | number;
}

const UniversalInput: FC<UniversalInputProps> = (props) => {
	const {
		name,
		inputType = 'text',
		required,
		testId,
		inputStyle,
		classNameWrapper,
		classNameLabel,
		classNameInput,
		label,
		placeholder,
		tooltipTitle,
		tooltipTheme,
		tooltipPlacement,
		value,
		autoComplete,
		autoFocus,
		width,
		height,
		bcColor,
		borderColor,
		color,
		margin,
		disabled,
		handleChange,
		readOnly,
		iconPosition,
		icon,
		isPassword,
		isFocused = false,
		min,
		max,
	} = props;
	const inputRef = useRef<HTMLInputElement>(null);
	const [passwordShown, setPasswordShown] = useState(false);
	const [inputValue, setInputValue] = useState(value);

	const withPassword = passwordShown ? inputType : 'password';
	const type = isPassword ? withPassword : inputType;

	const togglePassword = (): void => {
		setPasswordShown(!passwordShown);
	};

	useEffect(() => {
		if (isFocused && inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	useEffect(() => {
		setInputValue(value ?? '');
	}, [value]);

	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
		handleChange?.(e);
	};

	return (
		<div className={cn(s.universalInputWrapper, classNameWrapper)} data-testid={testId}>
			{label && (
				<label htmlFor={name} className={cn(s.label, classNameLabel)}>
					{label}
					{required && <span className={s.fieldRequired}>*</span>}
					{tooltipTitle && (
						<Tooltip
							title={tooltipTitle}
							placement={tooltipPlacement || 'top'}
							background={tooltipTheme || 'white'}
						>
							<IconQuestion />
						</Tooltip>
					)}
				</label>
			)}

			<div className={s.inputWrapper}>
				{iconPosition === 'start' && <div className={s.iconStart}>{icon}</div>}

				<input
					ref={inputRef}
					type={type}
					name={name}
					id={name}
					className={cn(s.universalInput, classNameInput, {
						[`${s.outlined}`]: inputStyle === 'outlined',
						[`${s.filled}`]: inputStyle === 'filled',
						[`${s.plain}`]: inputStyle === 'plain',
						[`${s.disabled}`]: disabled,
					})}
					style={{
						backgroundColor: bcColor || '',
						width: width || '',
						height: height || '',
						borderColor: borderColor || '',
						margin: margin || '',
						color: color || '',
						padding: iconPosition === 'start' ? '6px 10px 8px 30px' : '6px 30px 8px 10px',
					}}
					value={inputValue}
					onChange={onChangeHandler}
					placeholder={placeholder}
					autoFocus={autoFocus}
					autoComplete={autoComplete}
					readOnly={readOnly}
					disabled={disabled}
					min={min ?? ''}
					max={max ?? ''}
				/>

				{iconPosition === 'end' && <div className={s.iconEnd}>{icon}</div>}

				{isPassword && (
					<div onClick={togglePassword} className={s.iconEnd}>
						{passwordShown ? <EyeOpenIcon pathFill="#738094" /> : <EyeClosedIcon pathFill="#738094" />}
					</div>
				)}
			</div>
		</div>
	);
};

export default UniversalInput;
