import React from 'react';
import cn from 'classnames';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BlockBox } from 'components/common';
import { commonTestIds } from 'tests/testid';
import variables from 'styles/variables.scss';

type AuthModalWindowPropsType = {
	isMWOpen: boolean;
	handleMWClose: () => void;
	text: string | React.ReactNode;

	blockFotter?: React.ReactNode;

	width?: string;
	height?: string;
	overflow?: string;
	overflowMW?: string;
	positionRight?: boolean;
	showCloseBtn?: boolean;
	classNameRoot?: string;
	classNameChildren?: string;
	classNameTitle?: string;
	classNameTitleText?: string;
	boxHeight?: string;
	testId?: string;
};

export const ModalWindow: React.FC<AuthModalWindowPropsType> = ({
	isMWOpen,
	handleMWClose,

	text,
	children,

	blockFotter,

	width,
	height,
	overflow = 'inherit',
	overflowMW,
	positionRight,
	showCloseBtn = true,
	classNameRoot,
	classNameChildren,
	classNameTitle,
	classNameTitleText,
	boxHeight = 'auto',
	testId = commonTestIds.modalWindow
}) => {
	const useStyles = makeStyles({
		mwWrapper: {
			height: '100% !important',
			'& .MuiPaper-root:not(.MuiAccordion-root)': {
				overflow: overflowMW ?? 'inherit',
				borderRadius: '10px !important',
				height: height ? `${height} !important` : 'auto',
				width: width ? `${width} !important` : '400px',
				maxWidth: width ? `${width} !important` : '600px',
				margin: '0',
			},
		},
		mwRoot: {
			padding: '24px !important',
		},
		mwWrapperRight: {
			height: '100% !important',
			'& .MuiDialog-container': {
				height: '100vh !important',
				justifyContent: 'flex-end !important',
			},
			'& .MuiPaper-root': {
				borderRadius: '0px !important',
				height: '100vh !important',
				maxHeight: '100% !important',
				maxWidth: '33% !important',
				width: '33% !important',
				margin: '0',
			},
		},
		mwTitle: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			position: 'relative',
		},
		mwTitleText: {
			fontWeight: '700 !important',
		},
		mwFooter: {
			padding: '16px 24px',
		},
		mwChildren: {
			height: '100%',
			maxHeight: '100%',
			paddingRight: '16px',
			'&::-webkit-scrollbar': {
				height: '8px',
				backgroundColor: '#fff',
				width: '8px',
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundСolor: variables.gray_5,
				background: variables.gray_5,
				borderRadius: '14px',
			},
		},
	});
	const classes = useStyles();

	return (
		<Dialog
			data-testid={testId}
			className={cn({
				[classes.mwWrapperRight]: positionRight,
				[classes.mwWrapper]: !positionRight,
			})}
			open={isMWOpen}
			onClose={handleMWClose}
			style={{ zIndex: '100' }} // если поломается где-то zindex, то убрать
		>
			<BlockBox overflow={overflow} height={boxHeight} className={cn(classes.mwRoot, classNameRoot)}>
				{/* Шапка */}
				<div className={cn(classes.mwTitle, classNameTitle)}>
					<Typography className={cn(classes.mwTitleText, classNameTitleText)}>{text}</Typography>
					{showCloseBtn && (
						<IconButton onClick={handleMWClose} data-testid="closePopupButton">
							<CloseIcon style={{ color: '#000000', width: '15px', height: '15px' }} />
						</IconButton>
					)}
				</div>

				<div className={cn(classes.mwChildren, classNameChildren)}>{children}</div>
			</BlockBox>
			{/* футер, если есть */}
			{blockFotter && <div className={classes.mwFooter}>{blockFotter}</div>}
		</Dialog>
	);
};

export default ModalWindow;
