import React from 'react';
import classNames from 'classnames';
import BlockBox from 'components/common/BlockBox/BlockBox';
import styles from './styles.module.scss';


interface IOwnProps {
	headerLeft?: React.ReactElement;
	headerRight?: React.ReactElement;
	padding?: string;
	width?: string;
	headerClassName?: string;
	containerClassName?: string;
	bodyClassName?: string;
}

const CardContainer: React.FC<IOwnProps> = ({
	children,
	headerLeft,
	headerRight,
	padding,
	width,
	headerClassName,
	containerClassName,
	bodyClassName,
}) => (
	<BlockBox padding={padding || '0px 32px 32px'} width={width}>
		<div className={classNames(styles.container, containerClassName)}>
			<div className={classNames(styles.header, headerClassName)}>
				{headerLeft && <div>{headerLeft}</div>}
				{headerRight && <div>{headerRight}</div>}
			</div>
			<div className={classNames(styles.body, bodyClassName)}>{children}</div>
		</div>
	</BlockBox>
);

export default CardContainer;
