import React, { useEffect, FC, memo } from 'react';
import { LoadingButton } from '@mui/lab';
import { Form, Formik,  FormikState, useFormikContext } from 'formik';
import { CustomCheckbox } from 'components/common';
import { translate } from 'localizations';
import { UserType } from 'store/user/user.types';
import { LangType } from 'store/lang/lang.slice';
import DynamicForm from 'widgets/DynamicForm/DynamicForm';
import styles from './STT.module.scss';
import STTSelect from './components/STTSelect/STTSelect';
import { SttValues, SttValuesLocal, SttValuesForRequest, FormikProps } from './namespaces';
import { validationSchema } from './schemas/sttSchema';
import { sttDefault } from './consts';
import WithSTTSkeleton from './components/STTSkeleton/WithSTTSkeleton';
import { ContainedSelectPropsType } from 'components/common/Selects/ContainedSelect/ContainedSelect';
import useStt from './hooks/useStt';

interface FormObserverProps {
	sttOptions: Record<string, string | number>;
	resetForm: (nextState?: Partial<FormikState<SttValuesLocal>>) => void;
	defaultValues: { values: SttValuesLocal };
	handleChange: ((values: { values: SttValues; isDirty: boolean }) => void) | undefined;
	isDirty: boolean;
}
const FormObserver: FC<FormObserverProps> = ({ sttOptions, resetForm, defaultValues, handleChange, isDirty }) => {
	const { values } = useFormikContext<SttValuesLocal>();

	// устанавливаем свойства, сохраненные у юзера
	const setUserSTTSettings = async (defaultValues: { values: SttValuesLocal }) => {
		resetForm(defaultValues);
	};

	useEffect(() => {
		handleChange &&
			handleChange({
				values: {
					...values,
					sttOptionsMap: sttOptions,
				},
				isDirty,
			});
	}, [values, sttOptions]);

	useEffect(() => {
		if (defaultValues) setUserSTTSettings(defaultValues);
	}, [defaultValues]);

	return null;
};

export interface STTProps {
	user: UserType | null;
	language: LangType | string;
	onSubmitFunction: (values: SttValuesForRequest) => void;
	buttonTitle?: string;
	handleChange?: (values: { values: SttValues; isDirty: boolean }) => void;
	isContentFull?: boolean;
	showErrors?: boolean;
	menuPlacement?: ContainedSelectPropsType["menuPlacement"];
}

// TODO: убрать ts-ignore

const STT: FC<STTProps> = ({ user, language, onSubmitFunction, buttonTitle, handleChange, isContentFull, showErrors, menuPlacement}) => {
	const { data, methods} = useStt(user, language, onSubmitFunction);
	const { contentIsLoading, STTLanguagesList,	STTEnginesList,	STTModelsList, sttOptions, convertedOptions, defaultValuesOfUser } = data;
	const { getUserSTTSettings, handleSubmit, handleLanguage,	handleEngine,	handleModel, handleChangeOptions,	validateInputNumber, validateInputNumberValue } = methods;
	
	useEffect(() => {
		if (user) getUserSTTSettings();
	}, [user]);

	return (
		<Formik
			initialValues={sttDefault}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
			validateOnChange={false}
			validateOnBlur={false}
		>
			{({ values, errors, setFieldValue, setErrors, resetForm }: FormikProps) => (
				<Form>
					<WithSTTSkeleton showChildren={!contentIsLoading}>
						<div className={isContentFull ? styles.sttContentFull: styles.sttContent}>
							<div className={styles.sttSelects}>
								<div className={styles.sttSelect}>
									<STTSelect
										title={translate('sttLanguage', language)}
										placeholder={translate('sttSetLang', language)}
										isDisabled={false}
										tooltip={translate('sttSetLangTooltip', language)}
										onSelectionChange={async (e) => {
											await handleLanguage(e, values, setFieldValue, setErrors);
										}}
										options={STTLanguagesList}
										value={values.sttLanguage}
										errors={errors.sttLanguage}
										language={language}
										testId={'stt_language'}
										showErrors={showErrors}
										menuPlacement={menuPlacement}
									/>
								</div>
								<div className={styles.sttSelect}>
									<STTSelect
										title={translate('sttEngine', language)}
										placeholder={translate('sttSetEngine', language)}
										isDisabled={!values.sttLanguage?.value}
										onSelectionChange={async (e) => {
											await handleEngine(e, values, setFieldValue, setErrors);
										}}
										options={STTEnginesList}
										value={values.sttEngine}
										errors={errors.sttEngine}
										language={language}
										testId={'stt_engine'}
										showErrors={showErrors}
										menuPlacement={menuPlacement}
									/>
								</div>
								<div className={styles.sttSelect}>
									<STTSelect
										title={translate('sttModel', language)}
										placeholder={translate('sttSetModel', language)}
										isDisabled={!values.sttEngine?.value}
										onSelectionChange={(e) => {
											handleModel(e, setFieldValue, setErrors);
										}}
										options={STTModelsList}
										value={values.sttModel}
										errors={errors.sttModel}
										language={language}
										testId={'stt_model'}
										showErrors={showErrors}
										menuPlacement={menuPlacement}
									/>
								</div>
							</div>
							<p className={styles.order}>{translate('sttOrder', language)}</p>

							{sttOptions && Boolean(Object.keys(sttOptions).length) && (
								<DynamicForm
									schema={sttOptions}
									onChange={handleChangeOptions}
									validateInputNumber={validateInputNumber}
									validateInputNumberValue={validateInputNumberValue}
								/>
							)}

							<CustomCheckbox
								id="sttEconomize"
								theme="black"
								testid={'stt_economize'}
								tooltipTheme="dark"
								sizeCheckbox="overall"
								tooltipPlacement="right"
								checked={values.sttEconomize}
								rootClassname={styles.sttEconomize}
								style={{ marginRight: '10px' }}
								tooltip={translate('sttEconomize_tooltip', language)}
								label={translate('sttEconomize', language)}
								onChange={(e) => {
									setFieldValue('sttEconomize', e.target.checked);
								}}
							/>

							<FormObserver
								sttOptions={convertedOptions}
								resetForm={resetForm}
								defaultValues={defaultValuesOfUser}
								handleChange={handleChange}
								isDirty={!values.sttLanguage || !values.sttEngine || !values.sttModel}
							/>
						</div>
					</WithSTTSkeleton>

					{buttonTitle && (
						<div className={styles.controlButtonsBlock}>
							<LoadingButton
								color="primary"
								variant="contained"
								type="submit"
								disabled={!values.sttLanguage || !values.sttEngine || !values.sttModel}
								style={{
									textTransform: 'none',
								}}
							>
								{buttonTitle}
							</LoadingButton>
						</div>
					)}
				</Form>
			)}
		</Formik>
	);
};

export default memo(STT);
