import { CircularPreloader, CustomCheckbox } from 'components/common';
import { translate } from 'localizations';
import { RouteParams } from 'pages/Settings/types';
import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ExportParams } from 'store/calls/calls.types';
import { UniversalButton } from 'shared/ui';

import cls from './styles.module.scss';

interface ExportModalInnerProps {
	toggleSttExportModal: () => void;
	tags: ExportParams | null;
	onIconClick: (action: string, columns: string[]) => void;
	actionName: string;
}

const ExportModalInner: FC<ExportModalInnerProps> = (props) => {
	const { toggleSttExportModal, tags, onIconClick, actionName } = props;
	const { lang } = useParams<RouteParams>();

	const [selectedTags, setSelectedTags] = useState<string[]>([]);

	useEffect(() => {
		// Инициализация выбранных тегов по умолчанию
		if (tags) {
			const initialTags = [
				...tags.tags_without_values.map((tag) => tag.key),
				...tags.tags_with_values.map((tag) => tag.key),
				...tags.other_params.map((tag) => tag.key),
			];
			setSelectedTags(initialTags);
		}
	}, [tags]);

	const handleCheckboxChange = (key: string) => {
		setSelectedTags((prevSelectedTags) =>
			prevSelectedTags.includes(key)
				? prevSelectedTags.filter((tagKey) => tagKey !== key)
				: [...prevSelectedTags, key],
		);
	};

	const handleExport = () => {
		onIconClick(actionName, selectedTags);
	};

	const commonLabelStyle = {
		color: '#2F3747',
		fontFamily: 'Inter',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '22px',
	};

	if (!tags) {
		return (
			<div className={cls.preloader}>
				<CircularPreloader />
			</div>
		);
	}

	return (
		<div className={cls.root}>
			{tags?.tags_without_values && tags?.tags_without_values?.length > 0 && (
				<div className={cls.section}>
					<div className={cls.title}>{translate('tagsWithoutValues', lang)}</div>
					<div className={cls.tagsContainer}>
						{tags?.tags_without_values.map((tag) => (
							<CustomCheckbox
								key={tag.key}
								testid={tag.key}
								className={cls.checkbox}
								checked={selectedTags.includes(tag.key)}
								onChange={() => handleCheckboxChange(tag.key)}
								disabled={false}
								label={tag.title}
								labelStyle={commonLabelStyle}
							/>
						))}
					</div>
					<span className={cls.separator} />
				</div>
			)}

			{tags?.tags_with_values && tags?.tags_with_values?.length > 0 && (
				<div className={cls.section}>
					<div className={cls.title}>{translate('tagsWithValues', lang)}</div>
					<div className={cls.tagsContainer}>
						{tags?.tags_with_values.map((tag) => (
							<CustomCheckbox
								key={tag.key}
								testid={tag.key}
								className={cls.checkbox}
								checked={selectedTags.includes(tag.key)}
								onChange={() => handleCheckboxChange(tag.key)}
								disabled={false}
								label={tag.title}
								labelStyle={commonLabelStyle}
							/>
						))}
					</div>
					<span className={cls.separator} />
				</div>
			)}

			{tags?.other_params && tags?.other_params?.length > 0 && (
				<div className={cls.section}>
					<div className={cls.title}>{translate('otherParams', lang)}</div>
					<div className={cls.tagsContainer}>
						{tags?.other_params.map((tag) => (
							<CustomCheckbox
								key={tag.key}
								testid={tag.key}
								className={cls.checkbox}
								checked={selectedTags.includes(tag.key)}
								onChange={() => handleCheckboxChange(tag.key)}
								disabled={false}
								label={tag.title}
								labelStyle={commonLabelStyle}
							/>
						))}
					</div>
				</div>
			)}

			<div className={cls.buttonsBlock}>
				<UniversalButton
					text={translate('cancelButton', lang)}
					handleClick={toggleSttExportModal}
					buttonStyle="ghost"
				/>
				<UniversalButton text={translate('export', lang)} handleClick={handleExport} buttonStyle="filled" />
			</div>
		</div>
	);
};

export default ExportModalInner;
