import { FC } from 'react';

interface PlusIconProps {
	width?: string;
	height?: string;
	color?: string;
	props?: any;
}

const PlusIcon: FC<PlusIconProps> = ({ width = '12', height = '12', color = '#73D13D', props }) => (
	<svg
		width={width}
		height={height}
		viewBox={`0 0 ${width} ${height}`}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.99941 0C6.43163 0 6.78201 0.350386 6.78201 0.782609V11.2174C6.78201 11.6496 6.43163 12 5.99941 12C5.56718 12 5.2168 11.6496 5.2168 11.2174V0.782609C5.2168 0.350386 5.56718 0 5.99941 0Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0 6.00038C0 5.56816 0.350386 5.21777 0.782609 5.21777H11.2174C11.6496 5.21777 12 5.56816 12 6.00038C12 6.4326 11.6496 6.78299 11.2174 6.78299H0.782609C0.350386 6.78299 0 6.4326 0 6.00038Z"
			fill={color}
		/>
	</svg>
);

export default PlusIcon;
