import React, { FC, ReactElement, useEffect, useState } from 'react';
import Select from 'react-select';
import { OptionType } from 'components/common/Selects/MultiValueSelect/multiValuesSelect';
import { createTextSelectStyles } from './styles.jss';
import { CustomControl, CustomMenuList, CustomOption } from './customComponents';

interface AddOptionSelectProps {
	value: OptionType | null;
	options: OptionType[];
	selected: OptionType[];
	setSelected: (option: OptionType[]) => void;
	customControl: ReactElement;
	width?: string;
	ifArrowColor?: string;
	menuPosition?: 'left' | 'right';
}
const AddOptionSelect: FC<AddOptionSelectProps> = ({
	value = null,
	options,
	selected,
	setSelected,
	customControl,
	width,
	ifArrowColor,
	menuPosition = 'left',
}) => {
	const customSelectStyles = createTextSelectStyles({ width, menuPosition });

	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

	const handleSelectOption = (option: OptionType) => {
		const isActive = !!(selected.find(item => item.value === option.value));
		let newSelected;
		if (isActive) {
			newSelected = selected.filter(item => item.value !== option.value);
		} else {
			newSelected = [...selected, option];
		}
		setSelected(newSelected);
	};

	// костыль за закрытия меню
	useEffect(() => {
		document.addEventListener('mousedown', () => setIsMenuOpen(false));
		return () => {
			document.removeEventListener('mousedown', () => setIsMenuOpen(false));
		};
	}, []);

	return (
		<Select
			styles={customSelectStyles}
			options={options}
			value={value}
			openMenuOnFocus
			menuIsOpen={isMenuOpen}
			isClearable
			closeMenuOnSelect={false}
			isSearchable={false}
			hideSelectedOptions={false}
			menuPlacement="auto"
			components={{
				IndicatorsContainer: () => null,
				// eslint-disable-next-line react/no-unstable-nested-components
				Control: (controlProps) => (
					<CustomControl
						{...controlProps}
						isMenuOpen={isMenuOpen}
						ifArrowColor={ifArrowColor}
						handleSelectClick={() => setIsMenuOpen(!isMenuOpen)}
						iconPosition="left"
						customControl={customControl}
					/>
				),
				MenuList: CustomMenuList,
				// eslint-disable-next-line react/no-unstable-nested-components
				Option: (optionProps) => (
					<CustomOption selected={selected} handleSelectOption={handleSelectOption} {...optionProps} />
				),
			}}
		/>
	)
};

export default AddOptionSelect;
