import { FC } from 'react';
import { Typography } from '@mui/material';
import { MultiValueSelect } from 'components/common';
import { MultiValueSelectValueTypes } from '../../hooks/useTagsFilter';

interface TagsFilterselectProps {
	titleClassName?: string;
	title: string;
	testId: string;
	tagNames: string[];
	searchHideTagsInitialValues: MultiValueSelectValueTypes[];
	handleSearchHideTagsChange: (value: MultiValueSelectValueTypes[]) => void;
}

const TagsFilterSelect: FC<TagsFilterselectProps> = (props) => {
	const { titleClassName, title, testId, tagNames, searchHideTagsInitialValues, handleSearchHideTagsChange } = props;

	return (
		<>
			<Typography className={titleClassName}>{title}</Typography>
			<MultiValueSelect
				testId={testId}
				criteriaKey=""
				width="100%"
				selectType="multiString"
				options={tagNames.map((tag) => ({ value: tag, label: tag }))}
				value={searchHideTagsInitialValues}
				valueHandler={handleSearchHideTagsChange}
			/>
		</>
	);
};

export default TagsFilterSelect;
