import React from 'react';
import { Skeleton } from '@mui/material';
import styles from './STTSkeleton.module.scss';

const STTSkeleton = () => (
	<div className={styles.sttContent}>
		<Skeleton width={250} height={68} />
		<div className={styles.usersInfoLine}>
			<Skeleton width={250} height={68} />
			<Skeleton width={250} height={68} />
		</div>
		<Skeleton width={250} height={68} />
		<Skeleton width={250} height={68} />
	</div>
);

export default STTSkeleton;
