/* eslint-disable react/no-array-index-key */
import { FC, useEffect, useState } from 'react';
import { ContainedSelect } from 'components/common';
import { Typography } from '@mui/material';
import { optionsForSelects, optionsForSelectsWithType } from 'pages/Notifications/types/NotificationsTypes';
import { BasketIcon } from 'components/Icons/BasketIcon';
import { translate } from 'localizations';
import UniversalButton from '../../Buttons/UniversalButton/UniversalButton';
import { TextArea } from 'components/shared/Forms/TextArea/TextArea';
import { useDynamicSelects } from '../hooks/useDynamicSelects';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';

import cls from './styles.module.scss';

interface DynamicSelectsProps {
	blockTitle: string;
	leftSelectTitle: string;
	rightSelectTitle: string;
	options?: optionsForSelectsWithType[];
	tagNamesWithValues?: string[];
	initialValue?: any;
	onChange?: (value: any) => void;
}

export interface SelectDataItem {
	leftSelected: any;
	rightSelected: any;
	leftOptions: optionsForSelects[] | undefined;
	rightOptions: optionsForSelects[] | undefined;
}

const DynamicSelects: FC<DynamicSelectsProps> = (props) => {
	const {
		blockTitle,
		leftSelectTitle,
		rightSelectTitle,
		options = [],
		tagNamesWithValues,
		initialValue,
		onChange,
	} = props;

	const { dynamicSelectsData, dynamicSelectsMathods } = useDynamicSelects({
		options,
		initialValue,
		onChange,
		tagNamesWithValues,
	});
	const { selectData, lang, getAmoFillableCustomFieldsError, getBitrixFillableCustomFieldsError } =
		dynamicSelectsData;
	const { handleSelectChange, handleTextareaChenge, handleRemove, handleAdd } = dynamicSelectsMathods;

	const [hasError, setHasError] = useState<FetchBaseQueryError | SerializedError | undefined>(undefined);

	useEffect(() => {
		setHasError(getAmoFillableCustomFieldsError || getBitrixFillableCustomFieldsError);
	}, [getAmoFillableCustomFieldsError, getBitrixFillableCustomFieldsError, options]);

	return (
		<div className={cls.root}>
			<Typography className={cls.blockTitle}>{blockTitle}</Typography>

			{options.length && !hasError ? (
				<>
					{selectData?.length > 0 && (
						<div className={cls.selectBlockHeader}>
							<div className={cls.leftSelectTitle}>{leftSelectTitle}</div>
							<div className={cls.rightSelectTitle}>{rightSelectTitle}</div>
						</div>
					)}

					{selectData?.map((data, index) => (
						<div key={`${data.leftSelected.value}-${index}`} className={cls.selectBlock}>
							<div className={cls.leftSelect}>
								<ContainedSelect
									width="100%"
									onSelectChange={(value) => handleSelectChange(value, index, true)}
									value={data.leftSelected}
									options={data.leftOptions || []}
									className={`${cls.select}-${data.leftSelected.value}`}
								/>
							</div>

							{data.leftSelected.value !== '' ? (
								<>
									<div className={cls.equal}>=</div>

									<div className={cls.rightSelect}>
										{data.rightOptions ? (
											<ContainedSelect
												width="100%"
												onSelectChange={(value) => handleSelectChange(value, index, false)}
												value={
													data.rightSelected || {
														label: translate('selectValue', lang),
														value: translate('selectValue', lang),
													}
												}
												options={data.rightOptions || []}
												className={`${cls.select}-${data.rightSelected.value}`}
											/>
										) : (
											<TextArea
												className={cls.textarea}
												dataTestid={`${data.leftSelected.value}-${index}`}
												rows={1}
												onChange={handleTextareaChenge}
												value={data.rightSelected.label}
												placeholder={translate('enterValue', lang)}
											/>
										)}
									</div>
								</>
							) : null}

							<div className={cls.deletBtn}>
								<UniversalButton
									text=""
									buttonStyle="plain"
									icon={<BasketIcon color="#FF0000" width="22" height="22" />}
									handleClick={() => handleRemove(index)}
								/>
							</div>
						</div>
					))}
					<UniversalButton
						text={`+ ${translate('append', lang)}`}
						buttonStyle="plain"
						handleClick={handleAdd}
					/>
				</>
			) : (
				<div className={cls.failedToRetrieveData}>{translate('failedToRetrieveData', lang)}</div>
			)}
		</div>
	);
};

export default DynamicSelects;
