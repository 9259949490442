import { useAppSelector } from './redux';
import { useCurrentUser } from './index';

/**
 * возвращает пользователя, которого нужно показывать в профиле
 * для админа и менеджера - пользователя, выбранного в селекте пользователей (админ бар) или они их самих
 * для компании (user) - саму компанию
 * для сотрудника (operator) - самого сотрудника
 */
const useUserForProfile = () => {
	// пользователь, под которым залогинились
	const { loggedUser } = useAppSelector((state) => state.user);

	const canChangeUser = loggedUser?.role === 'admin' || loggedUser?.role === 'manager' || loggedUser?.role === 'user';

	// пользователь, выбранный в селекте пользователей (админ бар)
	// актуально для админа
	// TODO: для менеджера может работать неправильно.
	//  Сейчас пользователь, в которого провалился менеджер записывается в user,
	//  а не в childUser. Это связано с проверкой на adminToken в src/Routes стр. 43
	const selectedUser = useCurrentUser();

	// выбираем пользователя в зависимости от роли
	return canChangeUser ? selectedUser : loggedUser;
};

export default useUserForProfile;
