import { ChangeEvent, FC, useState } from 'react';
import { ContainedSelect } from 'components/common';
import UniversalInput from '../UniversalInput/UniversalInput';

import cls from './InputWithSelect.module.scss';

interface InputWithSelectProps {
	inputName: string;
	inputLabel: string;
	inputStyle: 'outlined' | 'filled' | 'plain';
	inputTooltipTitle: string;
	inputPlaceholder: string;
	inputValue: string;
	inputHandleChange: (e: ChangeEvent<HTMLInputElement>) => void;
	inputBorderColor: string;
	inputRequired: boolean;
	selectValue?: { value: string; label: string };
	setApiMethod?: (value: string) => void;
	options?: { label: string; value: string }[];
}

const InputWithSelect: FC<InputWithSelectProps> = (props) => {
	const {
		inputName,
		inputLabel,
		inputStyle,
		inputTooltipTitle,
		inputPlaceholder,
		inputValue,
		options = [{ label: '', value: '' }],
		inputHandleChange,
		inputBorderColor,
		inputRequired,
		selectValue,
		setApiMethod,
	} = props;

	const [localSelectValue, setLocalSelectValue] = useState(options[0]);

	const handleSelectChange = (value: any) => {
		setLocalSelectValue(value);
		if (setApiMethod) {
			setApiMethod(value.value);
		}
	};

	return (
		<div className={cls.root}>
			<UniversalInput
				name={inputName}
				inputStyle={inputStyle}
				classNameWrapper={cls.inputClassNameWrapper}
				label={inputLabel}
				tooltipTitle={inputTooltipTitle}
				placeholder={inputPlaceholder}
				value={inputValue}
				handleChange={inputHandleChange}
				borderColor={inputBorderColor}
				required={inputRequired}
			/>
			<ContainedSelect
				width="auto"
				options={options}
				value={selectValue || localSelectValue}
				onSelectChange={handleSelectChange}
				controlStyles={{ width: '110px' }}
			/>
		</div>
	);
};

export default InputWithSelect;
