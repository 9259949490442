import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { openSocket } from 'shared/utils/socket';
import { API_URL_WEBSOKET } from 'config';
import { REPORTS_MAKE_CALLS } from 'constans/endpoints';
import { reportsSlice } from 'store';
import { createSnackbarOptions } from 'components/common/Snackbar/Snackbar';
import { translate } from 'localizations';
import { getTokens } from 'utils/tokens';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { RootState } from 'store/store';
import { useAPIResponseToasts } from 'hooks';
import { pathReport, pathReportHash, reportColsLimit } from '../const/const';
import useZeroParameters from './useZeroParameters';
import { requestHandler } from '../ReportsFunctions/MakeDataForRequest';
import useValidateReport from './useValidateReport';

const useMakeReport = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	const { language } = useAppSelector((state: RootState) => state.lang);
	const { activeCriterias } = useAppSelector((state) => state.search.reports);
	const { reportLoadingIsStarted, isReportByHash, activeReport, additionalRows, date } = useAppSelector(
		(state) => state.reports,
	);
	const { user, childUser } = useAppSelector((state) => state.user);

	const handleNotifications = useAPIResponseToasts();

	const { validateEmptyParameters } = useValidateReport(activeReport.cols_group_by, additionalRows);

	const isDateError = date === null;

	const hasUserId = user ? user.id : '_';
	const userId = childUser ? childUser.id : hasUserId;
	const { token } = getTokens();

	const { btnResetParameters } = useZeroParameters();

	const initialStatus = {
		status: '',
		stage_name: '',
		stage_idx: 0,
		done: 0,
		total: 0,
		done_part: 0,
	};

	const [progress, setProgress] = useState<any>(initialStatus);
	const [message, setMessage] = useState('');
	const reportWebSocketRef = useRef<WebSocket | null>(null);

	// Обновляем ref и состояние при открытии сокета
	const openReportSocket = () => {
		const webSoketUrl = `${API_URL_WEBSOKET}${REPORTS_MAKE_CALLS}ws`;
		const socket = openSocket(webSoketUrl);
		reportWebSocketRef.current = socket; // Обновляем ref
		return socket;
	};

	const closeReportLoading = () => {
		dispatch(reportsSlice.actions.setLoading(false));
		dispatch(reportsSlice.actions.setReportLoadingIsStarted(false));
	};

	const handleCloseWebsocket = () => {
		closeReportLoading();
		dispatch(reportsSlice.actions.setReportWebSocket(null));

		if (reportWebSocketRef.current) {
			reportWebSocketRef.current.close();
			reportWebSocketRef.current = null;
		}
	};

	const checkSizeOfReport = (result: any) => {
		if (result?.report_result?.report?.cols?.length > reportColsLimit) {
			dispatch(reportsSlice.actions.setReportTooBig(true));
		} else {
			dispatch(reportsSlice.actions.setReportTooBig(false));
		}
	};

	const validateDateAndEmptyHandler = (isErrorDate: boolean, isValidateEmptyParameters: boolean): void => {
		if (isErrorDate) {
			enqueueSnackbar(
				null,
				createSnackbarOptions({
					time: 2000,
					type: 'error',
					text: `${translate('reportDateError', language)}`,
				}),
			);
		}
		if (isValidateEmptyParameters) {
			enqueueSnackbar(
				null,
				createSnackbarOptions({
					time: 2000,
					type: 'error',
					text: `${translate('reportSetParam', language)}`,
				}),
			);
		}
	};

	// обработка ошибки
	const socketError = (): void => {
		handleCloseWebsocket();
		enqueueSnackbar(
			null,
			createSnackbarOptions({
				time: 3000,
				type: 'error',
				text: `${translate('reportErrorMake', language)}`,
			}),
		);
	};

	// обработка сообщений от вебсокет в реальном времени
	const socketMessage = (result: any, isSaved: boolean): void => {
		switch (result.status) {
			case 'progress':
				setProgress(result);
				break;
			case 'fail':
				handleCloseWebsocket();
				enqueueSnackbar(
					null,
					createSnackbarOptions({
						time: 3000,
						type: 'error',
						text: `${translate('reportResponseError', language)}`,
					}),
				);
				handleCloseWebsocket();

				dispatch(reportsSlice.actions.setReportTooBig(true));
				break;
			case 'diff_fail':
				dispatch(reportsSlice.actions.setDiffReportIsLoaded(false));
				dispatch(reportsSlice.actions.setDiffReportHide(true));

				// показать снекбар об ошибке
				handleNotifications(
					'rejected',
					translate('reportDiffFail', language),
					translate('reportDiffFail', language),
				);
				break;

			// есть не у всех отчетов, приходит после 'done',
			// не важен для первой отрисовки отчета
			case 'diff_done':
				setMessage(translate('reportIsLoaded', language));
				if (result?.diff_report) {
					dispatch(reportsSlice.actions.setDiffReport(result?.diff_report));
					dispatch(reportsSlice.actions.setDiffReportIsLoaded(true));
				}

				handleCloseWebsocket();

				break;
			// Отчет загружен, убираем прогресс бар, вебсокет еще может прислать diff_report
			case 'done':
				if (!isSaved) {
					history.push(
						`/${language}/${userId}/${pathReport}?${pathReportHash}=${result?.report_result?.report_parameters_hash}`,
					);
				}

				const payload = result?.report_result;

				// если у отчета больше 25 столбцов, то не рисуем таблицу,
				// показываем ссылку для скачивания
				checkSizeOfReport(result);

				dispatch(reportsSlice.actions.setCallReport(payload));
				closeReportLoading();
			default:
				break;
		}
	};

	const handleMakeReport = async () => {
		if (!reportLoadingIsStarted) {
			if (isReportByHash) {
				if (!activeReport) {
					history.push(`/${language}/404`);
				}
			}

			const isValidateEmptyParameters = validateEmptyParameters();
			if (isDateError || isValidateEmptyParameters) {
				validateDateAndEmptyHandler(isDateError, isValidateEmptyParameters);
				handleCloseWebsocket();
				return;
			}

			dispatch(reportsSlice.actions.setLoadingReportInitial(null));
			dispatch(reportsSlice.actions.setReportLoadingIsStarted(true));

			const requestData = requestHandler(activeCriterias, activeReport, additionalRows);

			// если отчет за все время, скрываем чекбокс разницы с прошлым периодом
			if (activeReport.period === 'all_time') dispatch(reportsSlice.actions.setDiffReportHide(true));

			const socket = openReportSocket();

			socket.addEventListener('error', () => {
				socketError();
			});

			socket.addEventListener('message', (event) => {
				socketMessage(JSON.parse(event.data), false);
			});

			socket.onopen = () => {
				btnResetParameters();

				dispatch(reportsSlice.actions.setReportCollapsed(true));
				socket.send(
					JSON.stringify({
						token: `${token}`,
						report_parameters: requestData,
					}),
				);
			};

			socket.onclose = (event) => {
				// Проверяем, было ли закрытие соединения непредвиденным
				if (event.code !== 1000 || !event.wasClean) {
					handleCloseWebsocket();

					enqueueSnackbar(
						null,
						createSnackbarOptions({
							time: 3000,
							type: 'error',
							text: `${translate('reportGenerationErrorRetry', language)}`,
						}),
					);
				}
			};

			dispatch(reportsSlice.actions.setReportByHash(false));
		}
	};

	return {
		handleMakeReport,
		handleCloseWebsocket,
		progress,
		message,
		reportWebSocketRef,
	};
};

export default useMakeReport;
