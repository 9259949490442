import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'api/api.config';

export const userModulesAPI = createApi({
	reducerPath: 'userModulesAPI',
	baseQuery: baseQueryWithReauth,
	refetchOnMountOrArgChange: true,
	endpoints: (build) => ({
		// Список всех модулей
		getModules: build.query({
			query: ({ userId }) => ({
				url: `/user/${userId}/modules_description`,
			}),
		}),
		// Удаление чата по id
		// deleteTgChat: build.mutation({
		// 	query: ({ chatId }) => ({
		// 		url: `/tg_chat/${chatId}`,
		// 		method: 'DELETE',
		// 	}),
		// }),
	}),
});
