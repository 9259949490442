import { FC } from 'react';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { LicenseInfo } from '@mui/x-license';
import { setupStore } from 'store/store';
import theme from './theme';
import Routes from './Routes';
import CalliFrame from './pages/CalliFrame/CalliFrame';

const store = setupStore();

const App: FC = () => {
	// подключение лицензионного ключа таблицы mui
	// @ts-ignore
	LicenseInfo.setLicenseKey(process.env.REACT_APP_KEY_MUI_DATA_GRID_PRO);

	// показываем либо страницу для iframe в AMO CRM, либо сам проект
	const routing = window.location.pathname.includes('/call-widget') ? (
		<BrowserRouter basename={process.env.REACT_APP_SUB_URL_PATH}>
			<Switch>
				<Route path="/call-widget" component={CalliFrame} exact />
			</Switch>
		</BrowserRouter>
	) : (
		<BrowserRouter children={<Routes />} basename={process.env.REACT_APP_SUB_URL_PATH} />
	);

	return (
		<StylesProvider injectFirst>
			<ThemeProvider theme={theme}>
				<SnackbarProvider maxSnack={10}>
					<Provider store={store}>{routing}</Provider>
				</SnackbarProvider>
			</ThemeProvider>
		</StylesProvider>
	);
};

export default App;
