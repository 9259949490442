/* теги и значения  -  'tag_name_value'
     список тегов  -  'tag_name_list'
     конструктор - 'search_items'
		 по звонку - 'call'
*/

/* дефолтные списки в селектах в отчетах (строки и колонки) -  сейчас используется во фронте */
export const rowSelects = ['time', 'tag_name_value', 'operator_phone', 'call', 'client_phone'];

export const columnSelects = ['tag_name_list', 'search_items', 'tag_name_value', 'calls_count'];

/* дефолтные группировки для строк и столбцов */
export const DEFAULT_ROW_GROUPING_KEY = 'time';
export const DEFAULT_COLUMN_GROUPING_KEY = 'calls_count';

/* что не входит  по строкам */
export const excludeRowGroupings = new Set(['tag', 'client_phone']);
/* что не входит  по столбцам */
export const excludeColumnGroupings = new Set(['tag', 'checklist_avg']);

/* высота и ширина селектов в отчете */
export const selectHeight = '36px';
export const selectWidth = '252px';

/* routes */
export const pathCreateReport = 'report/create';

export const pathReports = 'reports';

export const pathReport = 'report';

export const pathReportHash = 'report_hash';

export const pathHideParameters = 'hide_parameters';

/* ключи, которые не учитывать в таблице при создании колонок */
export const excludeColumnsKeys = new Set(['call_ids_hash', 'additional_params']);

export const excludeColumnsKeysForColumns = new Set(['additional_params']);

/* report limit */
export const reportColsLimit = 100;

/* row keys for table */
export const rowTableKeys = {
	rowSum: 'row_sum', // вошедшие
	rowAll: 'row_all', // всего
	rowMissed: 'row_missed', // не вошедшие
};

/* значения, которые не показываются в графиках */
export const excludeChartsKeys = new Set([
	'first_call_dt',
	'last_call_dt',
	'client_phones',
	'operator_phones',
	'from_services',
]);

/* стадии формирования отчета */
export const loadingStages = [
	'getting_call_list',
	'call_processing',
	'col_processing',
	'row_processing',
	'summary_processing',
	'intersection_of_summaries',
	'delete_empty_rows',
	'rows_normalization',
	'delete_empty_normalized_rows',
];

/* колонки с датой и временем */
export const dateTimeCells = new Set(['first_call_dt', 'last_call_dt']);

/* колонки, у которых нет сортировки */
export const noSorting = new Set(['client_phones', 'operator_phones', 'from_services']);
