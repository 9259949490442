import { useState, FC, useEffect } from 'react';
import { TagTypeEnum } from 'pages/Calls/types';
import { CallTagType } from 'store/calls/calls.types';
import { translate } from 'localizations';
import { useActionsFunctions } from 'pages/Calls/components/CallsHeader/components/CallHeadersSettings/hooks/useActionsFunctions';
import { useManualTaggingService } from '../hooks/useManualTaggingService';
import { TagFormat } from '../types';
import useMessages from '../hooks/useMessages';
import { MAX_SIZE_TAG } from '../consts/consts';
import React from 'react';

const useManualTagging = (callId: string, tags: CallTagType[], language: string) => {
	const filterTagsToLocal = React.useMemo(() => {
		return tags.map((tag) => {
			return { value: tag.name, label: tag.name, id: tag.id };
		});
	}, [tags]);

	const [tagsLocal, setTags] = useState<TagFormat[]>(filterTagsToLocal); // список зеленых и новых тегов

	const isCanAddNewTag = !tagsLocal.find((tag) => tag.id === null); // Пока не создастся один тег, следующий тег нельзя создать

	const { data, methods } = useManualTaggingService();
	const {
		tagsListForSelect, // список тегов с бэка для выбора при создании тега
		isLoadingAddManual,
		isErrorAddManual,
		isSuccessAddManual,
		isErrorDeleteManual,
		isLoadingDeleteManual,
		isSuccessDeleteManual,
	} = data;
	const { addManualTag, deleteManualTag, updateManualTags } = methods;

	const {
		showSuccessMessageAdd,
		showSuccessMessageDelete,
		showErrorMessageAdd,
		showErrorMessageDelete,
		showErrorValidate,
		showErrorSizeValidate,
		showErrorUniqueValidate,
	} = useMessages();
	const { updateCall } = useActionsFunctions(null);

	const newManualTag: TagFormat = {
		value: translate('newTag', language),
		label: translate('newTag', language),
		id: null,
	};

	useEffect(() => {
		if (tags !== undefined) setTags(filterTagsToLocal);
	}, [tags]);

	useEffect(() => {
		if (isSuccessAddManual) {
			showSuccessMessageAdd();
			getUpdatedCall();
			updateManualTags();
		}
	}, [isSuccessAddManual]);

	useEffect(() => {
		if (isErrorAddManual) {
			showErrorMessageAdd();
		}
	}, [isErrorAddManual]);

	useEffect(() => {
		if (isSuccessDeleteManual) {
			showSuccessMessageDelete();
			getUpdatedCall();
			updateManualTags();
		}
	}, [isSuccessDeleteManual]);

	useEffect(() => {
		if (isErrorDeleteManual) {
			showErrorMessageDelete();
		}
	}, [isErrorDeleteManual]);

	const addNewManualTag = () => {
		setTags([...tagsLocal, newManualTag]);
	};

	// сброс незаполненного нового тега
	const resetNewEmptyTag = (index: number) => {
		setTags([...tagsLocal.filter((tag, indexTag) => indexTag !== index)]);
	};

	const validateNewTag = (value: string) => {
		return Boolean(value.trim().length);
	};

	// валидация размера тега
	const handleValidateSize = (e: undefined | null | string) => {
		if (e && e?.trim().length) {
			if (e?.trim().length > MAX_SIZE_TAG) {
				showErrorSizeValidate();
				return false;
			}
			return true;
		}
		// укажите название тега
		showErrorValidate();
		return false;
	};

	// валидация уникального названия тега в звонке
	const handleValidateUnique = (e: undefined | null | string, index: number) => {
		if (e && e?.trim().length) {
			const isUnque = !tagsLocal.find((tag) => tag.value === e);
			if (!isUnque) {
				showErrorUniqueValidate();
				resetNewEmptyTag(index);
			}
			return isUnque;
		}
		return false;
	};

	// совокупность валидаций
	const handleValidate = (e: undefined | null | string, index: number) => {
		if (handleValidateSize(e) && handleValidateUnique(e, index)) {
			return true;
		}
		return false;
	};

	// обновление звонка
	const getUpdatedCall = async () => {
		await updateCall(callId);
	};

	// создание тега
	const createTagAsync = async (e: TagFormat, index: number) => {
		if (validateNewTag(e.value)) {
			const { data } = await addManualTag({
				callId,
				params: {
					name: e.value,
					tagType: TagTypeEnum.manual,
				},
			});
			if (data) {
				const createdTag = tagsLocal[index];
				createdTag.id = data;
				setTags([...tagsLocal.slice(0, index), createdTag, ...tagsLocal.slice(index + 1)]);
			}
		} else {
			showErrorValidate();
		}
	};

	// удаление тега
	const handleDeleteTag = async (id: string) => {
		await deleteManualTag({
			callId,
			tagId: id,
		});
	};

	return {
		data: {
			disabledAddBtn: !isCanAddNewTag,

			tagsLocal,
			tagsListForSelect,

			isLoadingAddManual,
		},
		methods: {
			addNewManualTag,
			createTagAsync,
			handleDeleteTag,
			handleValidate,
			resetNewEmptyTag,
		},
	};
};
export default useManualTagging;
