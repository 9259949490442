import { ModeType } from 'store/search/search.types';

type SetParametersArgs = {
	action: string;
	filterHash?: string | null;
	languageEngineModel?: object;
	mode?: ModeType | null;
	columns?: string[];
	callIds?: string[];
};

export const useParametersForAction = () => {
	const setParameters = ({
		action,
		filterHash,
		languageEngineModel = {},
		mode = null,
		columns,
		callIds,
	}: SetParametersArgs) => {
		const baseParams = {
			action,
			columns: columns || [],
			...(Object.keys(languageEngineModel).length > 0 && {
				sttOptions: languageEngineModel,
			}),
		};

		if (mode === 'REPORTS') {
			return {
				...baseParams,
				call_ids: callIds,
			};
		}

		if (filterHash) {
			return {
				...baseParams,
				search_filter_hash: filterHash,
			};
		}

		return {
			...baseParams,
			call_ids: callIds,
		};
	};

	return { setParameters };
};
